import React, {createContext, useContext, useRef, useState} from 'react'

import PrepareConfirmationModal from '../../components/modals/PrepareConfirmationModal'

const PrepareConfirmationModalContext = createContext({})

const PrepareConfirmationModalProvider = ({children}) => {
  const prepareConfirmationModalModalRef = useRef()
  const [isPrepareLoading, setIsPrepareLoading] = useState(false)

  return (
    <PrepareConfirmationModalContext.Provider
      value={{
        prepareConfirmationModalModalRef,
        isPrepareLoading,
        setIsPrepareLoading
      }}
    >
      <>
        {children}
        <PrepareConfirmationModal modalRef={prepareConfirmationModalModalRef} />
      </>
    </PrepareConfirmationModalContext.Provider>
  )
}

export const usePrepareConfirmationModalContext = () => useContext(PrepareConfirmationModalContext)

export default PrepareConfirmationModalProvider
