import React, {createContext, useContext, useState} from 'react'

const CertificatesSummaryContext = createContext({})

const CertificatesSummaryProvider = ({children}) => {
  const [certificatesSummary, setCertificatesSummary] = useState()

  return (
    <CertificatesSummaryContext.Provider
      value={{
        certificatesSummary,
        setCertificatesSummary
      }}
    >
      {children}
    </CertificatesSummaryContext.Provider>
  )
}

export const useCertificatesSummaryContext = () => useContext(CertificatesSummaryContext)

export default CertificatesSummaryProvider
