export const SORT_OPTIONS = {
  EMPLOYEE: 'first_name',
  CONCEPT: 'concept_description',
  BEGINS: 'begins_at',
  ENDS: 'ends_at',
  TYPE_LEAVE: 'leave_type_id',
  TYPE_VACATION: 'vacation_type_id'
}

export const NOVELTY_PROCCESS_STRATEGY = 'NOVELTY_PROCCESS_STRATEGY'
export const NOVELTY_DATES_STRATEGY = 'NOVELTY_DATES_STRATEGY'

export const NOVELTY_EDITION_TYPES = {
  NOT_ALLOWED: 'NOT_ALLOWED',
  PARTIAL: 'PARTIAL',
  FULL: 'FULL'
}
