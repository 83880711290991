import React from 'react'

import PayrollHeaderWrapper from '../../../../components/Wrappers/PayrollHeaderWrapper'
import IncomesCertificatesHeaderActions from './IncomesCertificatesHeaderActions'
import IncomesCertificatesHeaderInfo from './IncomesCertificatesHeaderInfo'

const IncomesCertificatesHeader = () => {
  return (
    <PayrollHeaderWrapper>
      <IncomesCertificatesHeaderInfo />
      <IncomesCertificatesHeaderActions />
    </PayrollHeaderWrapper>
  )
}

export default IncomesCertificatesHeader
