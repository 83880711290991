import {useState} from 'react'
import {useDispatch} from 'react-redux'

import {isEmpty, isEqual} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import {getEmployeePDFCertificateByContractId} from '../../../services/dianForm220'
import {notifyError} from '../../../services/notification'
import {useDownloadLinkReportContext} from '../../employees-panel/providers/DownloadLinkReport'
import {
  CERTIFICATE_REPORTS_CONTRACTS,
  CERTIFICATE_REPORTS_FIELD_NAMES,
  CERTIFICATE_REPORTS_INITIAL_STATE,
  CERTIFICATE_REPORTS_RULES
} from '../constants/form/certificates-report'
import {useCertificateSettingContext} from '../providers/CertificateSetting'
import {useIsReportLoadingContext} from '../providers/modals/reports/IsReportDownload'

const useCertificatesReportForm = ({modalRef}) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState(CERTIFICATE_REPORTS_INITIAL_STATE)
  const {isReportsLoading} = useIsReportLoadingContext()
  const {setFileNameDownloaded, setObject, downloadLinkReport} = useDownloadLinkReportContext()
  const {
    certificateSetting: {year}
  } = useCertificateSettingContext()

  const resetForm = isOpen => {
    if (!isOpen) return

    setFormData(CERTIFICATE_REPORTS_INITIAL_STATE)
  }

  const handleSubmit = e => {
    e.preventDefault()
    isReportsLoading(true)

    const validator = new ValidatorForm(formData, CERTIFICATE_REPORTS_RULES)
    validator.setAttributeNames(CERTIFICATE_REPORTS_FIELD_NAMES)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      isReportsLoading(false)
      return
    }

    if (isEqual(formData.selectionType, CERTIFICATE_REPORTS_CONTRACTS.SELECTION) && isEmpty(formData.contractIds)) {
      dispatch(notifyError('¡Upps! debes seleccionar al menos un empleado'))
      isReportsLoading(false)
      return
    }

    const payload = {
      year,
      contract_ids: formData.contractIds,
      format_file: 'pdf',
      kind: formData.kind
    }

    getEmployeePDFCertificateByContractId(
      payload,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        isReportsLoading(false)

        modalRef.current.closeModal()
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        isReportsLoading(false)
      }
    )
  }

  return {
    formData,
    setFormData,
    resetForm,
    handleSubmit
  }
}

export default useCertificatesReportForm
