import React from 'react'
import {useLocation} from 'react-router-dom'

import {isEmpty, isEqual} from '../../../../helpers/utils'
import {BOOLEAN_PARAMETER_VALUE} from '../../../payroll-parameters/data/form/value'
import {useCurrentSettingContext} from '../../providers/CurrentSetting'
import CertificateSettingVoidContent from '../CertificateSettingVoidContent'
import EditCertificateForm from './EditCertificateForm'
import CertificateSettingLecture from './lecture/CertificateSettingLecture'

const SingleCertificateSettingsBody = () => {
  const {
    currentSetting: {setting: lines, is_closed}
  } = useCurrentSettingContext()

  const {search} = useLocation()
  const searchParams = new URLSearchParams(search)
  const editParam = searchParams.get('is_edit')
  const isEdit = isEqual(BOOLEAN_PARAMETER_VALUE.TRUE, editParam)

  if (!lines || isEmpty(lines))
    return <CertificateSettingVoidContent text="¡Vaya! No hay renglones en este certificado" />

  if (isEdit && !is_closed) return <EditCertificateForm />

  return <CertificateSettingLecture />
}

export default SingleCertificateSettingsBody
