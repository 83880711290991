import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {getGeneratedCertificatesAmount} from '../../../services/dianForm220'
import {useCertificatesSummaryContext} from '../providers/CertificatesSummary'

const useGetGeneratedCertificatesAmountByYear = () => {
  const {certificatesSummary} = useCertificatesSummaryContext()
  const [certificatesAmount, setCertificatesAmount] = useState(0)
  const {month: date} = useParams()

  const currentYear = date.split('-')[0]

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const fetchYearSetting = () => {
    getGeneratedCertificatesAmount(
      currentYear,
      response => {
        setCertificatesAmount(response)
        setIsLoading(false)
      },
      () => {
        setIsLoading(false)
        setIsError(true)
      }
    )
  }

  useEffect(() => {
    if (certificatesSummary) setIsLoading(true)

    fetchYearSetting()
  }, [certificatesSummary])

  return {isLoading, isError, data: certificatesAmount}
}

export default useGetGeneratedCertificatesAmountByYear
