import React from 'react'

import {isEqual, isUndefined, len} from '../../../helpers/utils'
import {CATEGORIES_CONFIG} from '../../../modules/payroll-process-novelties/constants/categories'
import {INITAL_STATE_FORM_ACTION} from '../../../modules/payroll-process-novelties/constants/form'
import {useNoveltiesCategoriesContext} from '../../../modules/payroll-process-novelties/providers/NoveltiesCategories'
import {useFormActionContext} from '../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import FloatContainer from '../../Wrappers/FloatContainer'
import CategoryTypeItem from '../Items/CategoryTypeItem'

const SelectCategory = ({className}) => {
  const {setFormAction} = useFormActionContext()
  const {activeCategory, setActiveCategory, categories} = useNoveltiesCategoriesContext()

  if (!Array.isArray(categories) || isEqual(len(categories), 0) || !CATEGORIES_CONFIG[activeCategory]) return null

  const handleChangeCategory = code => {
    setActiveCategory(code)
    setFormAction(INITAL_STATE_FORM_ACTION)
  }

  const {icon, name, caption} = CATEGORIES_CONFIG[activeCategory]

  return (
    <div className={`novelty-select s-relative z-normal ${className}`}>
      <FloatContainer
        container={{
          className: 'items-container'
        }}
      >
        <CategoryTypeItem isSelected icon={icon} type={name} caption={caption} />

        <div className="float-container ed-grid row-gap s-gap-1">
          {categories.map(category => {
            const opt = CATEGORIES_CONFIG[category]
            if (isUndefined(opt)) return null

            return (
              <CategoryTypeItem
                key={category}
                isActive={isEqual(category, activeCategory)}
                isComingSoon={opt?.is_coming_soon}
                isDisabled={opt?.is_coming_soon}
                icon={opt?.icon}
                type={opt?.name}
                caption={opt?.caption}
                onClick={() => handleChangeCategory(category)}
              />
            )
          })}
        </div>
      </FloatContainer>
    </div>
  )
}

export default SelectCategory
