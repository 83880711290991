import {readBackendDate} from '../../../helpers/dates'
import {CREATE, EDIT, STATUS_HISTORY} from '../../../helpers/payroll'
import {isEmpty, isEqual} from '../../../helpers/utils'

export const getSuccessButtonText = actionType => {
  if (isEqual(actionType, CREATE)) return 'Agregar Novedad'

  if (isEqual(actionType, EDIT)) return 'Editar Novedad'

  return 'Guardar'
}

export const getPartialMinimumEndDate = (isPartial, periods) => {
  if (!isPartial || !periods || isEmpty(periods)) return null

  const lastHistoryItem = [...periods].reverse().find(period => isEqual(period.status, STATUS_HISTORY))

  if (!lastHistoryItem || !lastHistoryItem.ends_at) return null

  return readBackendDate(lastHistoryItem.ends_at)
}
