import React from 'react'

import SearchInput from '../../../../../components/Atoms/Forms/SearchInput'
import {isEmpty} from '../../../../../helpers/utils'
import {useSettingsListContext} from '../../../providers/SettingsList'
import NewSettingButton from './NewSettingButton'

const CertificateSettingsViewHeader = ({searchValue, handleSearch}) => {
  const {settingList} = useSettingsListContext()

  const hasSettings = !isEmpty(settingList)

  return (
    <div className="s-main-justify s-cross-center s-mb-4 s-gap-2">
      <p className="small s-mb-0">Crea, edita y consulta la configuración de los certificados</p>
      <div className="s-main-center s-cross-center">
        {hasSettings && (
          <SearchInput className="s-mr-1 small" value={searchValue} onChange={handleSearch} placeholder="Busca aquí" />
        )}
        <NewSettingButton hasSettings={hasSettings} />
      </div>
    </div>
  )
}

export default CertificateSettingsViewHeader
