import React, {useEffect, useState} from 'react'

import ParameterSettingSkeleton from '../../../../components/Organisms/Skeleton/ParameterSettingSkeleton'
import {getEmployerYearlyCertificates} from '../../../../services/certificate'
import {useSettingsListContext} from '../../providers/SettingsList'
import CertificateSettingVoidContent from '../CertificateSettingVoidContent'
import CertificateSettingsView from './CertificateSettingsView'

const CertificateSettingsViewWrapper = () => {
  const {settingList, setSettingList, settingListDataTimestamp} = useSettingsListContext()

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const fetchCertificatesList = () => {
    getEmployerYearlyCertificates(
      response => {
        setSettingList(response)
        setIsLoading(false)
      },
      () => {
        setIsLoading(false)
        setIsError(true)
      }
    )
  }

  useEffect(() => {
    fetchCertificatesList()
  }, [settingListDataTimestamp])

  if (isLoading) return <ParameterSettingSkeleton />

  if (!settingList || isError)
    return (
      <div className="n-container">
        <CertificateSettingVoidContent text="Ocurrio un error al cargar el historial de configuraciones" />
      </div>
    )

  return <CertificateSettingsView />
}

export default CertificateSettingsViewWrapper
