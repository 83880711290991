import classNames from 'classnames'
import React from 'react'

const MultiSelectOption = ({handleClick, isActive, option}) => {
  const {label} = option

  return (
    <div
      onClick={() => handleClick(option)}
      className={classNames(
        {'is-active': isActive},
        'hover-change blue normal-radius cursor-pointer s-px-6px s-py-4px'
      )}
    >
      <p className="s-mb-0 small">{label}</p>
    </div>
  )
}

export default MultiSelectOption
