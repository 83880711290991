import React from 'react'

import useGetNoveltyActions from '../../../../modules/payroll-process-novelties/hooks/useGetNoveltyActions'
import Icon from '../../../Atoms/Icon'
import Tooltip from '../../../Atoms/Templates/Tooltip'
import TooltipContainer from '../../../Organisms/Containers/TooltipContainer'
import TableOptionsButton from '../../TableOptionsButton'

const NoveltyRowAction = ({novelty, deleteModalRef}) => {
  const {handleDelete, handleEdit, handleSee} = useGetNoveltyActions({novelty, deleteModalRef})

  return (
    <div className="flex s-cross-center">
      <div className="s-mr-1">
        <TableOptionsButton seeFunction={handleSee} deleteFunction={handleDelete} editFunction={handleEdit} />
      </div>

      {!handleDelete && !handleEdit && (
        <TooltipContainer className="s-cross-center">
          <Icon svg="information" color="var(--blue-accent)" className="s-mr-4px cursor-pointer" />
          <Tooltip>
            <div className="whitespace-nowrap">
              <span>Novedad en historico</span>
              <p className="s-mb-0 smaller s-color-lighter-text">No es posible modificarla</p>
            </div>
          </Tooltip>
        </TooltipContainer>
      )}
    </div>
  )
}

export default NoveltyRowAction
