import {NOVELTY_DATES_STRATEGY, NOVELTY_PROCCESS_STRATEGY} from './novelties'

export const NOVELTIES_STRATEGIES_DATA = {
  [NOVELTY_PROCCESS_STRATEGY]: {
    key: NOVELTY_PROCCESS_STRATEGY,
    name: 'Novedades por proceso',
    caption: 'Las novedades del proceso actual',
    icon: 'bolt',
    path: 'by-process'
  },
  [NOVELTY_DATES_STRATEGY]: {
    key: NOVELTY_DATES_STRATEGY,
    name: 'Novedades por fechas',
    caption: 'Las novedades en un rango de fechas',
    icon: 'clock',
    path: 'by-date-range'
  }
}
