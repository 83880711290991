import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {useRef} from 'react'
import {useSelector} from 'react-redux'

import {shortFormatDate} from '../../../../helpers/dates'
import {getFormatCurrency} from '../../../../helpers/format-number'
import {isEqual} from '../../../../helpers/utils'
import {INITAL_STATE_FORM_ACTION} from '../../../../modules/payroll-process-novelties/constants/form'
import {NOVELTY_PROCCESS_STRATEGY} from '../../../../modules/payroll-process-novelties/constants/novelties'
import {useFormActionContext} from '../../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import {useStrategyFilterContext} from '../../../../modules/payroll-process-novelties/providers/StrategyFilter'
import {selectors as employerSelectors} from '../../../../redux/ducks/employer'
import OcassionalNoveltyRowStyles from '../../../../styles/modules/payroll-process-novelties/occasional-novelty-row.module.scss'
import DeleteModal from '../../../Organisms/Modals/DeleteModal'
import TableRow from '../../../Wrappers/TableRow'
import Concept from '../../Templates/Concept'
import Employee from '../../Templates/Employee'
import NoveltyRowAction from './NoveltyRowAction'

const OcassionalNoveltyRow = ({novelty, onDelete}) => {
  const {setFormAction} = useFormActionContext()
  const deleteModal = useRef()
  const employerSession = useSelector(employerSelectors.getSession)
  const {currentStrategyFilter} = useStrategyFilterContext()

  const handleOnClick = () => {
    onDelete(novelty.id)
    setFormAction(INITAL_STATE_FORM_ACTION)
    deleteModal.current.closeModal()
  }

  const isFilteredByProccess = isEqual(currentStrategyFilter, NOVELTY_PROCCESS_STRATEGY)

  return (
    <>
      <TableRow>
        <Employee employee={novelty} />
        <Concept concept={novelty} />
        {/* needs to be duplicated because of the TableRow children validation */}
        {!isFilteredByProccess && (
          <p className={classNames(OcassionalNoveltyRowStyles.dateRow, 'small')}>
            {shortFormatDate(novelty.begins_at)}
          </p>
        )}
        {!isFilteredByProccess && (
          <p className={classNames(OcassionalNoveltyRowStyles.dateRow, 'small')}>{shortFormatDate(novelty.ends_at)}</p>
        )}
        <p className="s-mb-0 m-center">{novelty.quantity}</p>
        <p className="s-mb-0 m-right">
          {getFormatCurrency(
            novelty.value,
            employerSession.code_iso_3,
            !employerSession.not_include_decimals_in_calculation
          )}
        </p>
        <NoveltyRowAction novelty={novelty} deleteModalRef={deleteModal} />
      </TableRow>
      <DeleteModal ref={deleteModal} deleteFunction={handleOnClick} />
    </>
  )
}

OcassionalNoveltyRow.propTypes = {
  novelty: PropTypes.shape({}).isRequired
}

OcassionalNoveltyRow.defaultProps = {
  novelty: PropTypes.shape({})
}

export default OcassionalNoveltyRow
