import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {QUERY_PARAM_HASH_CONTRACT} from '../../../../config/routes'
import SelectedCertificateEmptyState from '../layout/SelectedCertificateEmptyState'
import SelectedIncomesCertificate from './SelectedIncomesCertificate'

const IncomesCertificatePanel = () => {
  const [currentQueryParams, setCurrentQueryParams] = useState(null)
  const {search} = useLocation()

  useEffect(() => {
    setCurrentQueryParams(new URLSearchParams(search))
  }, [search])

  const hasContractHash = currentQueryParams && currentQueryParams.has(QUERY_PARAM_HASH_CONTRACT)

  if (!hasContractHash) return <SelectedCertificateEmptyState />

  return <SelectedIncomesCertificate />
}

export default IncomesCertificatePanel
