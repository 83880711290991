import React, {useState} from 'react'

import Container from '../../../../components/Wrappers/Container'
import useHandleSearchSettings from '../../hooks/useHandleSearchSettings'
import CertificateSettingsViewList from './CertificateSettingsViewList'
import CertificateSettingsViewHeader from './header/CertificateSettingsViewHeader'

const CertificateSettingsView = () => {
  const {filteredList, handleSearch, searchValue} = useHandleSearchSettings()

  return (
    <Container>
      <CertificateSettingsViewHeader handleSearch={handleSearch} searchValue={searchValue} />
      <CertificateSettingsViewList list={filteredList} />
    </Container>
  )
}

export default CertificateSettingsView
