import React, {useContext} from 'react'
import {useSelector} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {getConceptsNoveltyByProcessType} from '../../../helpers/concepts'
import NoveltyTableAddButtons from '../../../modules/payroll-process-novelties/components/NoveltyTableAddButtons'
import useDownloadNoveltyReport from '../../../modules/payroll-process-novelties/hooks/useDownloadNoveltyReport'
import {useNoveltiesFiltersContext} from '../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useStrategyFilterContext} from '../../../modules/payroll-process-novelties/providers/StrategyFilter'
import {selectors as employerConceptSelectors} from '../../../redux/ducks/concepts'
import {generateRecurrentNoveltiesReport} from '../../../services/recurrentnovelty'
import ActionButton from '../../Atoms/Templates/ActionButton'
import SelectConceptInput from '../Selects/SelectConceptInput'
import SelectEmployeeInput from '../Selects/SelectEmployeeInput'
import RecurrentNoveltyTableContent from './content/RecurrentNoveltyTableContent'

const RecurrentNoveltyTable = () => {
  const {setFilters} = useNoveltiesFiltersContext()
  const {currentStrategyFilter} = useStrategyFilterContext()

  const noveltyConcepts = useSelector(employerConceptSelectors.getNoveltyConcepts)
  const ConceptByProcessType = useSelector(employerConceptSelectors.getProcessConcepts)
  const {activeProcess} = useContext(PayrollContext)
  const concepts = getConceptsNoveltyByProcessType(noveltyConcepts, ConceptByProcessType, activeProcess.process_type_id)

  const handleOnSelectConceptInput = conceptID => {
    setFilters(prev => {
      if (conceptID === 0) {
        const {concept_id, ...rest} = prev
        return rest
      }
      return {...prev, concept_id: conceptID, page: 1}
    })
  }

  const handleOnSelectEmployeeInput = contractID => {
    setFilters(prev => {
      if (contractID === 0) {
        const {contract_id, ...rest} = prev
        return rest
      }

      return {...prev, contract_id: contractID, page: 1}
    })
  }

  const {handleGenerateReport, isLoadingDownloadReport} = useDownloadNoveltyReport(generateRecurrentNoveltiesReport)

  return (
    <>
      <div
        key={currentStrategyFilter}
        className="m-cross-end s-flex-gap-12px flex-grow m-main-end s-column m-row s-px-2 s-pb-2 m-px-4 m-pb-4"
      >
        <SelectEmployeeInput
          className="small"
          name="employee_id"
          id="employee_id"
          label="Empleado"
          placeholder="Busca por Identificación o nombre"
          onSelect={handleOnSelectEmployeeInput}
        />
        <SelectConceptInput
          className="small"
          name="concept_id"
          id="concept_id"
          label="Concepto"
          placeholder="Busca por código o nombre"
          onSelect={handleOnSelectConceptInput}
          concepts={concepts}
        />

        <ActionButton
          icon="download"
          onClick={handleGenerateReport}
          className="s-border-border"
          isDisabled={isLoadingDownloadReport}
        />

        <NoveltyTableAddButtons />
      </div>

      <RecurrentNoveltyTableContent />
    </>
  )
}

export default RecurrentNoveltyTable
