import React, {useRef} from 'react'

import RadioButton from '../../../../components/Atoms/Forms/RadioButton'
import ModalTemplate from '../../../../components/Wrappers/ModalTemplate'
import RadioButtonsWrapper from '../../../../components/Wrappers/RadioButtonsWrapper'
import {isEqual} from '../../../../helpers/utils'
import MultiSelectEmployeeInput from '../../../../modules/employees-panel/components/MultiSelectEmployeeInput'
import {CERTIFICATE_REPORTS_CONTRACTS, CERTIFICATE_REPORTS_KIND} from '../../constants/form/certificates-report'
import useCertificatesReportForm from '../../hooks/useCertificatesReportForm'

const CertificatesReportModal = ({modalRef}) => {
  const {formData, setFormData, resetForm, handleSubmit} = useCertificatesReportForm({modalRef})

  const updateRadioButton = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: value,
      // clean the contract list if the selection_type changed
      contractIds: isEqual(name, 'selectionType') ? [] : state.contractIds
    }))
  }

  return (
    <ModalTemplate ref={modalRef} className="s-center medium" onChangeOpen={resetForm}>
      <form onSubmit={handleSubmit}>
        <h3>Certificados</h3>
        <div className="ed-grid s-grid-1 rows-gap s-mb-2">
          <div>
            <RadioButtonsWrapper label="Empleados" className="s-mb-1">
              <RadioButton
                name="selectionType"
                label="Todos"
                value={CERTIFICATE_REPORTS_CONTRACTS.ALL}
                onChange={updateRadioButton}
                checked={isEqual(formData.selectionType, CERTIFICATE_REPORTS_CONTRACTS.ALL)}
              />

              <RadioButton
                name="selectionType"
                label="Una selección"
                value={CERTIFICATE_REPORTS_CONTRACTS.SELECTION}
                onChange={updateRadioButton}
                checked={isEqual(formData.selectionType, CERTIFICATE_REPORTS_CONTRACTS.SELECTION)}
              />
            </RadioButtonsWrapper>

            {isEqual(formData.selectionType, CERTIFICATE_REPORTS_CONTRACTS.SELECTION) && (
              <MultiSelectEmployeeInput setFormData={setFormData} placeholder="Escribe para buscar empleados" />
            )}
          </div>

          <RadioButtonsWrapper label="Generar como" className="s-mb-2">
            <RadioButton
              name="kind"
              label="Archivo ZIP"
              value={CERTIFICATE_REPORTS_KIND.ZIP}
              onChange={updateRadioButton}
              checked={isEqual(formData.kind, CERTIFICATE_REPORTS_KIND.ZIP)}
            />

            <RadioButton
              name="kind"
              label="Un solo PDF"
              value={CERTIFICATE_REPORTS_KIND.ONE_FILE}
              onChange={updateRadioButton}
              checked={isEqual(formData.kind, CERTIFICATE_REPORTS_KIND.ONE_FILE)}
            />
          </RadioButtonsWrapper>
        </div>

        <button className="button" type="submit">
          Descargar
        </button>
      </form>
    </ModalTemplate>
  )
}

export default CertificatesReportModal
