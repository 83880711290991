import classNames from 'classnames'
import React from 'react'

import SelectButton from '../../../../../components/Molecules/Selects/SelectButton'
import {mediaToM} from '../../../../../helpers/media-queries'
import {useMedia} from '../../../../../hooks/useMedia'
import useGetReportButtonsOptions from '../../../hooks/useGetReportButtonsOptions'
import {useIsReportLoadingContext} from '../../../providers/modals/reports/IsReportDownload'

const ReportsHeaderButton = () => {
  const toM = useMedia(mediaToM)
  const {isReportsLoading} = useIsReportLoadingContext()

  const {options} = useGetReportButtonsOptions()

  return (
    <SelectButton
      isDisable={isReportsLoading}
      classButton={classNames({small: toM, grayscale: isReportsLoading}, 'ghost')}
      iconName="document"
      description={isReportsLoading ? 'Descargando...' : 'Reportes'}
      options={options}
    />
  )
}

export default ReportsHeaderButton
