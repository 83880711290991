import {useEffect, useState} from 'react'

import {isEmpty} from '../../../helpers/utils'
import {useSettingsListContext} from '../providers/SettingsList'

const useHandleSearchSettings = () => {
  const {settingList} = useSettingsListContext()

  const [searchValue, setSearchValue] = useState('')
  const [filteredList, setFilteredList] = useState(settingList)

  useEffect(() => {
    setFilteredList(settingList)
    setSearchValue('')
  }, [settingList])

  const handleSearch = e => {
    let value = e.target.value
    setSearchValue(value)

    if (isEmpty(value)) {
      setFilteredList(settingList)
      return
    }

    value = value.toLowerCase()
    const newList = settingList.filter(setting => String(setting.year).toLowerCase().includes(value))
    setFilteredList(newList)
  }

  return {searchValue, filteredList, handleSearch}
}

export default useHandleSearchSettings
