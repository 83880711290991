import {
  CONCEPT_DEDUCTION,
  CONCEPT_DEDUCTION_SYSTEM,
  CONCEPT_EARNING,
  CONCEPT_EARNING_SYSTEM,
  CONCEPT_NET_PAY,
  CONCEPT_NOT_OPERATION,
  CONCEPT_PROVISION
} from '../helpers/concepts'
import {ORIGIN_INTEGRATION_NBC} from '../helpers/origin'
import SAP_LOGO from '../static/images/utils/SAP-logo.png'

export const CONCEPT_MAP_COLOR = {
  [CONCEPT_EARNING]: {is_ghost: false, color: 's-bg-green'},
  [CONCEPT_DEDUCTION]: {is_ghost: false, color: 's-bg-red'},
  [CONCEPT_EARNING_SYSTEM]: {is_ghost: true, color: 's-color-alt-green'},
  [CONCEPT_DEDUCTION_SYSTEM]: {is_ghost: true, color: 's-color-alt-red'},
  [CONCEPT_PROVISION]: {is_ghost: false, color: 's-bg-blue'},
  [CONCEPT_NOT_OPERATION]: {is_ghost: false, color: 's-bg-text'},
  [CONCEPT_NET_PAY]: {is_ghost: false, color: 's-bg-blue-800'}
}

export const CONCEPT_MAP_ORIGIN = {
  [ORIGIN_INTEGRATION_NBC]: {logo: SAP_LOGO}
}
