import React, {useContext} from 'react'

import DropdownItem from '../../../components/Atoms/Items/DropdownItem'
import DropdownSelect from '../../../components/Molecules/Dropsdown/DropdownSelect'
import {PayrollContext} from '../../../contexts/contexts'
import {readBackendDate} from '../../../helpers/dates'
import {isEqual} from '../../../helpers/utils'
import {INITAL_STATE_FORM_ACTION} from '../constants/form'
import {NOVELTY_DATES_STRATEGY, NOVELTY_PROCCESS_STRATEGY} from '../constants/novelties'
import {getStrategiesOptions} from '../helpers/strategies'
import {useDateFilterModalContext} from '../providers/DateFilterModal'
import {useFormActionContext} from '../providers/PayrollReportModalWrapper'
import {useStrategyFilterContext} from '../providers/StrategyFilter'

const StrategiesSelect = () => {
  const {currentStrategyFilter, setCurrentStrategyFilter} = useStrategyFilterContext()
  const {setBeginDate, setEndDate} = useDateFilterModalContext()
  const {activeProcess} = useContext(PayrollContext)
  const {dateFilterModalRef} = useDateFilterModalContext()
  const {setFormAction} = useFormActionContext()

  const changeStrategy = strategyKey => {
    if (isEqual(strategyKey, NOVELTY_PROCCESS_STRATEGY) && isEqual(currentStrategyFilter, NOVELTY_PROCCESS_STRATEGY))
      return

    if (isEqual(strategyKey, NOVELTY_PROCCESS_STRATEGY)) {
      setCurrentStrategyFilter(strategyKey)
      setBeginDate(readBackendDate(activeProcess.begins_at))
      setEndDate(readBackendDate(activeProcess.ends_at))
    }

    if (isEqual(strategyKey, NOVELTY_DATES_STRATEGY)) {
      dateFilterModalRef.current.openModal()
    }

    setFormAction(INITAL_STATE_FORM_ACTION)
  }

  const strategyOptions = getStrategiesOptions()

  return (
    <DropdownSelect
      className="s-main-start s-column s-cross-start"
      selected={strategyOptions.find(option => isEqual(option.key, currentStrategyFilter))?.value}
    >
      {strategyOptions.map(option => (
        <DropdownItem
          key={option.key}
          text={option.value}
          isActive={isEqual(option.key, currentStrategyFilter)}
          onClick={() => changeStrategy(option.key)}
        />
      ))}
    </DropdownSelect>
  )
}

export default StrategiesSelect
