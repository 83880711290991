import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'

import routes from '../../../config/routes'
import {updateCertificateSetting} from '../../../services/certificate'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {useCurrentSettingContext} from '../providers/CurrentSetting'

const useEditCertificateForm = () => {
  const {currentSetting} = useCurrentSettingContext()
  const [formData, setformData] = useState(currentSetting.setting)
  const [isLoading, setIsLoading] = useState(false)
  const {year} = currentSetting
  const history = useHistory()
  const dispatch = useDispatch()

  const handleOnSubmit = e => {
    e.preventDefault()
    setIsLoading(true)

    const payload = {
      year,
      setting: formData
    }

    updateCertificateSetting(
      payload,
      () => {
        const path = `${routes.settings.base}${routes.settings.incomesWithholdingsCertificates}`

        history.push({
          pathname: path
        })
        dispatch(notifySuccessful('Se editó la configuración exitosamente'))
        setIsLoading(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando editabamos la configuración.'))
        setIsLoading(false)
      }
    )
  }

  return {formData, setformData, handleOnSubmit, isLoading}
}

export default useEditCertificateForm
