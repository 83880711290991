import classNames from 'classnames'
import React, {forwardRef, useEffect, useState} from 'react'

import {getFilteredOptions} from '../../../helpers/form'
import {isAnyEqual, isEmpty, isEqual, isNull, len} from '../../../helpers/utils'
import MultiSelectStyles from '../../../styles/components/Molecules/Selects/multi-select.module.scss'
import MultiSelectOption from '../../Atoms/Forms/MultiSelectOption'
import Icon from '../../Atoms/Icon'
import FloatContainer from '../../Wrappers/FloatContainer'

const MultiSelect = forwardRef(
  ({
    id,
    name,
    label,
    className,
    placeholder = 'Escribe para buscar',
    initialValue = [],
    isRequired,
    isDisabled,
    onChange,
    options = [],
    optionComponent: CustomOptionComponent,
    ...rest
  }) => {
    const [inputValue, setInputValue] = useState('')
    const [selectedValues, setSelectedValues] = useState(initialValue)
    const [optionsList, setOptionsList] = useState(getFilteredOptions(options, selectedValues))
    const [listIndex, setListIndex] = useState({active: 0, max: 0})

    useEffect(() => {
      setOptionsList(getFilteredOptions(options, selectedValues))
    }, [options])

    useEffect(() => {
      onChange(selectedValues)
      setOptionsList(getFilteredOptions(options, selectedValues))
    }, [selectedValues])

    const handleFilterOptions = searchValue => {
      const filteredOptions = getFilteredOptions(searchOptions, selectedValues)

      const searchOptions = filteredOptions.filter(
        item =>
          item.label.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.value.toLowerCase().includes(searchValue.toLowerCase())
      )

      if (isEmpty(selectedValues)) {
        const size = isEmpty(searchOptions) ? 0 : len(searchOptions) - 1

        setOptionsList(filteredOptions)
        setListIndex({active: 0, max: size})
        return
      }

      const size = isEmpty(filteredOptions) ? 0 : len(filteredOptions) - 1
      setOptionsList(filteredOptions)
      setListIndex({active: 0, max: size})
    }

    const handleOnChangeInput = newValue => {
      setInputValue(newValue)

      if (isEmpty(newValue)) {
        setOptionsList(getFilteredOptions(options, selectedValues))
        setListIndex({active: 0, max: 0})
        return
      }

      handleFilterOptions(newValue)
    }

    // TODO: MODIFY
    const handleOnSelect = option => {
      if (isEmpty(optionsList)) return

      setInputValue('')
      setOptionsList(getFilteredOptions(options, selectedValues))
      setListIndex({active: 0, max: 0})
      setSelectedValues(state => [...state, isNull(option) ? optionsList[listIndex.active] : option])
    }

    const handleKeyPress = e => {
      if (!isAnyEqual(e.key, ['Enter', 'ArrowUp', 'ArrowDown'])) return

      e.preventDefault()

      if (isEqual(e.key, 'Enter')) {
        handleOnSelect(null)
        return
      }

      moveIndexPosition(e.key)
    }

    const moveIndexPosition = move => {
      setListIndex(currentIndex => {
        if (isEqual(currentIndex?.active, 0) && isEqual(move, 'ArrowUp')) {
          return currentIndex
        }

        if (
          !isEqual(currentIndex?.active, 0) &&
          isEqual(currentIndex?.active, currentIndex?.max) &&
          isEqual(move, 'ArrowDown')
        ) {
          return currentIndex
        }

        const newActiveIndex = isEqual(move, 'ArrowDown') ? ++currentIndex.active : --currentIndex.active

        return {active: newActiveIndex, max: currentIndex?.max}
      })
    }

    const deleteOptionSelected = valueToDelete => {
      setSelectedValues(state => [...state.filter(value => value.id !== valueToDelete.id)])
    }

    const Option = CustomOptionComponent || MultiSelectOption

    return (
      <div className={className}>
        <FloatContainer container={{className: 's-relative z-tooltip'}} isInputTheContainer>
          <div
            className={classNames(
              MultiSelectStyles.container,
              'flex s-row s-cross-center s-border-border normal-radius s-mb-2px'
            )}
          >
            {selectedValues.map(value => (
              <div
                key={value.id}
                className={classNames(
                  MultiSelectStyles.chip,
                  's-cross-center small-radius s-pxy-4px s-bg-border s-w-fit'
                )}
              >
                <span className="small s-pl-2px s-mr-4px">{value.label}</span>
                <Icon
                  svg="cross"
                  className="cursor-pointer s-color-blue-500 s-hover-color-red"
                  size="14px"
                  onClick={() => deleteOptionSelected(value)}
                />
              </div>
            ))}
            <input
              value={inputValue}
              className={MultiSelectStyles.input}
              onChange={e => {
                handleOnChangeInput(e.target.value)
              }}
              onKeyDown={handleKeyPress}
              required={isRequired}
              id={id}
              type="text"
              name={name}
              autoComplete="off"
              placeholder={placeholder}
              disabled={isDisabled}
              {...rest}
            />
          </div>
          {!isDisabled && (
            <div className="float-container s-column s-flex-gap-12px size-m">
              {isEmpty(optionsList) && (
                <p className="s-cross-center smaller s-color-light-text s-mb-0">No se encontraron resultados</p>
              )}
              {!isEmpty(optionsList) &&
                optionsList.map((option, index) => (
                  <Option
                    key={option.id}
                    isActive={isEqual(index, listIndex.active)}
                    handleClick={handleOnSelect}
                    option={option}
                  />
                ))}
            </div>
          )}
        </FloatContainer>
      </div>
    )
  }
)

MultiSelect.displayName = 'MultiSelect'

export default MultiSelect
