import React from 'react'

import Table from '../../../../components/Wrappers/Table'
import {isEmpty} from '../../../../helpers/utils'
import CertificateSettingVoidContent from '../CertificateSettingVoidContent'
import CertificateSettingsViewItem from './CertificateSettingsViewItem'

const CertificateSettingsViewList = ({list}) => {
  if (isEmpty(list)) return <CertificateSettingVoidContent />

  return (
    <Table
      heads={['Año', 'Acciones']}
      className="table-template"
      classNameTh="border-bottom-white-4"
      classNameHeader="sticky-element-to-header z-fixed"
    >
      {list.map(setting => (
        <CertificateSettingsViewItem key={setting.id} setting={setting} />
      ))}
    </Table>
  )
}

export default CertificateSettingsViewList
