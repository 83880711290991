import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {readBackendDate} from '../../../helpers/dates'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {fullName} from '../../../helpers/string-format'
import {isAnyEqual, isEmpty, isEqual, isNull} from '../../../helpers/utils'
import {LEAVE_NOVELTY_FORM_INITIAL_STATE} from '../../../modules/payroll-process-novelties/constants/form'
import NoveltiesFiltersProvider from '../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useFormActionContext} from '../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getLeaveNoveltyByID} from '../../../services/leaveNovelty'
import LeaveNoveltyTable from '../../Molecules/Tables/LeaveNoveltyTable'
import NoveltyTableWrapper from '../Containers/NoveltyTableWrapper'
import LeaveNoveltyForm from '../Forms/LeaveNoveltyForm'

const LeaveNovelty = () => {
  const employerSession = useSelector(employerSelectors.getSession)
  const {formAction} = useFormActionContext()

  const [isOpen, setIsOpen] = useState(false)
  const [dataForm, setDataForm] = useState(LEAVE_NOVELTY_FORM_INITIAL_STATE)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const leaveTypes = !isNull(cacheGeneral) && cacheGeneral.data?.leave_types

  const handleHasMedicalLeave = leaveNoveltyID => {
    const result = leaveTypes.filter(
      leaveType => isEqual(leaveType.id, Number(leaveNoveltyID)) && !leaveType.description.includes('INCAPACIDAD')
    )
    return isEmpty(result)
  }

  useEffect(() => {
    setIsOpen(false)

    if (isNull(formAction.type)) {
      setIsOpen(false)
      return
    }

    if (isEqual(formAction.type, CREATE)) {
      setDataForm(LEAVE_NOVELTY_FORM_INITIAL_STATE)
      setIsOpen(true)
      return
    }

    if (isAnyEqual(formAction.type, [EDIT, SHOW])) {
      getLeaveNoveltyByID(
        formAction.noveltyID,
        response => {
          setDataForm({
            id: response.id,
            contract_id: response.contract_id,
            employee_description: fullName(response),
            leave_type_id: response.leave_type_id,
            begins_at: readBackendDate(response.begins_at),
            ends_at: readBackendDate(response.ends_at),
            days: response.days,
            is_extends: response.is_extends,
            extends_leave_id: response.extends_leave_id,
            description: response.description,
            medical_diagnosis: response.medical_diagnosis,
            support_code: response.support_code,
            has_medical_leave: handleHasMedicalLeave(response.leave_type_id),
            status: response.status,
            leave_per_periods: response.leave_per_periods,
            edition_type: response.edition_type
          })
          setIsOpen(true)
        },
        error => {
          console.error(error)
          setIsOpen(false)
        }
      )
    }
  }, [formAction]) // eslint-disable-line

  return (
    <NoveltiesFiltersProvider>
      <NoveltyTableWrapper
        isOpenForm={isOpen}
        form={<LeaveNoveltyForm initialState={dataForm} handleHasMedicalLeave={handleHasMedicalLeave} />}
        table={<LeaveNoveltyTable />}
      />
    </NoveltiesFiltersProvider>
  )
}

export default LeaveNovelty
