import {PropTypes} from 'prop-types'
import React, {createContext, useContext, useState} from 'react'

import {INITAL_STATE_FORM_ACTION} from '../constants/form'

const FormActionContext = createContext({})

const FormActionProvider = ({children}) => {
  const [formAction, setFormAction] = useState(INITAL_STATE_FORM_ACTION)

  return (
    <FormActionContext.Provider
      value={{
        formAction,
        setFormAction: setFormAction
      }}
    >
      {children}
    </FormActionContext.Provider>
  )
}

export const useFormActionContext = () => useContext(FormActionContext)

FormActionProvider.propTypes = {
  children: PropTypes.node
}

FormActionProvider.defaultProps = {
  children: null
}

export default FormActionProvider
