import {useCertificatesReportModalContext} from '../providers/modals/reports/CertificatesReportModal'
import {useHistoricReportModalContext} from '../providers/modals/reports/HistoricReportModal'
import useHandleGetSettingsReport from './useHandleGetSettingsReport'

const useGetReportButtonsOptions = () => {
  const {certificatesReportModalRef} = useCertificatesReportModalContext()
  const {historicReportModalRef} = useHistoricReportModalContext()
  const {handleGetSettingsReport} = useHandleGetSettingsReport()

  const options = [
    {
      key: 1,
      description: 'Detalle',
      icon: 'excel',
      onClick: () => console.error('Detalle'),
      isVisible: true
    },
    {
      key: 2,
      description: 'Historico',
      icon: 'excel',
      onClick: () => historicReportModalRef.current.openModal(),
      isVisible: true
    },
    {
      key: 3,
      description: 'Medios Magnéticos',
      icon: 'excel',
      onClick: () => console.error('Medios Magnéticos'),
      isVisible: true
    },
    {
      key: 4,
      description: 'Configuración',
      icon: 'excel',
      onClick: handleGetSettingsReport,
      isVisible: true
    },
    {
      key: 5,
      description: 'Certificados',
      icon: 'excel',
      onClick: () => certificatesReportModalRef.current.openModal(),
      isVisible: true
    }
  ]

  return {options}
}

export default useGetReportButtonsOptions
