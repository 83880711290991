import {PropTypes} from 'prop-types'
import React, {createContext, useContext, useState} from 'react'

import {PayrollContext} from '../../../contexts/contexts'
import {isEqual} from '../../../helpers/utils'
import {NOVELTIES_FILTERS_DEFAULT_VALUES} from '../constants/filters'
import {NOVELTY_PROCCESS_STRATEGY} from '../constants/novelties'
import useHandleNoveltiesFilters from '../hooks/useHandleNoveltiesFilters'
import {useDateFilterModalContext} from './DateFilterModal'
import {useStrategyFilterContext} from './StrategyFilter'

const NoveltiesFiltersContext = createContext({})

const NoveltiesFiltersProvider = ({children}) => {
  const {beginDate, endDate} = useDateFilterModalContext()
  const {
    activeProcess: {hash}
  } = useContext(PayrollContext)

  const {currentStrategyFilter} = useStrategyFilterContext()

  const getDefaultFilterValue = () => {
    if (isEqual(currentStrategyFilter, NOVELTY_PROCCESS_STRATEGY)) {
      return {...NOVELTIES_FILTERS_DEFAULT_VALUES, 'hash-process': hash}
    }

    return {
      ...NOVELTIES_FILTERS_DEFAULT_VALUES,
      begins_at: beginDate.replace('T00:00:00.000Z', ''),
      ends_at: endDate.replace('T00:00:00.000Z', '')
    }
  }

  const defaultValue = getDefaultFilterValue()

  const [filters, setFilters] = useState(defaultValue)

  const {handleReload, handleNextPage, handleGetParams} = useHandleNoveltiesFilters(setFilters)

  return (
    <NoveltiesFiltersContext.Provider
      value={{
        filters,
        setFilters,
        handleReload,
        handleNextPage,
        handleGetParams,
        defaultValue
      }}
    >
      {children}
    </NoveltiesFiltersContext.Provider>
  )
}

export const useNoveltiesFiltersContext = () => useContext(NoveltiesFiltersContext)

NoveltiesFiltersProvider.propTypes = {
  children: PropTypes.node
}

NoveltiesFiltersProvider.defaultProps = {
  children: null
}

export default NoveltiesFiltersProvider
