import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {QUERY_PARAM_HASH_CONTRACT} from '../../../config/routes'
import {getEmployeeCertificateByContractId} from '../../../services/dianForm220'
import {getContractDetailByHash} from '../../../services/employees'
import {useCertificateSettingContext} from '../providers/CertificateSetting'

const useGetCurrentCertificateByContract = () => {
  const {
    certificateSetting: {year}
  } = useCertificateSettingContext()
  const [currentCertificateId, setCurrentCertificateId] = useState()
  const {search} = useLocation()
  const searchParams = new URLSearchParams(search)
  const contractHash = searchParams.get(QUERY_PARAM_HASH_CONTRACT)

  const [certificateContent, setCertificateContent] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const handleGetContractDetailByHash = () => {
    getContractDetailByHash(
      contractHash,
      response => {
        setCurrentCertificateId(response.id)

        const payload = {
          year,
          contract_ids: [response.id],
          format_file: 'html'
        }

        getEmployeeCertificateByContractId(
          payload,
          response => {
            setCertificateContent(response)
            setIsLoading(false)
          },
          () => {
            setIsLoading(false)
            setIsError(true)
          }
        )
      },
      () => {
        setIsLoading(false)
        setIsError(true)
      }
    )
  }

  useEffect(() => {
    setIsLoading(true)
    handleGetContractDetailByHash()
  }, [search])

  return {isLoading, isError, data: certificateContent, currentCertificateId}
}

export default useGetCurrentCertificateByContract
