import React, { useContext, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { PayrollContext } from '../../../../contexts/contexts'
import { STATUS_HISTORY } from '../../../../helpers/payroll'
import { isEqual } from '../../../../helpers/utils'
import useFetchNoveltiesWithConditions from '../../../../hooks/useFetchNoveltiesWithConditions'
import NoveltyTableSkeleton from '../../../../modules/payroll-process-novelties/components/skeletons/NoveltyTableSkeleton'
import { INITAL_STATE_FORM_ACTION } from '../../../../modules/payroll-process-novelties/constants/form'
import { getRecurrentNoveltiesTableHeaders } from '../../../../modules/payroll-process-novelties/constants/table'
import { useNoveltiesFiltersContext } from '../../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import { useFormActionContext } from '../../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import { notifySuccessful } from '../../../../services/notification'
import { deleteRecurrentNovelty, getRecurrentNoveltiesByStrategy } from '../../../../services/recurrentnovelty'
import LoadMoreButton from '../../../Atoms/Buttons/LoadMoreButton'
import RecurrentNoveltyRow from '../Rows/RecurrentNoveltyRow'
import NoveltyTableContentFallback from './NoveltyTableContentFallback'
import NoveltyTableContentWrapper from './NoveltyTableContentWrapper'

const RecurrentNoveltyTableContent = () => {
  const {activeProcess} = useContext(PayrollContext)
  const {handleNextPage, handleReload} = useNoveltiesFiltersContext()
  const {setFormAction} = useFormActionContext()
  const dispatch = useDispatch()
  const bottomScrollRef = useRef()

  const {novelties, isLoading, isError, isLastPage} = useFetchNoveltiesWithConditions(
    activeProcess.hash,
    getRecurrentNoveltiesByStrategy,
    bottomScrollRef
  )

  const handleOnDelete = noveltyID => {
    deleteRecurrentNovelty(noveltyID, () => {
      setFormAction(INITAL_STATE_FORM_ACTION)
      handleReload()
      dispatch(notifySuccessful('Hemos eliminado la novedad recurrente exitosamente'))
    })
  }

  if (isLoading) return <NoveltyTableSkeleton />
  if (isError) return <NoveltyTableContentFallback isError />

  const isHistory = isEqual(activeProcess?.status, STATUS_HISTORY)
  const tableHeaders = getRecurrentNoveltiesTableHeaders(isHistory)

  return (
    <>
      <NoveltyTableContentWrapper headers={tableHeaders} novelties={novelties}>
        {novelties.map(novelty => (
          <RecurrentNoveltyRow key={novelty.id} novelty={novelty} onDelete={handleOnDelete} />
        ))}
      </NoveltyTableContentWrapper>

      {!isLastPage && <LoadMoreButton description="Carga más novedades" onClick={() => handleNextPage(isLastPage)} />}
      <div ref={bottomScrollRef} />
    </>
  )
}

export default RecurrentNoveltyTableContent
