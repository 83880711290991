export const INITAL_STATE_FORM_ACTION = {
  type: null,
  noveltyID: 0
}

export const DATE_FILTER_FORM_INITIAL_STATE = {
  beginDate: '',
  endDate: ''
}

export const DATE_FILTER_FORM_RULES = {
  beginDate: 'date|required',
  endDate: 'date|required'
}

export const DATE_FILTER_FORM_FIELD_NAMES = {
  beginDate: '"Fecha de inicio"',
  endDate: '"Fecha de fin"'
}

// occasional novelty

export const OCCASIONAL_NOVELTY_FORM_INITIAL_STATE = {
  id: 0,
  concept_id: 0,
  concept_description: '',
  contract_id: 0,
  employee_description: '',
  pay_period_id: 0,
  process_id: 0,
  quantity: '0',
  value: '0',
  notes: ''
}

export const OCCASIONAL_NOVELTY_FORM_FIELDS = {
  contract_id: 'empleado',
  concept_id: 'concepto',
  quantity: 'cantidad',
  value: 'valor',
  notes: 'Notas u observaciones'
}

export const OCCASIONAL_NOVELTY_FORM_RULES = {
  contract_id: 'required|integer|not_in:0',
  concept_id: 'required|integer|not_in:0',
  pay_period_id: 'required|integer|not_in:0',
  quantity: 'required|numeric',
  value: 'required|numeric',
  notes: 'string'
}

// recurrent novelty

export const RECURRENT_NOVELTY_FORM_INITIAL_STATE = {
  id: 0,
  concept_id: 0,
  concept_description: '',
  contract_id: 0,
  employee_description: '',
  value: '0',
  begins_at: '',
  ends_at: '',
  is_override: false,
  is_custom_formula: false,
  application_period_sequence: '',
  standard_behavior: '',
  formula: '',
  notes: '',
  history_formula: '',
  history_amount: '',
  has_history: false
}

export const RECURRENT_NOVELTY_FORM_FIELDS = {
  contract_id: 'Empleado',
  concept_id: 'Concepto',
  value: 'Valor',
  begins_at: 'Inicial',
  ends_at: 'Finalización',
  is_override: '¿Usas una formula diferente para esta novedad?',
  is_custom_formula: '¿Cómo quieres crear la formula?',
  application_period_sequence: 'Periodo en qué se incluye la novedad',
  standard_behavior: 'Proporcionalidad',
  formula: 'Formula',
  notes: 'Notas u observaciones'
}

export const RECURRENT_NOVELTY_FORM_RULES = {
  contract_id: 'required|integer|not_in:0',
  concept_id: 'required|integer|not_in:0',
  value: 'required|numeric|min:1',
  begins_at: 'required|date',
  ends_at: 'date|after_or_equal:begins_at',
  is_override: 'required|boolean',
  is_custom_formula: 'required|boolean',
  application_period_sequence: 'string',
  standard_behavior: 'string',
  formula: 'string',
  notes: 'string'
}

// leave novelty

export const LEAVE_NOVELTY_FORM_INITIAL_STATE = {
  contract_id: 0,
  employee_description: '',
  leave_type_id: 0,
  begins_at: '',
  ends_at: '',
  days: 0,
  is_extends: false,
  extends_leave_id: 0,
  description: '',
  medical_diagnosis: '',
  support_code: '',
  process_hash: '',
  has_medical_leave: false
}

export const LEAVE_NOVELTY_FORM_FIELDS = {
  contract_id: 'empleado',
  leave_type_id: 'tipo',
  begins_at: 'inicio',
  ends_at: 'finalización',
  is_extends: '¿es prorroga?',
  extends_leave_id: 'incapacidad a prorrogar',
  description: 'nota y observaciones',
  medical_diagnosis: 'diagnóstico médico',
  support_code: 'Código soporte administradora'
}

export const LEAVE_NOVELTY_FORM_RULES = {
  contract_id: 'required|integer|not_in:0',
  leave_type_id: 'required|integer|not_in:0',
  begins_at: 'required|date',
  ends_at: 'required|date|after_or_equal:begins_at',
  is_extends: 'required|boolean',
  extends_leave_id: 'integer',
  description: 'string',
  medical_diagnosis: 'string',
  support_code: 'string'
}

// vacation novelty

export const VACATION_NOVELTY_FORM_INITIAL_STATE = {
  contract_id: 0,
  vacation_type_id: 0,
  begins_at: '',
  amount_days: '',
  process_hash: '',

  // Intern Fiels to validate logic
  working_days: 0,
  calendar_days: 0,
  vacation_is_in_money: false
}

export const VACATION_NOVELTY_FORM_FIELDS = {
  contract_id: 'Empleado',
  vacation_type_id: 'Tipo',
  begins_at: 'Inicio'
}

export const VACATION_NOVELTY_FORM_RULES = {
  contract_id: 'required|integer|not_in:0',
  vacation_type_id: 'required|integer|not_in:0',
  begins_at: 'required|date'
}

// absence novelty

export const ABSENCE_NOVELTY_FORM_INITIAL_STATE = {
  contract_id: 0,
  absence_type_id: 0,
  concept_id: 0,
  concept_description: '',
  begins_at: '',
  ends_at: '',
  code: '',
  note: '',
  has_discount_rest_day: false,
  can_pause_contract: false,
  process_hash: '',
  is_discount: false
}

export const ABSENCE_NOVELTY_FORM_FIELDS = {
  contract_id: 'empleado',
  absence_type_id: 'tipo de ausentismo',
  concept_id: 'concepto',
  begins_at: 'inicio',
  ends_at: 'finalización',
  code: 'código',
  note: 'nota',
  has_discount_rest_day: '¿Descuenta dominical?',
  can_pause_contract: '¿Interrumpe contrato?'
}

export const ABSENCE_NOVELTY_FORM_RULES = {
  contract_id: 'required|integer|not_in:0',
  absence_type_id: 'required|integer|not_in:0',
  concept_id: 'required|integer|not_in:0',
  begins_at: 'required|date',
  ends_at: 'required|date|after_or_equal:begins_at',
  code: 'string',
  note: 'string',
  has_discount_rest_day: 'required|boolean',
  can_pause_contract: 'required|boolean'
}
