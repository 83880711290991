import {useContext} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import routes, {payrollDateRoute} from '../config/routes'
import {PayrollContext} from '../contexts/contexts'
import {COLOMBIA_CODE} from '../helpers/country'
import {isEqual} from '../helpers/utils'
import {selectors as employerSelectors} from '../redux/ducks/employer'

const useGetPayrollHeaderOptions = (socialSecurityModalRef, isMigratedMonth, hasSocialSecurityAndElectronicPayroll) => {
  const history = useHistory()
  const {payrollDate} = useContext(PayrollContext)
  const employerSession = useSelector(employerSelectors.getSession)

  const isColombia = isEqual(employerSession.code_iso_3, COLOMBIA_CODE)
  const isDecember = isEqual(payrollDate.month, 12)
  const hasCertificateOption = isColombia && isDecember && !isMigratedMonth

  const handleElectronicPayroll = () => {
    history.push(`${payrollDateRoute(payrollDate)}${routes.payroll.payrollElectronic}`)
  }

  const handleCertificate = () => {
    history.push(`${payrollDateRoute(payrollDate)}${routes.payroll.certificates}`)
  }

  const handleOpenSocialSecurityModal = () => {
    socialSecurityModalRef.current.openModal()
  }

  const selectOptions = [
    {
      key: 1,
      description: 'Seguridad social',
      icon: 'shield',
      onClick: handleOpenSocialSecurityModal,
      isVisible: true
    },
    {
      key: 2,
      description: 'Nómina electrónica',
      icon: 'rectangle-group',
      onClick: handleElectronicPayroll,
      isVisible: true
    },
    {
      key: 3,
      description: 'Certificado Ingresos y Retenciones',
      icon: 'document',
      onClick: handleCertificate,
      isVisible: false
    }
  ]

  if (!hasSocialSecurityAndElectronicPayroll && !hasCertificateOption) {
    return []
  }

  if (!hasSocialSecurityAndElectronicPayroll) {
    selectOptions.splice(0, 2)
  }

  if (!hasCertificateOption) {
    selectOptions.pop()
  }

  return selectOptions
}

export default useGetPayrollHeaderOptions
