import React, {useContext, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {writeBackendDate} from '../../../helpers/dates'
import {ORIGIN_APP_NOMINEROS} from '../../../helpers/origin'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {isAnyEqual, isEqual, len} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import {
  INITAL_STATE_FORM_ACTION,
  VACATION_NOVELTY_FORM_FIELDS,
  VACATION_NOVELTY_FORM_RULES
} from '../../../modules/payroll-process-novelties/constants/form'
import {NOVELTY_EDITION_TYPES} from '../../../modules/payroll-process-novelties/constants/novelties'
import {useNoveltiesFiltersContext} from '../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useFormActionContext} from '../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {createVacationNovelty, updateVacationNovelty} from '../../../services/vacationNovelty'
import Select from '../../Atoms/Forms/Select'
import Icon from '../../Atoms/Icon'
import FormInput from '../../Atoms/Templates/FormInput'
import Tooltip from '../../Atoms/Templates/Tooltip'
import PerPeriodItem from '../../Molecules/Items/PerPeriodItem'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import SelectEmployeeInput from '../../Molecules/Selects/SelectEmployeeInput'
import NoveltyFormContainer from '../Containers/NoveltyFormContainer'
import TooltipContainer from '../Containers/TooltipContainer'

const VacationNoveltyForm = ({initialState}) => {
  const {formAction, setFormAction} = useFormActionContext()
  const {handleReload} = useNoveltiesFiltersContext()

  const dispatch = useDispatch()
  const employerSession = useSelector(employerSelectors.getSession)

  const isDisabled = isEqual(formAction.type, SHOW)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const vacationTypes = cacheGeneral.data?.vacation_types
  const {activeProcess} = useContext(PayrollContext)
  const [formData, setFormData] = useState(initialState)

  const handleOnSelectEmployeeInput = contractID => {
    setFormData(state => ({
      ...state,
      contract_id: Number(contractID)
    }))
  }

  const handleOnChangeVacationType = e => {
    const value = Number(e.target.value)
    const options = e.target.options
    const i = options.selectedIndex

    setFormData(state => ({
      ...state,
      vacation_type_id: value,
      vacation_is_in_money: isEqual(options[i].getAttribute('data-in-money'), 'true'),
      amount_days: ''
    }))
  }

  const handleOnChangeBeginsAt = date => {
    setFormData(state => ({
      ...state,
      begins_at: date
    }))
  }

  const handleOnChangeWorkingDays = e => {
    const value = e.target.value

    const pattern = formData.vacation_is_in_money ? /^\d{1,2}(\.(\d{1,2})?)?$/ : /^\d{1,2}$/

    if (isEqual(value, '') || pattern.test(value)) {
      setFormData(state => ({
        ...state,
        amount_days: value
      }))
    }
  }

  const handleCancelForm = () => setFormAction(INITAL_STATE_FORM_ACTION)

  const handleSuccessForm = () => {
    if (isEqual(formAction.type, CREATE)) handleCreate()
    if (isEqual(formAction.type, EDIT)) handleEdit()
  }

  const handleValidate = () => {
    const payload = {
      contract_id: formData.contract_id,
      vacation_type_id: formData.vacation_type_id,
      begins_at: writeBackendDate(formData.begins_at),
      process_hash: activeProcess.hash,
      calendar_days: 0,
      working_days: Number(formData.amount_days),
      origin: ORIGIN_APP_NOMINEROS
    }

    if (formData.vacation_is_in_money) {
      payload.calendar_days = Number(formData.amount_days)
      payload.working_days = 0
    }

    const validator = new ValidatorForm(payload, VACATION_NOVELTY_FORM_RULES)
    validator.setAttributeNames(VACATION_NOVELTY_FORM_FIELDS)

    if (validator.fails()) {
      throw validator.errors()
    }

    if (isEqual(formData.amount_days, '')) {
      // eslint-disable-next-line
      throw 'Debes digitar una cantidad de días válida'
    }

    return payload
  }

  const handleCreate = () => {
    try {
      const payload = handleValidate()
      createVacationNovelty(payload, response => {
        setFormAction(INITAL_STATE_FORM_ACTION)
        handleReload()
        dispatch(notifySuccessful('Hemos creado la novedad exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const handleEdit = () => {
    try {
      const payload = handleValidate()
      updateVacationNovelty(formData.id, payload, response => {
        setFormAction(INITAL_STATE_FORM_ACTION)
        handleReload()
        dispatch(notifySuccessful('Hemos Actualizado la novedad exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const successButtonText = isEqual(formAction.type, CREATE)
    ? 'Agregar Vacaciones'
    : isEqual(formAction.type, EDIT)
      ? 'Editar Vacaciones'
      : 'Guardar'

  const {edition_type} = formData
  const isPermissionsPartial = isEqual(edition_type, NOVELTY_EDITION_TYPES.PARTIAL)
  const isPermissionsFull = isEqual(edition_type, NOVELTY_EDITION_TYPES.FULL)

  return (
    <NoveltyFormContainer
      cancelFunction={handleCancelForm}
      successFunction={handleSuccessForm}
      successButtonText={successButtonText}
      hasEditPermissions={isPermissionsFull || isPermissionsPartial}
    >
      <SelectEmployeeInput
        isRequired
        isDisabled={isAnyEqual(formAction.type, [EDIT, SHOW]) || isPermissionsFull || isPermissionsPartial}
        id="contract_id"
        name="contract_id"
        label="Empleado"
        placeholder="Busca por Identificación o nombre"
        onSelect={handleOnSelectEmployeeInput}
        initialValue={formData.employee_description}
      />

      <Select
        isRequired
        isDisabled={isDisabled || isPermissionsPartial}
        id="vacation_type"
        name="vacation_type"
        label="Tipo"
        placeholder="Selecciona un tipo de vacaciónes"
        onChange={handleOnChangeVacationType}
        value={formData.vacation_type_id}
      >
        {Array.isArray(vacationTypes) &&
          vacationTypes.map(vacationType => (
            <option key={vacationType.id} value={vacationType.id} data-in-money={vacationType.in_money}>
              {vacationType.description}
            </option>
          ))}
      </Select>

      <DatePickerInput
        isRequired
        disabled={isDisabled || isPermissionsPartial}
        id="begins_at"
        name="begins_at"
        label="Inicio"
        onChange={handleOnChangeBeginsAt}
        value={formData.begins_at}
      />
      <div className="flex">
        <FormInput
          isRequired
          isDisabled={isDisabled}
          id="amount_days"
          name="amount_days"
          className="s-mr-1"
          label={`Cantidad de días ${formData.vacation_is_in_money ? 'a compensar' : 'hábiles'}`}
          placeholder={`${formData.vacation_is_in_money ? '1.25' : '1'}`}
          onChange={handleOnChangeWorkingDays}
          value={formData.amount_days}
        />

        {formData.vacation_is_in_money && (
          <TooltipContainer className="s-cross-start s-py-2px">
            <Icon svg="information" color="var(--blue-accent)" className="s-mr-4px cursor-pointer" />
            <Tooltip>
              <div className="whitespace-nowrap">
                <span>Art. 20 Ley 1429 de 2010</span>
                <p className="s-mb-0 smaller s-color-lighter-text">
                  Solicita mínimo la misma cantidad de días en descanso
                </p>
              </div>
            </Tooltip>
          </TooltipContainer>
        )}
      </div>

      {Array.isArray(initialState.vacation_per_periods) && len(initialState.vacation_per_periods) > 1 && (
        <div className="m-cols-3 ed-grid rows-gap s-gap-1">
          <h4>Distribución de la novedad por periodos</h4>
          <div className="ed-grid s-grid-1 m-grid-3 rows-gap">
            {initialState.vacation_per_periods.map(novelty => (
              <PerPeriodItem key={novelty.id} perPeriodNovelty={novelty} />
            ))}
          </div>
        </div>
      )}
    </NoveltyFormContainer>
  )
}

export default VacationNoveltyForm
