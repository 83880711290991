import React, {useContext, useRef} from 'react'
import {useDispatch} from 'react-redux'

import {PayrollContext} from '../../../../contexts/contexts'
import useFetchNoveltiesWithConditions from '../../../../hooks/useFetchNoveltiesWithConditions'
import NoveltyTableSkeleton from '../../../../modules/payroll-process-novelties/components/skeletons/NoveltyTableSkeleton'
import {INITAL_STATE_FORM_ACTION} from '../../../../modules/payroll-process-novelties/constants/form'
import {LEAVE_NOVELTY_TABLE_HEADERS} from '../../../../modules/payroll-process-novelties/constants/table'
import {useNoveltiesFiltersContext} from '../../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useFormActionContext} from '../../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import {deleteLeaveNovelty, getLeaveNoveltiesByStrategy} from '../../../../services/leaveNovelty'
import {notifySuccessful} from '../../../../services/notification'
import LoadMoreButton from '../../../Atoms/Buttons/LoadMoreButton'
import LeaveNoveltyRow from '../Rows/LeaveNoveltyRow'
import NoveltyTableContentFallback from './NoveltyTableContentFallback'
import NoveltyTableContentWrapper from './NoveltyTableContentWrapper'

const LeaveNoveltyTableContent = () => {
  const {activeProcess} = useContext(PayrollContext)
  const {handleNextPage, handleReload} = useNoveltiesFiltersContext()
  const {setFormAction} = useFormActionContext()
  const dispatch = useDispatch()
  const bottomScrollRef = useRef()

  const {novelties, isLoading, isError, isLastPage} = useFetchNoveltiesWithConditions(
    activeProcess.hash,
    getLeaveNoveltiesByStrategy,
    bottomScrollRef
  )

  const handleOnDelete = noveltyID => {
    deleteLeaveNovelty(noveltyID, () => {
      setFormAction(INITAL_STATE_FORM_ACTION)
      handleReload()
      dispatch(notifySuccessful('Hemos eliminado la novedad exitosamente'))
    })
  }

  if (isLoading) return <NoveltyTableSkeleton />
  if (isError) return <NoveltyTableContentFallback isError />

  return (
    <>
      <NoveltyTableContentWrapper headers={LEAVE_NOVELTY_TABLE_HEADERS} novelties={novelties}>
        {novelties.map(novelty => (
          <LeaveNoveltyRow novelty={novelty} key={novelty.id} onDelete={handleOnDelete} />
        ))}
      </NoveltyTableContentWrapper>

      {!isLastPage && <LoadMoreButton description="Carga más novedades" onClick={() => handleNextPage(isLastPage)} />}
      <div ref={bottomScrollRef} />
    </>
  )
}

export default LeaveNoveltyTableContent
