import PropTypes from 'prop-types'
import React from 'react'

import {CREATE, EDIT} from '../../../helpers/payroll'
import {isEqual} from '../../../helpers/utils'
import {useFormActionContext} from '../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'

const NoveltyFormContainer = props => {
  const {cancelFunction, successFunction, successButtonText, children, hasEditPermissions} = props
  const {formAction} = useFormActionContext()

  const showSaveButton = (isEqual(formAction.type, EDIT) && hasEditPermissions) || isEqual(formAction.type, CREATE)

  return (
    <form className="form ed-grid m-grid-2 lg-grid-3 form-grid">
      {children}
      <div className="m-cols-2 lg-cols-3 s-order-2">
        <div className="s-main-center s-flex-gap-24px">
          <button onClick={cancelFunction} className="button cancel" type="button">
            {!showSaveButton ? 'Cerrar' : 'Cancelar'}
          </button>

          {showSaveButton && (
            <button onClick={successFunction} className="button" type="button">
              {successButtonText}
            </button>
          )}
        </div>
      </div>
    </form>
  )
}

NoveltyFormContainer.propTypes = {
  cancelButtonText: PropTypes.string,
  successButtonText: PropTypes.string,
  successFunction: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  hasEditPermissions: PropTypes.bool.isRequired
}

NoveltyFormContainer.defaultProps = {
  cancelFunction: () => {},
  successButtonText: 'Guardar',
  successFunction: () => {},
  noveltyStatus: ''
}

export default NoveltyFormContainer
