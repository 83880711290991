import PropTypes from 'prop-types'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateBasicInformationEmployee} from 'services/employees'
import {notifyError, notifySuccessful} from 'services/notification'

import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {getFormatCurrency} from '../../../helpers/format-number'
import {fullName} from '../../../helpers/string-format'
import {isEqual, isNull} from '../../../helpers/utils'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import AvatarEdit from '../../Atoms/Avatars/AvatarEdit'
import Badge from '../../Atoms/Badge'
import MainEmployeInformationItem from '../../Molecules/Items/MainEmployeInformationItem'
import ContractSelect from '../../Molecules/Selects/ContractSelect'
import Container from '../../Wrappers/Container'

const EmployeesHeader = ({employee, contractSelected, onChangeContractSelected, onUpdate}) => {
  const dispatch = useDispatch()

  const employerSession = useSelector(employerSelectors.getSession)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const contractStatuses = !isNull(cacheGeneral) && cacheGeneral.data?.contract_statuses
  const status = contractStatuses.filter(c => isEqual(c.id, contractSelected.contract.contract_status_id))

  const handleUpdateAvatar = objAvatar => {
    updateBasicInformationEmployee(
      {...employee.employee, ...objAvatar},
      response => {
        onUpdate(state => ({...state, employee: {...state.employee, ...response}}))
        dispatch(notifySuccessful('La imagen fue actualizada correctamente.'))
      },
      error => {
        dispatch(notifyError(error))
      }
    )
  }

  return (
    <Container className="employees-header flex nowrap lg-cols-2 m-main-justify s-column lg-row">
      <div className="flex nowrap">
        <AvatarEdit
          classes="s-mr-24px m-mr-32px flex-none"
          employeeID={employee.employee.id}
          gender={employee.employee.gender}
          thumbnail={employee.employee.thumbnail}
          onUpdate={handleUpdateAvatar}
        />
        <div className="">
          <div className="s-mb-2">
            <div className="s-color-text">
              <p className="t3 s-mb-0">{fullName(employee.employee)}</p>
              <div className="s-cross-center">
                <span className="s-mr-12px s-color-title smallest">{employee?.employee?.hash}</span>
                {Array.isArray(status) && isEqual(status.length, 1) && (
                  <Badge text={status[0].description} color={status[0].color} />
                )}
              </div>
            </div>
          </div>

          <div className="flex s-flex-gap-16px lg-flex-gap-32px">
            <MainEmployeInformationItem
              icon="identification"
              title="No. identificación"
              text={employee.employee.identification_number}
            />

            <MainEmployeInformationItem
              icon="cash"
              title="Salario"
              text={`${getFormatCurrency(
                contractSelected.salary,
                employerSession.code_iso_3,
                !employerSession.not_include_decimals_in_calculation
              )}`}
            />

            <MainEmployeInformationItem
              icon="document"
              title="Contratos"
              child={
                <ContractSelect
                  contracts={employee.contracts}
                  contractSelected={contractSelected}
                  onChangeContractSelected={onChangeContractSelected}
                />
              }
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

ContractSelect.propTypes = {
  employee: PropTypes.object.isRequired,
  onChangeContractSelected: PropTypes.func.isRequired,
  contractSelected: PropTypes.object.isRequired
}

ContractSelect.defaultProps = {
  employee: {},
  onChangeContractSelected: () => {},
  contractSelected: {}
}

export default EmployeesHeader
