import {useDispatch} from 'react-redux'

import {getSettingsReportByYear} from '../../../services/certificate'
import {notifyError} from '../../../services/notification'
import {useDownloadLinkReportContext} from '../../employees-panel/providers/DownloadLinkReport'
import {useCertificateSettingContext} from '../providers/CertificateSetting'
import {useIsReportLoadingContext} from '../providers/modals/reports/IsReportDownload'

const useHandleGetSettingsReport = () => {
  const {setIsReportsLoading} = useIsReportLoadingContext()
  const {setFileNameDownloaded, setObject, downloadLinkReport} = useDownloadLinkReportContext()
  const dispatch = useDispatch()
  const {
    certificateSetting: {year}
  } = useCertificateSettingContext()

  const handleGetSettingsReport = () => {
    setIsReportsLoading(true)

    getSettingsReportByYear(
      year,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setIsReportsLoading(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsReportsLoading(false)
      }
    )
  }

  return {handleGetSettingsReport}
}

export default useHandleGetSettingsReport
