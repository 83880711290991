import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import MultiSelect from '../../../../components/Molecules/Selects/MultiSelect'
import {selectors as employerConceptSelectors} from '../../../../redux/ducks/concepts'
import {formatConceptsToOption, getMultiSelectInitialValues, getMultiSelectOptions} from '../../helpers/concepts'
import ConceptOption from '../form/ConceptOption'

const LineItem = ({line, formData, setformData}) => {
  const {name} = line

  const employerConcepts = useSelector(employerConceptSelectors.getConcepts)
  const filteredConcepts = formatConceptsToOption(employerConcepts)
  const optionList = getMultiSelectOptions(filteredConcepts, formData)
  const initialValue = getMultiSelectInitialValues(filteredConcepts, formData[name])

  const handleChange = newConcepts => {
    const newIds = newConcepts.map(concept => concept.id)

    setformData(prevValue => {
      return {
        ...prevValue,
        [name]: newIds
      }
    })
  }

  return (
    <div>
      <h4 className="small s-mb-2px">Renglón {name}</h4>
      <MultiSelect
        id={`select_line_${name}`}
        name={`select_line_${name}`}
        className="s-mb-4"
        initialValue={initialValue}
        onChange={handleChange}
        options={optionList}
        optionComponent={ConceptOption}
      />
    </div>
  )
}

export default LineItem
