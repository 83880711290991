import React from 'react'
import {useDispatch} from 'react-redux'

import ModalTemplate from '../../../../components/Wrappers/ModalTemplate'
import {prepareIncomesCertificates} from '../../../../services/dianForm220'
import {notifyError} from '../../../../services/notification'
import {useCertificateSettingContext} from '../../providers/CertificateSetting'
import {useCertificatesSummaryContext} from '../../providers/CertificatesSummary'
import {usePrepareConfirmationModalContext} from '../../providers/modals/PrepareConfirmationModal'

const PrepareConfirmationModal = ({modalRef}) => {
  const {setIsPrepareLoading} = usePrepareConfirmationModalContext()
  const {
    certificateSetting: {year}
  } = useCertificateSettingContext()
  const dispatch = useDispatch()
  const {setCertificatesSummary} = useCertificatesSummaryContext()

  const handleOnSuccess = () => {
    setIsPrepareLoading(false)
    const timestamp = new Date().getTime()

    setCertificatesSummary(timestamp)
  }

  const handleOnError = error => {
    dispatch(notifyError(error))
    setIsPrepareLoading(false)
  }

  const handleConfirm = () => {
    modalRef.current.closeModal()
    setIsPrepareLoading(true)

    prepareIncomesCertificates(year, handleOnSuccess, handleOnError)
  }

  return (
    <ModalTemplate ref={modalRef} className="medium s-center">
      <h3>Prepara tus certificados</h3>
      <div className="s-mb-24px">
        ¿Estás seguro de continuar?, esta acción eliminará los certificados existentes y creará nuevos
      </div>
      <div className="buttons-container">
        <button className="button cancel" onClick={() => modalRef.current.closeModal()}>
          No, cancelar
        </button>
        <button className="button" onClick={handleConfirm}>
          Sí, continuar
        </button>
      </div>
    </ModalTemplate>
  )
}

export default PrepareConfirmationModal
