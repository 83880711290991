import {mediaFromM} from 'helpers/media-queries'
import {useMedia} from 'hooks/useMedia'
import React from 'react'

const NoveltyTableSkeletonBody = () => {
  const fromM = useMedia(mediaFromM)

  const tableRows = Array.from({length: 5})

  return (
    <table className="table">
      {fromM && (
        <thead>
          <tr>
            <th>
              <div className="s-w-full s-h-24px s-bg-border animate-pulse s-rounded-4px" />
            </th>
          </tr>
        </thead>
      )}
      <tbody>
        {tableRows.map((_, index) => (
          <tr key={index}>
            <td>
              <div className="s-w-full s-h-40px s-bg-border animate-pulse s-rounded-4px" />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default NoveltyTableSkeletonBody
