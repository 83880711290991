import PropTypes from 'prop-types'
import React, {createContext, useContext, useState} from 'react'

const CurrentSettingContext = createContext({})

const CurrentSettingProvider = ({children}) => {
  const [currentSetting, setCurrentSetting] = useState()

  return (
    <CurrentSettingContext.Provider
      value={{
        currentSetting,
        setCurrentSetting
      }}
    >
      {children}
    </CurrentSettingContext.Provider>
  )
}

export const useCurrentSettingContext = () => useContext(CurrentSettingContext)

CurrentSettingProvider.propTypes = {
  children: PropTypes.node
}

CurrentSettingProvider.defaultProps = {
  children: null
}

export default CurrentSettingProvider
