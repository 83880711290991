import React from 'react'
import {Link} from 'react-router-dom'

import Container from '../../../../components/Wrappers/Container'
import routes from '../../../../config/routes'
import WarningImg from '../../../../static/images/stories/warning.svg'
import IncomesCertificateWrapper from './IncomesCertificateWrapper'

const IncomesCertificateNoConfigurationView = ({isError}) => {
  const title = isError ? '!Vaya! Ocurrio un error' : '!Vaya! Aún no has configurado este año'

  return (
    <IncomesCertificateWrapper>
      <Container className="s-column s-center s-cross-center s-main-center">
        <div className="l-block" />
        <img className="s-mb-2" src={WarningImg} alt="Nómina electrónica" width="200px" height="200px" />
        <h3>{title}</h3>
        {!isError && (
          <div className="s-color-light-text">
            <p>
              Configuralo{' '}
              <Link to={`${routes.settings.base}${routes.settings.incomesWithholdingsCertificates}`}>
                <span>Aquí</span>
              </Link>{' '}
              para que puedas preparar los certificados.
            </p>
          </div>
        )}
      </Container>
    </IncomesCertificateWrapper>
  )
}

export default IncomesCertificateNoConfigurationView
