import {readBackendDate, writeBackendDate} from '../../../../helpers/dates'
import {TYPE_ALL} from '../../constants/form/history-report'

export const getHistoryReportModalInitialState = year => {
  return {
    from: readBackendDate(`${year}-01-01T00:00:00.000Z`),
    to: readBackendDate(`${year}-12-31T00:00:00.000Z`),
    selectionType: TYPE_ALL,
    contractIds: []
  }
}
