import PropTypes from 'prop-types'
import React, {createContext, useContext, useState} from 'react'

const SettingsListContext = createContext({})

const SettingsListProvider = ({children}) => {
  const [settingList, setSettingList] = useState()
  const [settingListDataTimestamp, setSettingListDataTimestamp] = useState()

  return (
    <SettingsListContext.Provider
      value={{
        settingList,
        setSettingList,
        settingListDataTimestamp,
        setSettingListDataTimestamp
      }}
    >
      {children}
    </SettingsListContext.Provider>
  )
}

export const useSettingsListContext = () => useContext(SettingsListContext)

export default SettingsListProvider
