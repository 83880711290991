import {SORT_OPTIONS} from './novelties'

export const SORT_DIRECTION_ASC = 'ASC'
export const SORT_DIRECTION_DESC = 'DESC'

export const NOVELTIES_FILTERS_DEFAULT_VALUES = {
  limit: 5,
  page: 1,
  sort: SORT_OPTIONS.EMPLOYEE,
  sort_direction: SORT_DIRECTION_ASC
}
