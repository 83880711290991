import React, {useContext, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {getConceptsNoveltyByProcessType} from '../../../helpers/concepts'
import {writeBackendDate} from '../../../helpers/dates'
import {KEY_NOVELTY_RECURRENT} from '../../../helpers/novelties'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {isAnyEqual, isEmpty, isEqual} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import {
  INITAL_STATE_FORM_ACTION,
  RECURRENT_NOVELTY_FORM_FIELDS,
  RECURRENT_NOVELTY_FORM_RULES
} from '../../../modules/payroll-process-novelties/constants/form'
import {NOVELTY_EDITION_TYPES} from '../../../modules/payroll-process-novelties/constants/novelties'
import {
  BEHAVIOR_LIST,
  BEHAVIOR_SELECT_OPTIONS,
  PERIOD_LIST,
  PERIOD_SELECT_OPTIONS
} from '../../../modules/payroll-process-novelties/constants/options'
import {getSuccessButtonText} from '../../../modules/payroll-process-novelties/helpers/form'
import {useNoveltiesFiltersContext} from '../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useFormActionContext} from '../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import {selectors as employerConceptSelectors} from '../../../redux/ducks/concepts'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {createRecurrentNovelty, updateRecurrentNovelty} from '../../../services/recurrentnovelty'
import RadioButton from '../../Atoms/Forms/RadioButton'
import Select from '../../Atoms/Forms/Select'
import TextArea from '../../Atoms/Forms/TextArea'
import Icon from '../../Atoms/Icon'
import FormInput from '../../Atoms/Templates/FormInput'
import Tooltip from '../../Atoms/Templates/Tooltip'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import PreviewMarkdown from '../../Molecules/PreviewMarkdown'
import SelectConceptInput from '../../Molecules/Selects/SelectConceptInput'
import SelectEmployeeInput from '../../Molecules/Selects/SelectEmployeeInput'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'
import NoveltyFormContainer from '../Containers/NoveltyFormContainer'
import TooltipContainer from '../Containers/TooltipContainer'
import FormulatorEditor from '../FormulatorEditor'

const RecurrentNoveltyForm = ({setResizeFormReason, initialState}) => {
  const dispatch = useDispatch()
  const {formAction, setFormAction} = useFormActionContext()
  const {activeProcess} = useContext(PayrollContext)
  const noveltyConcepts = useSelector(employerConceptSelectors.getNoveltyConcepts)
  const ConceptByProcessType = useSelector(employerConceptSelectors.getProcessConcepts)
  const concepts = getConceptsNoveltyByProcessType(noveltyConcepts, ConceptByProcessType, activeProcess.process_type_id)
  const editorRef = useRef(null)
  const {handleReload} = useNoveltiesFiltersContext()

  const isDisabled = isEqual(formAction.type, SHOW)
  const [formData, setFormData] = useState(initialState)

  const handleChangeValue = e => {
    const valueField = e.target.value
    const pattern = /^[0-9]+\.?(\d{1,2})?$/

    if (isEqual(valueField, '') || pattern.test(valueField)) {
      setFormData(state => ({
        ...state,
        value: valueField
      }))
    }
  }

  const handleOnChangeInputText = e => {
    const value = e.target.value
    const name = e.target.name

    setFormData(state => ({
      ...state,
      [name]: value
    }))
  }

  const handleOnSelectConceptInput = conceptID => {
    setFormData(state => ({
      ...state,
      concept_id: Number(conceptID)
    }))
  }

  const handleOnSelectEmployeeInput = contractID => {
    setFormData(state => ({
      ...state,
      contract_id: Number(contractID)
    }))
  }

  const handleOnChangeBeginsAt = date => {
    setFormData(state => ({
      ...state,
      begins_at: date
    }))
  }

  const handleOnChangeEndsAt = date => {
    setFormData(state => ({
      ...state,
      ends_at: date
    }))
  }

  const handleOnChangeOverrideFormula = e => {
    const value = e.target.value
    const isOverride = isEqual(value, 'true')

    setFormData(state => ({
      ...state,
      is_override: isOverride,
      is_custom_formula: false,
      application_period_sequence: '',
      standard_behavior: '',
      formula: ''
    }))

    // we request resize the form because we show or hide fields
    setResizeFormReason(`IS_OVERRIDE_${isOverride}`)
  }

  const handleOnChangeIsCustomFormula = e => {
    const value = e.target.value
    const isCustomFormula = isEqual(value, 'true')
    setFormData(state => ({
      ...state,
      is_custom_formula: isCustomFormula,
      application_period_sequence: '',
      standard_behavior: '',
      formula: 'resultado = $.VALOR_NOVEDAD;'
    }))

    // we request resize the form because we show or hide fields
    setResizeFormReason(`IS_CUSTOM_FORMULA_${isCustomFormula}`)
  }

  const handleCancelForm = () => setFormAction(INITAL_STATE_FORM_ACTION)

  const handleSuccessForm = () => {
    if (isEqual(formAction.type, CREATE)) handleCreate()
    if (isEqual(formAction.type, EDIT)) handleEdit()
  }

  const handleValidate = () => {
    const payload = {
      contract_id: formData.contract_id,
      concept_id: formData.concept_id,
      value: Number(formData.value),
      begins_at: writeBackendDate(formData.begins_at),
      ends_at: writeBackendDate(formData.ends_at),
      is_override: formData.is_override,
      is_custom_formula: formData.is_custom_formula,
      application_period_sequence: formData.application_period_sequence,
      standard_behavior: formData.standard_behavior,
      formula: '',
      notes: formData.notes
    }

    if (!payload.is_override) {
      payload.is_custom_formula = false
      payload.application_period_sequence = ''
      payload.standard_behavior = ''
      payload.formula = ''
    }

    if (payload.is_custom_formula) {
      payload.application_period_sequence = ''
      payload.standard_behavior = ''
      payload.formula = editorRef.current.getValue()
    }

    const validator = new ValidatorForm(payload, RECURRENT_NOVELTY_FORM_RULES)
    validator.setAttributeNames(RECURRENT_NOVELTY_FORM_FIELDS)

    if (validator.fails()) {
      throw validator.errors()
    }

    // validate "Formulación Personalizada"
    if (payload.is_custom_formula) {
      if (isEmpty(payload.formula)) {
        throw 'Debes agregar una formula personalizada' // eslint-disable-line
      }

      const pattern = /^resultado\s*=\s*/
      if (!pattern.test(payload.formula)) {
        throw 'La formula se debe asignar a la palabra resultado' // eslint-disable-line
      }
    }

    // validate "Formulación Asistida"
    if (payload.is_override && !payload.is_custom_formula) {
      if (isEmpty(payload.application_period_sequence)) {
        throw 'Debes seleccionar un periodo en qué se incluye la novedad' // eslint-disable-line
      }

      if (!isAnyEqual(payload.application_period_sequence, PERIOD_LIST)) {
        throw 'Debes indicar un periodo valido' // eslint-disable-line
      }

      if (isEmpty(payload.standard_behavior)) {
        throw 'Debes seleccionar un proporcionalidad' // eslint-disable-line
      }

      if (!isAnyEqual(payload.standard_behavior, BEHAVIOR_LIST)) {
        throw 'Debes indicar una proporcionalidad valida' // eslint-disable-line
      }
    }

    return payload
  }

  const handleCreate = () => {
    try {
      const payload = handleValidate()
      createRecurrentNovelty(payload, () => {
        setFormAction(INITAL_STATE_FORM_ACTION)
        handleReload()
        dispatch(notifySuccessful('Hemos creado la novedad recurrente exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const handleEdit = () => {
    try {
      const payload = handleValidate()
      updateRecurrentNovelty(formData.id, payload, () => {
        setFormAction(INITAL_STATE_FORM_ACTION)
        handleReload()
        dispatch(notifySuccessful('Hemos actualizado la novedad recurrente exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const handleOnMountEditor = () => {
    setResizeFormReason(`ON_MOUNT_EDITOR_${new Date().getTime()}`)
  }

  const successButtonText = getSuccessButtonText(formAction.type)

  const {edition_type} = formData
  const isPermissionsPartial = isEqual(edition_type, NOVELTY_EDITION_TYPES.PARTIAL)
  const isPermissionsFull = isEqual(edition_type, NOVELTY_EDITION_TYPES.FULL)

  return (
    <NoveltyFormContainer
      cancelFunction={handleCancelForm}
      successFunction={handleSuccessForm}
      successButtonText={successButtonText}
      hasEditPermissions={isPermissionsFull || isPermissionsPartial}
    >
      <SelectEmployeeInput
        isRequired
        isDisabled={isAnyEqual(formAction.type, [EDIT, SHOW]) || isPermissionsFull || isPermissionsPartial}
        name="employee_id"
        id="employee_id"
        label={RECURRENT_NOVELTY_FORM_FIELDS.contract_id}
        placeholder="Busca por Identificación o nombre"
        onSelect={handleOnSelectEmployeeInput}
        initialValue={formData.employee_description}
      />

      <SelectConceptInput
        isRequired
        disabled={isDisabled || isPermissionsPartial}
        name="concept_id"
        id="concept_id"
        label={RECURRENT_NOVELTY_FORM_FIELDS.concept_id}
        placeholder="Busca por código o nombre"
        onSelect={handleOnSelectConceptInput}
        concepts={concepts}
        initialValue={formData.concept_description}
      />

      <FormInput
        isRequired
        isDisabled={isDisabled}
        label={isDisabled ? 'Valor Aplicado' : RECURRENT_NOVELTY_FORM_FIELDS.value}
        name="value"
        id="value"
        placeholder="0.00"
        value={formData.value}
        onChange={handleChangeValue}
        align="right"
      />

      <DatePickerInput
        disabled={isDisabled || isPermissionsPartial}
        isRequired
        label={RECURRENT_NOVELTY_FORM_FIELDS.begins_at}
        id="begins_at"
        name="begins_at"
        value={formData.begins_at}
        onChange={handleOnChangeBeginsAt}
      />

      <DatePickerInput
        disabled={isDisabled}
        label={RECURRENT_NOVELTY_FORM_FIELDS.ends_at}
        id="ends_at"
        name="ends_at"
        openToDate={isEqual(formData.ends_at, '') ? formData.begins_at : formData.ends_at}
        minDate={formData.begins_at}
        value={formData.ends_at}
        onChange={handleOnChangeEndsAt}
      />

      <RadioButtonsWrapper
        label={
          isDisabled ? '¿Usaste una formula diferente para esta novedad?' : RECURRENT_NOVELTY_FORM_FIELDS.is_override
        }
      >
        <RadioButton
          disabled={isDisabled}
          name="is_override"
          label="Sí"
          value={true}
          onChange={handleOnChangeOverrideFormula}
          checked={isEqual(formData.is_override, true)}
        />
        <RadioButton
          disabled={isDisabled}
          name="is_override"
          label="No"
          value={false}
          onChange={handleOnChangeOverrideFormula}
          checked={isEqual(formData.is_override, false)}
        />
      </RadioButtonsWrapper>

      {formData.is_override && (
        <>
          <div className="flex">
            <RadioButtonsWrapper
              label={isDisabled ? '¿Cómo creaste la formula?' : RECURRENT_NOVELTY_FORM_FIELDS.is_custom_formula}
            >
              <RadioButton
                disabled={isDisabled}
                name="is_custom_formula"
                label="Asistida"
                value={false}
                onChange={handleOnChangeIsCustomFormula}
                checked={isEqual(formData.is_custom_formula, false)}
              />
              <RadioButton
                disabled={isDisabled}
                name="is_custom_formula"
                label="Personalizada"
                value={true}
                onChange={handleOnChangeIsCustomFormula}
                checked={isEqual(formData.is_custom_formula, true)}
              />
            </RadioButtonsWrapper>

            <TooltipContainer className="s-cross-start s-py-2px">
              <Icon svg="information" color="var(--blue-accent)" className="s-mr-4px cursor-pointer" />
              <Tooltip>
                <div className="whitespace-nowrap">
                  <p className="smaller s-mb-0">
                    Asistida:{' '}
                    <span className="s-color-lighter-text">
                      {isDisabled
                        ? 'Indicaste una periocidad y proporcionalidad.'
                        : 'Indica una periocidad y proporcionalidad.'}
                    </span>
                  </p>
                  <p className="smaller s-mb-0">
                    Personalizada:{' '}
                    <span className="s-color-lighter-text">
                      {isDisabled ? 'Use mi propia formula.' : 'Quiero crear mi propia formula.'}
                    </span>
                  </p>
                </div>
              </Tooltip>
            </TooltipContainer>
          </div>

          {/* Only show when the action is EDIT or CREATE, i.e. when "isDisabled = false" */}
          {!isDisabled && !formData.is_custom_formula && (
            <>
              <Select
                isRequired
                placeholder
                isDisabled={isDisabled}
                name="application_period_sequence"
                label={RECURRENT_NOVELTY_FORM_FIELDS.application_period_sequence}
                value={formData.application_period_sequence}
                onChange={handleOnChangeInputText}
              >
                {PERIOD_SELECT_OPTIONS.map(item => (
                  <option key={item.key} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </Select>

              <Select
                isRequired
                placeholder
                isDisabled={isDisabled}
                name="standard_behavior"
                label={RECURRENT_NOVELTY_FORM_FIELDS.standard_behavior}
                value={formData.standard_behavior}
                onChange={handleOnChangeInputText}
              >
                {BEHAVIOR_SELECT_OPTIONS.map(item => (
                  <option key={item.key} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </Select>
            </>
          )}
        </>
      )}

      <TextArea
        isDisabled={isDisabled}
        className="s-h-full lg-x-3 lg-y-1 s-rows-2 s-order-1 m-cols-2 lg-cols-1"
        name="notes"
        id="notes"
        placeholder="Escribe una nota"
        value={formData.notes}
        onChange={handleOnChangeInputText}
        align="left"
        label={RECURRENT_NOVELTY_FORM_FIELDS.notes}
        textarea={{
          className: 's-h-full'
        }}
      />

      {/* Only show when the action is EDIT or CREATE, i.e. when "isDisabled = false" */}
      {!isDisabled && formData.is_custom_formula && (
        <FormulatorEditor
          editorRef={editorRef}
          initialFormula={formData.formula}
          noveltySource={KEY_NOVELTY_RECURRENT}
          width="100%"
          height="150px"
          theme="light"
          onMount={handleOnMountEditor}
        />
      )}

      {/* Only show when the action is SHOW, i.e. when "isDisabled = true" */}
      {isDisabled && formData.formula && (
        <div className="s-h-full m-rows-2 lg-rows-2 m-cols-3 lg-cols-3 s-border-border normal-radius s-pxy-2">
          <PreviewMarkdown content={'**Formula utilizada:**\n```js\n' + formData.formula + '\n```'} />
        </div>
      )}
    </NoveltyFormContainer>
  )
}

export default RecurrentNoveltyForm
