import {useEffect, useState} from 'react'

import {scrollToElement} from '../helpers/scroll'
import {isEmpty, isEqual} from '../helpers/utils'
import {
  NOVELTY_DATES_STRATEGY,
  NOVELTY_PROCCESS_STRATEGY
} from '../modules/payroll-process-novelties/constants/novelties'
import {useDateFilterModalContext} from '../modules/payroll-process-novelties/providers/DateFilterModal'
import {useNoveltiesFiltersContext} from '../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useStrategyFilterContext} from '../modules/payroll-process-novelties/providers/StrategyFilter'

const useFetchNoveltiesWithConditions = (processHash, service, bottomRef) => {
  const {currentStrategyFilter} = useStrategyFilterContext()
  const {filters, setFilters, handleGetParams, defaultValue} = useNoveltiesFiltersContext()
  const {beginDate, endDate} = useDateFilterModalContext()

  const [isLastPage, setIsLastPage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [novelties, setNovelties] = useState([])

  // changes each time the strategy filter changes
  useEffect(() => {
    setFilters(defaultValue)
  }, [currentStrategyFilter, beginDate, endDate])

  // effect when the filters or the process was changed
  useEffect(() => {
    if (!filters || isEmpty(filters)) return

    const requestParams = handleGetParams(filters)

    setIsLoading(true)

    const strategyKey = isEqual(currentStrategyFilter, NOVELTY_PROCCESS_STRATEGY)
      ? NOVELTY_PROCCESS_STRATEGY
      : NOVELTY_DATES_STRATEGY

    service(
      strategyKey,
      requestParams,
      response => {
        isError && setIsError(false)

        // when backend answers with a size less than the limit, it means that is the last page
        setIsLastPage(response.length < filters.limit)

        // if the query param has a page with 1 as value then replace the data state completely
        // if isn't the page 1 then append the response to current data state
        setNovelties(prevData => {
          if (filters.page === 1) {
            return response
          }

          return [...prevData, ...response]
        })
        setIsLoading(false)

        // if the page isn't the first one then scroll to bottom
        // to avoid conflicts with any other scrolls on first render
        if (filters.page !== 1) {
          scrollToElement(bottomRef)
        }
      },
      () => {
        setIsError(true)
        setIsLoading(false)
      }
    )
  }, [filters, processHash]) // eslint-disable-line

  return {
    novelties,
    isLastPage,
    setFilters,
    isLoading,
    isError
  }
}

export default useFetchNoveltiesWithConditions
