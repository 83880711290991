import classNames from 'classnames'
import React from 'react'

import Icon from '../../../../../components/Atoms/Icon'
import {mediaToM} from '../../../../../helpers/media-queries'
import {useMedia} from '../../../../../hooks/useMedia'
import {usePrepareConfirmationModalContext} from '../../../providers/modals/PrepareConfirmationModal'

const PrepareHeaderButton = () => {
  const toM = useMedia(mediaToM)
  const {prepareConfirmationModalModalRef, isPrepareLoading} = usePrepareConfirmationModalContext()

  const handleOpenConfirmationModal = () => {
    prepareConfirmationModalModalRef.current.openModal()
  }

  return (
    <button
      type="button"
      className={classNames({small: toM, grayscale: isPrepareLoading}, 'button')}
      onClick={handleOpenConfirmationModal}
      disabled={isPrepareLoading}
    >
      <div className="s-cross-center s-main-center">
        <Icon svg="bolt" className="s-mr-4px" />
        <span>{isPrepareLoading ? 'Preparando...' : 'Prepara'}</span>
      </div>
    </button>
  )
}

export default PrepareHeaderButton
