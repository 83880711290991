import React, {forwardRef} from 'react'

import DatePickerInput from '../../../../components/Molecules/Pickers/DatePickerInput'
import ModalTemplate from '../../../../components/Wrappers/ModalTemplate'
import useDateFilterForm from '../../hooks/useDateFilterForm'

const DateFilterModal = forwardRef(({}, ref) => {
  const {setFormData, formData, handleSubmit, resetForm} = useDateFilterForm({modalRef: ref})

  return (
    <ModalTemplate ref={ref} className="s-center medium" onChangeOpen={resetForm}>
      <>
        <h3 className="s-mb-4">Novedades por fecha</h3>
        <form onSubmit={handleSubmit} className="s-mb-0">
          <div className="ed-grid s-grid-2">
            <DatePickerInput
              label="Desde"
              id="endDate"
              className="s-mb-4"
              value={formData.beginDate}
              onChange={date => setFormData(state => ({...state, beginDate: date}))}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              isRequired
            />
            <DatePickerInput
              label="Hasta"
              id="endDate"
              className="s-mb-4"
              value={formData.endDate}
              onChange={date => setFormData(state => ({...state, endDate: date}))}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              isRequired
            />
          </div>

          <button className="button" type="submit">
            Aplicar
          </button>
        </form>
      </>
    </ModalTemplate>
  )
})

DateFilterModal.displayName = 'DateFilterModal'

export default DateFilterModal
