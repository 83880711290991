import PropTypes from 'prop-types'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import routes, {payrollDateRoute} from '../../../config/routes'
import {PayrollContext} from '../../../contexts/contexts'
import {COLOMBIA_CODE} from '../../../helpers/country'
import {getMonth, readBackendDate} from '../../../helpers/dates'
import {mediaToM} from '../../../helpers/media-queries'
import {isEmpty, isEqual, isUndefined} from '../../../helpers/utils'
import useGetPayrollHeaderOptions from '../../../hooks/useGetPayrollHeaderOptions'
import {useMedia} from '../../../hooks/useMedia'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {closeEmployerMonth, setMonthActive} from '../../../services/employermonth'
import {notifyError, notifySuccessful} from '../../../services/notification'
import Icon from '../../Atoms/Icon'
import Tag from '../../Atoms/Tag'
import Tooltip from '../../Atoms/Templates/Tooltip'
import MonthYearPickerInput from '../../Molecules/Pickers/MonthYearPickerInput'
import SelectButton from '../../Molecules/Selects/SelectButton'
import PayrollHeaderWrapper from '../../Wrappers/PayrollHeaderWrapper'
import TooltipContainer from '../Containers/TooltipContainer'
import ConfirmModal from '../Modals/ConfirmModal'
import SocialSecurityModal from '../Modals/SocialSecurityModal'

const PayrollHeader = ({employerMonthID, maxMonth, isActiveMonth, isMigratedMonth, periods}) => {
  const history = useHistory()

  const employerSession = useSelector(employerSelectors.getSession)
  const {payrollDate} = useContext(PayrollContext)
  const toM = useMedia(mediaToM)
  const dispatch = useDispatch()

  const confirmModalCloseMonth = useRef()
  const socialSecurityModalRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [isAllPeriodClosed, setIsAllPeriodClosed] = useState(false)

  const hasSocialSecurityAndElectronicPayroll =
    !isActiveMonth && payrollDate.year >= 2023 && isEqual(employerSession.code_iso_3, COLOMBIA_CODE)

  useEffect(() => {
    setIsAllPeriodClosed(false)

    const periodFound = periods.find(p => p.is_active)

    if (isUndefined(periodFound)) {
      setIsAllPeriodClosed(true)
    }
  }, [periods])

  const handleChangeDate = date => {
    history.push(payrollDateRoute({year: date.getFullYear(), month: date.getMonth() + 1}))
  }

  const handleOpenConfirmModalCloseMonth = () => {
    confirmModalCloseMonth.current.openModal()
  }

  const handleCloseMonth = () => {
    setIsLoading(true)

    closeEmployerMonth(
      employerMonthID,
      response => {
        const nextMonth = {year: response.year_number, month: response.month_number}

        setIsLoading(false)
        dispatch(setMonthActive(response))
        dispatch(notifySuccessful('El mes fue cerrado correctamente 🎉'))

        history.push(payrollDateRoute(nextMonth))
      },
      error => {
        setIsLoading(false)
        dispatch(notifyError(error))
      }
    )
  }

  const selectOptions = useGetPayrollHeaderOptions(
    socialSecurityModalRef,
    isMigratedMonth,
    hasSocialSecurityAndElectronicPayroll
  )

  return (
    <PayrollHeaderWrapper className="date-picker">
      <label className="s-relative">
        <h1 className="t2 s-mb-0 s-cross-center s-cross-center nowrap hover-change cursor-pointer normal-radius s-px-8px s-py-4px">
          <Icon className="s-mr-8px" svg="calendar" color="currentColor" />
          <span className="s-mr-8px">{`${getMonth(payrollDate.month - 1)} del ${payrollDate.year}`}</span>
          <Icon size="1rem" svg="down" />
        </h1>
        <MonthYearPickerInput
          id="payroll-date"
          minDate={readBackendDate(employerSession.first_pay_period_migration)}
          maxDate={new Date(maxMonth.year, maxMonth.month - 1, 1)}
          value={new Date(payrollDate.year, payrollDate.month - 1, 1)}
          onChange={handleChangeDate}
        />
      </label>

      <div className={`s-column m-row s-cross-center s-flex-gap-16px ${toM && 's-100'}`}>
        {isMigratedMonth && <Tag text="Mes Migrado" color="red" hasCircle></Tag>}

        {isActiveMonth && (
          <TooltipContainer className={`${toM && 's-100'}`}>
            <button
              className={`button  ${toM && 's-100'}`}
              disabled={isLoading || !isAllPeriodClosed}
              onClick={handleOpenConfirmModalCloseMonth}
            >
              <div className="s-cross-center s-main-center">
                <Icon svg="lock-open" className="s-mr-4px" />
                <span>{isLoading ? 'Cerrando...' : 'Cierra el mes'}</span>
              </div>
            </button>

            {!isAllPeriodClosed && (
              <Tooltip>
                <div className="whitespace-nowrap">
                  <span>Primero debes:</span>
                  <p className="s-mb-0 smaller s-color-lighter-text">
                    {isLoading ? 'Cerrando...' : 'Cerrar todos tus periodos'}
                  </p>
                </div>
              </Tooltip>
            )}
          </TooltipContainer>
        )}

        {/* social security, electronic payroll and interface*/}
        {!isEmpty(selectOptions) && (
          <SelectButton
            iconName="cube"
            description="Actividades"
            classButton={`${toM && 's-100'}`}
            options={selectOptions}
          />
        )}
      </div>

      {/* Modal para cerrar el periodo */}
      <ConfirmModal
        ref={confirmModalCloseMonth}
        hasCodeConfirmation
        confirmFunction={handleCloseMonth}
        title="Cierra el Mes"
        content={
          <>
            ¿Estás seguro de cerrar el mes{' '}
            <span className="s-color-title">{`${getMonth(payrollDate.month - 1)} del ${payrollDate.year}`}</span>? Esta
            acción no se puede revetir.
          </>
        }
      />

      {/* SocialSecurity modal */}
      <SocialSecurityModal ref={socialSecurityModalRef} year={payrollDate.year} month={payrollDate.month} />
    </PayrollHeaderWrapper>
  )
}

export default PayrollHeader
