import classNames from 'classnames'
import React, {useContext} from 'react'

import ActionButton from '../../../components/Atoms/Templates/ActionButton'
import {PayrollContext} from '../../../contexts/contexts'
import {KEY_NOVELTY_OCCASIONAL} from '../../../helpers/novelties'
import {CREATE, STATUS_HISTORY} from '../../../helpers/payroll'
import {isEqual, isNull} from '../../../helpers/utils'
import {useNoveltiesCategoriesContext} from '../providers/NoveltiesCategories'
import {useOccasionalNoveltyBulkContext} from '../providers/OccasionalNoveltyBulk'
import {useFormActionContext} from '../providers/PayrollReportModalWrapper'

const NoveltyTableAddButtons = () => {
  const {formAction, setFormAction} = useFormActionContext()
  const {activeProcess} = useContext(PayrollContext)
  const {bulkModalRef, isUploading} = useOccasionalNoveltyBulkContext()
  const {activeCategory} = useNoveltiesCategoriesContext()

  const handleOnClickOccasionalNoveltyBulk = () => {
    bulkModalRef.current.openModal()
  }

  const handleAddNovelty = () => {
    setFormAction({
      type: CREATE,
      noveltyID: 0
    })
  }

  if (formAction.type || isEqual(activeProcess?.status, STATUS_HISTORY)) return null

  return (
    <>
      {isEqual(activeCategory, KEY_NOVELTY_OCCASIONAL) && (
        <ActionButton
          icon="arrow-up-tray"
          onClick={handleOnClickOccasionalNoveltyBulk}
          isDisabled={isUploading}
          className={classNames({grayscale: isUploading}, 's-border-border')}
        />
      )}
      <ActionButton content="Nuevo" icon="plus" onClick={handleAddNovelty} isMain />
    </>
  )
}

export default NoveltyTableAddButtons
