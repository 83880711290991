import Proptypes from 'prop-types'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'

import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {capitalize} from '../../../helpers/string-format'
import {isNull} from '../../../helpers/utils'
import NoveltyTableAddButtons from '../../../modules/payroll-process-novelties/components/NoveltyTableAddButtons'
import useDownloadNoveltyReport from '../../../modules/payroll-process-novelties/hooks/useDownloadNoveltyReport'
import {useNoveltiesFiltersContext} from '../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useStrategyFilterContext} from '../../../modules/payroll-process-novelties/providers/StrategyFilter'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {generateLeaveNoveltiesReport} from '../../../services/leaveNovelty'
import Select from '../../Atoms/Forms/Select'
import ActionButton from '../../Atoms/Templates/ActionButton'
import SelectEmployeeInput from '../Selects/SelectEmployeeInput'
import LeaveNoveltyTableContent from './content/LeaveNoveltyTableContent'

const LeaveNoveltyTable = () => {
  const employerSession = useSelector(employerSelectors.getSession)
  const {setFilters} = useNoveltiesFiltersContext()
  const {currentStrategyFilter} = useStrategyFilterContext()

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const leaveTypes = !isNull(cacheGeneral) && cacheGeneral.data?.leave_types
  leaveTypes.unshift({id: 0, description: 'TODOS'})

  const [leavetypeID, setLeaveTypeID] = useState(0)

  const handleOnSelectEmployeeInput = contractID => {
    setFilters(prev => {
      if (contractID === 0) {
        const {contract_id, ...rest} = prev
        return rest
      }

      return {...prev, contract_id: contractID, page: 1}
    })
  }

  const handleOnChangeLeaveType = e => {
    const leaveTypeID = e.target.value
    setLeaveTypeID(leaveTypeID)
    setFilters(prev => {
      return {...prev, leave_type_id: leaveTypeID, page: 1}
    })
  }

  const {handleGenerateReport, isLoadingDownloadReport} = useDownloadNoveltyReport(generateLeaveNoveltiesReport)

  return (
    <>
      <div
        key={currentStrategyFilter}
        className="m-cross-end s-flex-gap-12px flex-grow m-main-end s-column m-row s-px-2 s-pb-2 m-px-4 m-pb-4"
      >
        <SelectEmployeeInput
          className="small"
          name="employee_id"
          id="employee_id"
          label="Empleado"
          placeholder="Busca por Identificación o nombre"
          onSelect={handleOnSelectEmployeeInput}
        />

        <Select
          className="small"
          id="leave_type"
          name="leave_type"
          label="Tipo"
          placeholder
          onChange={handleOnChangeLeaveType}
          value={leavetypeID}
        >
          {Array.isArray(leaveTypes) &&
            leaveTypes.map(leaveType => (
              <option key={leaveType.id} value={leaveType.id}>
                {capitalize(leaveType.description)}
              </option>
            ))}
        </Select>

        <ActionButton
          icon="download"
          onClick={handleGenerateReport}
          className="s-border-border"
          isDisabled={isLoadingDownloadReport}
        />
        <NoveltyTableAddButtons />
      </div>

      <LeaveNoveltyTableContent />
    </>
  )
}

LeaveNoveltyTable.propTypes = {
  novelties: Proptypes.array.isRequired
}

LeaveNoveltyTable.defaultProps = {
  novelties: []
}

export default LeaveNoveltyTable
