import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import ParameterSettingSkeleton from '../../../../components/Organisms/Skeleton/ParameterSettingSkeleton'
import {getSettingsByCertificateYear} from '../../../../services/certificate'
import {useCurrentSettingContext} from '../../providers/CurrentSetting'
import CertificateSettingVoidContent from '../CertificateSettingVoidContent'
import SingleCertificateSettingsView from './SingleCertificateSettingsView'

const SingleCertificateSettingWrapper = () => {
  const {currentSetting, setCurrentSetting} = useCurrentSettingContext()
  const {year} = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const fetchYearSetting = () => {
    getSettingsByCertificateYear(
      year,
      response => {
        setCurrentSetting(response)
        setIsLoading(false)
      },
      () => {
        setIsLoading(false)
        setIsError(true)
      }
    )
  }

  useEffect(() => {
    fetchYearSetting()
  }, [])

  if (isLoading) return <ParameterSettingSkeleton />

  if (!currentSetting || isError)
    return (
      <div className="n-container">
        <CertificateSettingVoidContent text="Ocurrio un error al cargar la configuración" />
      </div>
    )

  return <SingleCertificateSettingsView />
}

export default SingleCertificateSettingWrapper
