import React, {useEffect} from 'react'

import SettingHeader from '../../../components/Organisms/Headers/SettingHeader'
import TabsWrapper from '../../../components/Wrappers/TabsWrapper'
import CurrentSettingProvider from '../providers/CurrentSetting'
import CertificateSettingsTabWrapper from './CertificateSettingsTabWrapper'
import SingleCertificateSettingWrapper from './single-certificate/SingleCertificateSettingWrapper'

const CertificateSetting = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SettingHeader icon="document" title="Certificados Ingresos y Retenciones" />
      <div className="lg-cols-2">
        <TabsWrapper defaultActive={1}>
          <CertificateSettingsTabWrapper>
            <CurrentSettingProvider>
              <SingleCertificateSettingWrapper />
            </CurrentSettingProvider>
          </CertificateSettingsTabWrapper>
        </TabsWrapper>
      </div>
    </>
  )
}

export default CertificateSetting
