export const FIRST_PERIOD = 'FIRST_PERIOD'
export const LAST_PERIOD = 'LAST_PERIOD'
export const ALL_PERIODS = 'ALL_PERIODS'

export const BY_SALARY_DAYS = 'APPLY_BY_SALARY_DAYS'
export const BY_CONTRACT_DAYS = 'APPLY_BY_CONTRACT_DAYS'
export const BY_FIX_VALUE = 'APPLY_BY_FIX_VALUE'

export const PERIOD_LIST = [FIRST_PERIOD, LAST_PERIOD, ALL_PERIODS]
export const BEHAVIOR_LIST = [BY_SALARY_DAYS, BY_CONTRACT_DAYS, BY_FIX_VALUE]

export const PERIOD_SELECT_OPTIONS = [
  {key: FIRST_PERIOD, value: 'Primer periodo'},
  {key: LAST_PERIOD, value: 'Último periodo'},
  {key: ALL_PERIODS, value: 'Todos los periodos'}
]

export const BEHAVIOR_SELECT_OPTIONS = [
  {key: BY_SALARY_DAYS, value: 'Días del Salario'},
  {key: BY_CONTRACT_DAYS, value: 'Días Laborados'},
  {key: BY_FIX_VALUE, value: 'Ninguna, usa valor fijo'}
]
