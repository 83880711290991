import React, {useEffect, useRef} from 'react'

const TabsRefWrapper = ({children}) => {
  const tabsHeaderRef = useRef(null)

  useEffect(() => {
    const tabsHeaderRefObserved = tabsHeaderRef.current

    const observer = new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle('is-sticky', e.intersectionRatio < 1)
      },
      // we set a margin negative that is the distant from top viewport downwards, when the
      // tabsHeaderRefObserved (tabs-processes) has that top distant then the class is-sticky is applied
      {rootMargin: '-150px 0px', threshold: [1]}
    )

    if (tabsHeaderRefObserved) observer.observe(tabsHeaderRefObserved)

    return () => {
      if (tabsHeaderRefObserved) observer.unobserve(tabsHeaderRefObserved)
    }
  }, [tabsHeaderRef])

  return (
    <div className="tabs-processes" ref={tabsHeaderRef}>
      {children}
    </div>
  )
}

export default TabsRefWrapper
