import {useState} from 'react'
import {useDispatch} from 'react-redux'

import {notifyError} from '../../../services/notification'
import {useDownloadLinkReportContext} from '../../employees-panel/providers/DownloadLinkReport'
import {NOVELTIES_STRATEGIES_DATA} from '../constants/strategies'
import {useNoveltiesFiltersContext} from '../providers/NoveltiesFilters'
import {useStrategyFilterContext} from '../providers/StrategyFilter'

const useDownloadNoveltyReport = service => {
  const {currentStrategyFilter} = useStrategyFilterContext()
  const [isLoadingDownloadReport, setIsLoadingDownloadReport] = useState(false)
  const {setObject, setFileNameDownloaded, downloadLinkReport} = useDownloadLinkReportContext()
  const {handleGetParams, filters} = useNoveltiesFiltersContext()
  const dispatch = useDispatch()

  const handleGenerateReport = () => {
    const {path: strategy} = NOVELTIES_STRATEGIES_DATA[currentStrategyFilter]
    setIsLoadingDownloadReport(true)

    const params = handleGetParams(filters)

    // pass strategy and params
    service(strategy, params)
      .then(({data, headers}) => {
        setObject(data)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
      })
      .catch(err => dispatch(notifyError(err)))
      .finally(() => setIsLoadingDownloadReport(false))
  }

  return {isLoadingDownloadReport, handleGenerateReport}
}

export default useDownloadNoveltyReport
