import {shortFormatDate} from 'helpers/dates'
import {isEmpty, isEqual} from 'helpers/utils'
import {NOVELTY_PROCCESS_STRATEGY} from 'modules/payroll-process-novelties/constants/novelties'
import React from 'react'

import Icon from '../../../../components/Atoms/Icon'
import {useDateFilterModalContext} from '../../providers/DateFilterModal'
import {useStrategyFilterContext} from '../../providers/StrategyFilter'

const DateFilterWarning = () => {
  const {beginDate, endDate, dateFilterModalRef} = useDateFilterModalContext()
  const {currentStrategyFilter} = useStrategyFilterContext()

  if (isEqual(currentStrategyFilter, NOVELTY_PROCCESS_STRATEGY) || isEmpty(beginDate) || isEmpty(endDate)) return null

  const handleEditDateFilter = () => {
    dateFilterModalRef.current.openModal()
  }

  return (
    <div className="s-bg-yellow flex s-gap-2 s-cross-center s-main-center s-py-2 s-px-2 s-mb-4">
      <p className="s-mb-0">
        ⚠️ Estas viendo ahora las novedades del {shortFormatDate(beginDate)} al {shortFormatDate(endDate)}
      </p>
      <button type="button" className="s-bg-transparent s-border-none s-color-blue" onClick={handleEditDateFilter}>
        <Icon svg="edit" size="1.5em" />
      </button>
    </div>
  )
}

export default DateFilterWarning
