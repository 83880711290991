import React from 'react'

import Container from '../../../../components/Wrappers/Container'
import PayslipImg from '../../../../static/images/stories/payslip.svg'

const SelectedCertificateEmptyState = ({isError}) => {
  const title = isError ? '¡Vaya! Ocurrio un error al cargar el certificado' : '¡Vaya! esto se ve un poco vacío'

  return (
    <Container className="s-column s-center s-cross-center s-main-center s-cols-2">
      <div className="l-block" />
      <img
        className="s-mb-2"
        src={PayslipImg}
        alt="Certificado de ingresos y retenciones"
        width="200px"
        height="200px"
      />
      <h3>{title}</h3>
      {!isError && <p className="s-color-light-text">Selecciona un empleado y su certificado aparecerá aquí.</p>}
    </Container>
  )
}

export default SelectedCertificateEmptyState
