import classNames from 'classnames'
import React from 'react'

import Icon from '../../../../../components/Atoms/Icon'
import {mediaToM} from '../../../../../helpers/media-queries'
import {useMedia} from '../../../../../hooks/useMedia'
import {useCloseCertificatesContext} from '../../../providers/modals/CloseCertificate'

const CloseHeaderButton = () => {
  const toM = useMedia(mediaToM)
  const {CloseCertificatesModalRef, isCloseCertificatesLoading} = useCloseCertificatesContext()

  const openCloseCertificatesModal = () => {
    CloseCertificatesModalRef.current.openModal()
  }

  return (
    <button
      type="button"
      className={classNames({small: toM, grayscale: isCloseCertificatesLoading}, 'button ghost')}
      onClick={openCloseCertificatesModal}
      disabled={isCloseCertificatesLoading}
    >
      <div className="s-cross-center s-main-center">
        <Icon svg="lock-open" className="s-mr-4px" />
        <span>{isCloseCertificatesLoading ? 'Cerrando...' : 'Cierra'}</span>
      </div>
    </button>
  )
}

export default CloseHeaderButton
