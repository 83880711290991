import React from 'react'

import DownloadLinkReportProvider from '../../../modules/employees-panel/providers/DownloadLinkReport'
import {NOVELTY_CATEGORY_TABLES} from '../../../modules/payroll-process-novelties/components/novelty'
import StrategiesSelect from '../../../modules/payroll-process-novelties/components/StrategiesSelect'
import useLoadCategories from '../../../modules/payroll-process-novelties/hooks/useLoadCategories'
import {useNoveltiesCategoriesContext} from '../../../modules/payroll-process-novelties/providers/NoveltiesCategories'
import SelectCategory from '../../Molecules/Selects/SelectCategory'
import {TableHeader} from '../../Molecules/Templates/TableHeader'
import {TableBody} from '../Containers/TableBody'

const NoveltyTable = () => {
  const {activeCategory} = useNoveltiesCategoriesContext()

  useLoadCategories()

  const isValidCategory = Object.keys(NOVELTY_CATEGORY_TABLES).includes(activeCategory)

  return (
    <TableBody className="novelty-table">
      <TableHeader>
        <SelectCategory />
        <StrategiesSelect />
      </TableHeader>

      <DownloadLinkReportProvider>
        {isValidCategory && NOVELTY_CATEGORY_TABLES[activeCategory]}
      </DownloadLinkReportProvider>
    </TableBody>
  )
}

export default NoveltyTable
