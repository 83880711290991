import {PayrollContext} from 'contexts/contexts'
import {readBackendDate} from 'helpers/dates'
import {PropTypes} from 'prop-types'
import React, {createContext, useContext, useRef, useState} from 'react'

import DateFilterModal from '../components/dates/DateFilterModal'

const DateFilterModalContext = createContext({})

const DateFilterModalProvider = ({children}) => {
  const dateFilterModalRef = useRef()
  const {activeProcess} = useContext(PayrollContext)

  const [beginDate, setBeginDate] = useState(readBackendDate(activeProcess.begins_at))
  const [endDate, setEndDate] = useState(readBackendDate(activeProcess.ends_at))

  return (
    <DateFilterModalContext.Provider
      value={{
        dateFilterModalRef,
        beginDate,
        setBeginDate,
        endDate,
        setEndDate
      }}
    >
      {children}
      <DateFilterModal ref={dateFilterModalRef} />
    </DateFilterModalContext.Provider>
  )
}

export const useDateFilterModalContext = () => useContext(DateFilterModalContext)

DateFilterModalProvider.propTypes = {
  children: PropTypes.node
}

DateFilterModalProvider.defaultProps = {
  children: null
}

export default DateFilterModalProvider
