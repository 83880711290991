import classNames from 'classnames'
import React from 'react'
import {useSelector} from 'react-redux'

import {isEmpty} from '../../../../helpers/utils'
import {selectors as employerConceptSelectors} from '../../../../redux/ducks/concepts'
import LectureLineItemStyles from '../../../../styles/modules/icomes-certificate/incomes-withholdings-certificate.module.scss'
import {formatConceptsToObjects} from '../../helpers/concepts'

const LectureLineItem = ({line}) => {
  const employerConcepts = useSelector(employerConceptSelectors.getConcepts)

  const {name, concepts} = line
  const hasConcepts = concepts && !isEmpty(concepts)
  const conceptsObject = formatConceptsToObjects(employerConcepts)

  return (
    <div>
      <h4 className="small s-mb-2px">Renglón {name}</h4>
      <div
        className={classNames(
          LectureLineItemStyles.container,
          'flex s-row s-cross-center s-border-border s-pxy-4px normal-radius s-mb-4'
        )}
      >
        {hasConcepts &&
          concepts.map((conceptId, index) => (
            <div
              key={index}
              className={classNames(
                LectureLineItemStyles.chip,
                's-cross-center small-radius s-pxy-4px s-bg-border s-w-fit'
              )}
            >
              <span className="small s-pl-2px s-mr-4px">{conceptsObject[conceptId]}</span>
            </div>
          ))}
      </div>
    </div>
  )
}

export default LectureLineItem
