import classNames from 'classnames'
import React, {useState} from 'react'

import useHandleCreateNewCertificate from '../../../hooks/useHandleCreateNewCertificate'
import {useSettingsListContext} from '../../../providers/SettingsList'

const NewSettingButton = ({hasSettings}) => {
  const {settingList} = useSettingsListContext()
  const [isLoading, setIsLoading] = useState(false)
  const {handleCreateNewCertificate} = useHandleCreateNewCertificate(setIsLoading)

  const isLastSettingClosed = settingList[0]?.is_closed

  const isButtonDisabled = isLoading || (!isLastSettingClosed && hasSettings)

  return (
    <button
      type="button"
      disabled={isButtonDisabled}
      className={classNames({grayscale: isButtonDisabled}, 'button small')}
      onClick={handleCreateNewCertificate}
    >
      {isLoading ? 'Creando...' : 'Nuevo'}
    </button>
  )
}

export default NewSettingButton
