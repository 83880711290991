import classNames from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'

import routes from '../../../../config/routes'
import {readLinesFromSettingData} from '../../helpers/lines'
import useEditCertificateForm from '../../hooks/useEditCertificateForm'
import {useCurrentSettingContext} from '../../providers/CurrentSetting'
import LineItem from '../line/LineItem'

const EditCertificateForm = () => {
  const {formData, setformData, handleOnSubmit, isLoading} = useEditCertificateForm()

  const {
    currentSetting: {setting}
  } = useCurrentSettingContext()

  const lines = readLinesFromSettingData(setting)

  return (
    <form onSubmit={handleOnSubmit}>
      {lines.map(line => {
        return <LineItem key={line.name} line={line} formData={formData} setformData={setformData} />
      })}
      <footer className="flex s-flex-gap-12px">
        <button className={classNames({grayscale: isLoading}, 'button')} type="submit" disabled={isLoading}>
          {isLoading ? 'Guardando...' : 'Guardar'}
        </button>
        <Link to={`${routes.settings.base}${routes.settings.incomesWithholdingsCertificates}`}>
          <span className="button ghost">Atras</span>
        </Link>
      </footer>
    </form>
  )
}

export default EditCertificateForm
