import React, {useRef, useState} from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom'

import {isEmpty, isEqual} from '../../../helpers/utils'
import {SETTINGS_OPTIONS} from '../../../modules/settings/constants/options'
import SearchInput from '../../Atoms/Forms/SearchInput'
import SidebarSetting from '../../Molecules/SidebarSetting'
import Container from '../../Wrappers/Container'

const SettingsPanel = () => {
  const [searchValue, setSearchValue] = useState('')
  const [optionList, setOptionList] = useState(SETTINGS_OPTIONS)
  const [activeOptionID, setActiveOptionID] = useState(0)
  const optionsContainer = useRef()
  const history = useHistory()
  const {path} = useRouteMatch()

  const handleSearch = e => {
    const value = e.target.value
    setSearchValue(value)

    if (isEmpty(value)) {
      setOptionList(SETTINGS_OPTIONS)
      return
    }

    const searchValue = value.toLowerCase()
    const filterList = SETTINGS_OPTIONS.filter(
      o =>
        o.name.toLowerCase().includes(searchValue) ||
        o.description.toLowerCase().includes(searchValue) ||
        !isEqual(
          o.tags.findIndex(t => t.includes(searchValue)),
          -1
        )
    )

    setOptionList(filterList)

    resetScroll()
  }

  const handleSelectOption = option => {
    setActiveOptionID(option.id)

    history.push(`${path}${option.route}`)
  }

  const resetScroll = () => {
    const container = optionsContainer.current
    if (!container) return

    container.scrollTo({top: 0, behavior: 'smooth'})
  }

  return (
    <Container className="sticky-element s-h-vh employees-panel">
      <div className="panel-section s-mb-16px m-mb-24px">
        <header className="s-mb-16px m-mb-24px s-cross-center s-main-justify">
          <h4 className="s-mb-0">Configuraciones</h4>
          <div className="flex">{/* Here include options additionals */}</div>
        </header>
        <SearchInput
          placeholder="Busca la configuración que necesites"
          className="s-mb-16px m-mb-24px"
          value={searchValue}
          onChange={handleSearch}
        />
      </div>

      <div className="panel-section ed-grid row-gap s-gap-1 s-cross-start" ref={optionsContainer}>
        {isEmpty(optionList) ? (
          <div className="s-px-6px s-py-8px s-mb-0 s-center small s-color-light-text s-bg-body-alt">
            <span role="img" aria-label="" className="s-mr-8px">
              🥺
            </span>
            <span>¡Vaya! no encontramos ninguna opción de configuración.</span>
          </div>
        ) : (
          optionList.map(o => (
            <SidebarSetting
              key={o.id}
              isActive={isEqual(o.id, activeOptionID)}
              option={o}
              onSelectOption={handleSelectOption}
            />
          ))
        )}
      </div>
    </Container>
  )
}

export default SettingsPanel
