import classNames from 'classnames'
import React from 'react'

import Icon from '../../../../../components/Atoms/Icon'
import {mediaToM} from '../../../../../helpers/media-queries'
import {useMedia} from '../../../../../hooks/useMedia'

const SendHeaderButton = () => {
  const toM = useMedia(mediaToM)

  return (
    <button
      type="button"
      className={classNames({small: toM}, 'button ghost grayscale')}
      onClick={console.error('missing endpoint')}
      // TODO: VALIDATE WITH ISLOADING STATE WHEN REPLACE ONCLICK FUNCTION
      disabled
    >
      <div className="s-cross-center s-main-center">
        <Icon svg="rocket" className="s-mr-4px" />
        <span>Envia</span>
      </div>
    </button>
  )
}

export default SendHeaderButton
