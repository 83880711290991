export const TYPE_SELECTION = 'SELECTION'
export const TYPE_ALL = 'ALL'

export const HISTORY_REPORT_RULES = {
  from: 'date|required',
  to: 'date|required|after_or_equal:from'
}

export const HISTORY_REPORT_FIELD_NAMES = {
  from: 'Desde',
  to: 'Hasta'
}
