import React from 'react'

import Settings from '../../../static/images/stories/settings.svg'

const CertificateSettingVoidContent = ({text = '¡Vaya! no encontramos configuraciones'}) => {
  return (
    <div className="s-column s-center s-cross-center s-main-center">
      <img className="s-mb-2" src={Settings} alt="Certificado de ingresos y retenciones" width="200px" height="200px" />
      <h3>{text}</h3>
    </div>
  )
}

export default CertificateSettingVoidContent
