import {useDispatch} from 'react-redux'

import {stringFormatDate} from '../../../helpers/dates'
import {notifyError} from '../../../services/notification'
import {getHistoryPayrollByRange} from '../../../services/payrollReports'
import {useDownloadLinkReportContext} from '../../employees-panel/providers/DownloadLinkReport'
import {useIsReportLoadingContext} from '../providers/modals/reports/IsReportDownload'

const useHandleGenerateHistoricalReport = () => {
  const {setFileNameDownloaded, setObject, downloadLinkReport} = useDownloadLinkReportContext()
  const {setIsReportsLoading} = useIsReportLoadingContext()
  const dispatch = useDispatch()

  const handleOnClickGenerateHistoryReport = (from, to, contractIDs) => {
    getHistoryPayrollByRange(
      stringFormatDate(from),
      stringFormatDate(to),
      contractIDs,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setIsReportsLoading(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsReportsLoading(false)
      }
    )
  }

  return {
    onGenerate: handleOnClickGenerateHistoryReport
  }
}

export default useHandleGenerateHistoricalReport
