import React from 'react'

import Icon from '../../../../components/Atoms/Icon'
import Container from '../../../../components/Wrappers/Container'
import Paysheet from '../../../../static/images/stories/paysheet.svg'
import IncomesCertificateWrapper from './IncomesCertificateWrapper'

const IncomesCertificateEmptyListView = () => {
  return (
    <IncomesCertificateWrapper>
      <Container className="s-column s-center s-cross-center s-main-center">
        <div className="l-block" />
        <img className="s-mb-2" src={Paysheet} alt="Nómina electrónica" width="200px" height="200px" />
        <h3>Aún no tienes certificados</h3>
        <div className="s-color-light-text">
          Da click en el botón{' '}
          <span className="s-color-text">
            <Icon svg="bolt" size="12" className="s-mr-2px" />
            Preparar
          </span>{' '}
          y tus certificados aparecerán aquí.
        </div>
      </Container>
    </IncomesCertificateWrapper>
  )
}

export default IncomesCertificateEmptyListView
