import React from 'react'

import CertificatesReportModalProvider from '../../../providers/modals/reports/CertificatesReportModal'
import HistoricReportModalProvider from '../../../providers/modals/reports/HistoricReportModal'
import IsReportLoadingProvider from '../../../providers/modals/reports/IsReportDownload'
import ReportsHeaderButton from './ReportsHeaderButton'

const ReportsHeaderButtonWrapper = () => {
  return (
    <IsReportLoadingProvider>
      <CertificatesReportModalProvider>
        <HistoricReportModalProvider>
          <ReportsHeaderButton />
        </HistoricReportModalProvider>
      </CertificatesReportModalProvider>
    </IsReportLoadingProvider>
  )
}

export default ReportsHeaderButtonWrapper
