import React from 'react'

import DownloadLinkReportProvider from '../../employees-panel/providers/DownloadLinkReport'
import CertificateSettingProvider from '../providers/CertificateSetting'
import IncomesCertificatesContent from './IncomesCertificatesContent'

const IncomesCertificatesPage = () => {
  return (
    <CertificateSettingProvider>
      <DownloadLinkReportProvider>
        <IncomesCertificatesContent />
      </DownloadLinkReportProvider>
    </CertificateSettingProvider>
  )
}

export default IncomesCertificatesPage
