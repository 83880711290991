import React, {useContext} from 'react'
import {useLocation} from 'react-router-dom'

import EmployeesPanel from '../../../../components/Organisms/Panels/EmployeesPanel'
import {QUERY_PARAM_HASH_CONTRACT} from '../../../../config/routes'
import {PayrollContext} from '../../../../contexts/contexts'
import {history} from '../../../../helpers/history'
import {isEqual} from '../../../../helpers/utils'
import useFetchWithConditions from '../../../../hooks/useFetchWithConditions'
import {getEmployeesWithGeneratedCertificate} from '../../../../services/dianForm220'
import {DEFAULT_SORT_OPTIONS} from '../../constants/employees'

const IncomesCertificatesEmployeesFilter = () => {
  const {search} = useLocation()
  const {
    payrollDate: {year, month}
  } = useContext(PayrollContext)

  const handleGetEmployeesWithCertificate = (queryParams, callback, callbackErr) => {
    const params = new URLSearchParams(queryParams)
    params.set('year', year)
    params.set('month', month)

    getEmployeesWithGeneratedCertificate(params.toString(), callback, callbackErr)
  }

  const {data, loading, page, setPage, isLastPage, setSortOption, setFilterOption, setSearchValue} =
    useFetchWithConditions(DEFAULT_SORT_OPTIONS, 20, handleGetEmployeesWithCertificate)

  const unSelectEmployee = () => {
    const newQueryParams = new URLSearchParams(search)
    newQueryParams.delete(QUERY_PARAM_HASH_CONTRACT)

    history.replace({search: `?${newQueryParams.toString()}`})
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const handleSelectEmployee = employee => {
    const newQueryParams = new URLSearchParams(search)
    newQueryParams.set(QUERY_PARAM_HASH_CONTRACT, employee.contract_hash)

    history.replace({search: `?${newQueryParams.toString()}`})
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const handleChangeSort = key => {
    unSelectEmployee()
    setSortOption(key)
  }

  const handleChangeFilter = key => {
    unSelectEmployee()
    setFilterOption(key)
  }

  const handleSearchEmployee = value => {
    setSearchValue(value)
  }

  const handlerScrollEnd = () => {
    if (isLastPage) return

    unSelectEmployee()
    setPage(page + 1)
  }

  return (
    <EmployeesPanel
      employees={data}
      loading={loading && isEqual(page, 1)}
      limitEmployees={100}
      defaultSortOption={DEFAULT_SORT_OPTIONS}
      sortOptions={[DEFAULT_SORT_OPTIONS, {key: 'last_name', value: 'Apellido'}]}
      onSelectEmployee={handleSelectEmployee}
      onChangeFilter={handleChangeFilter}
      onChangeSort={handleChangeSort}
      onChangeSearch={handleSearchEmployee}
      onScrollEnd={handlerScrollEnd}
    />
  )
}

export default IncomesCertificatesEmployeesFilter
