import React from 'react'

import {isEqual} from '../../../helpers/utils'
import {NO_CONFIGURATION_ERROR_MESSAGE} from '../constants/error'
import useGetCertificateSettingByYear from '../hooks/useGetCertificateSettingByYear'
import CertificatesSummaryProvider from '../providers/CertificatesSummary'
import IncomesCertificatesList from './IncomesCertificatesList'
import IncomesCertificateNoConfigurationView from './layout/IncomesCertificateNoConfigurationView'
import IncomesCertificateSkeleton from './layout/IncomesCertificateSkeleton'

const IncomesCertificatesContent = () => {
  const {isLoading, isError, data, error} = useGetCertificateSettingByYear()

  if (isLoading) return <IncomesCertificateSkeleton />
  if (isError && !isEqual(error, NO_CONFIGURATION_ERROR_MESSAGE))
    return <IncomesCertificateNoConfigurationView isError />
  if (!data) return <IncomesCertificateNoConfigurationView />

  return (
    <CertificatesSummaryProvider>
      <IncomesCertificatesList />
    </CertificatesSummaryProvider>
  )
}

export default IncomesCertificatesContent
