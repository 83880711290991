import React, {useEffect} from 'react'

import SettingHeader from '../../../components/Organisms/Headers/SettingHeader'
import TabsWrapper from '../../../components/Wrappers/TabsWrapper'
import SettingsListProvider from '../providers/SettingsList'
import CertificateSettingsTabWrapper from './CertificateSettingsTabWrapper'
import CertificateSettingsViewWrapper from './list/CertificateSettingsViewWrapper'

const CertificateSettings = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SettingHeader icon="document" title="Certificados Ingresos y Retenciones" />
      <div className="lg-cols-2">
        <TabsWrapper defaultActive={1}>
          <CertificateSettingsTabWrapper>
            <SettingsListProvider>
              <CertificateSettingsViewWrapper />
            </SettingsListProvider>
          </CertificateSettingsTabWrapper>
        </TabsWrapper>
      </div>
    </>
  )
}

export default CertificateSettings
