import React from 'react'

import IncomesCertificatesHeader from '../header/IncomesCertificatesHeader'

const IncomesCertificateWrapper = ({children}) => {
  return (
    <main className="ed-grid rows-gap">
      <IncomesCertificatesHeader />
      {children}
    </main>
  )
}

export default IncomesCertificateWrapper
