import classNames from 'classnames'
import React, {useEffect, useRef} from 'react'

import useWindowSize from '../../../../hooks/useWindowSize'

const CertificateIframe = ({content, className}) => {
  const iframeRef = useRef(null)
  const containerRef = useRef(null)
  const {width} = useWindowSize()

  const adjustIframeHeight = () => {
    if (iframeRef.current) {
      try {
        const containerWidth = containerRef.current.offsetWidth

        // Obtén las dimensiones reales del contenido del iframe
        const iframeContentWidth = iframeRef.current.contentWindow.document.body.scrollWidth
        const iframeContentHeight = iframeRef.current.contentWindow.document.body.scrollHeight

        // Calcula el factor de escala basado solo en el ancho
        const scale = containerWidth / iframeContentWidth

        // Ajusta dinámicamente el ancho y alto del iframe manteniendo proporciones
        iframeRef.current.style.transform = `scale(${scale})`
        iframeRef.current.style.transformOrigin = 'top left'

        // Ajusta la altura proporcionalmente al factor de escala
        iframeRef.current.style.height = `${iframeContentHeight}px`
        containerRef.current.style.height = `${iframeContentHeight * scale}px`
      } catch (error) {
        console.error('Error accessing iframe content:', error)
      }
    }
  }

  useEffect(() => {
    const iframe = iframeRef.current

    if (iframe) {
      iframe.addEventListener('load', adjustIframeHeight)

      // Dynamically update the iframe content
      const doc = iframe.contentWindow.document
      doc.open()
      doc.write(content)
      doc.close()
      doc.body.style.overflow = 'hidden'

      adjustIframeHeight()

      return () => {
        iframe.removeEventListener('load', adjustIframeHeight)
      }
    }
  }, [content]) // Recalculate height whenever content changes

  // recalculate iframe height when viewport width changes
  useEffect(() => {
    adjustIframeHeight()
  }, [width])

  return (
    <div ref={containerRef}>
      <iframe ref={iframeRef} style={{width: '1000px', border: 'none'}} className={classNames(className)} />
    </div>
  )
}

export default CertificateIframe
