import {useState} from 'react'
import {useDispatch} from 'react-redux'

import {getEmployeePDFCertificateByContractId} from '../../../services/dianForm220'
import {notifyError} from '../../../services/notification'
import {useDownloadLinkReportContext} from '../../employees-panel/providers/DownloadLinkReport'
import {useCertificateSettingContext} from '../providers/CertificateSetting'

const useDownloadCertificatePDF = certificateId => {
  const {
    certificateSetting: {year}
  } = useCertificateSettingContext()
  const dispatch = useDispatch()
  const {setFileNameDownloaded, setObject, downloadLinkReport} = useDownloadLinkReportContext()

  const [isLoading, setIsLoading] = useState(false)

  const handleDownload = () => {
    setIsLoading(true)

    const payload = {
      year,
      contract_ids: [certificateId],
      format_file: 'pdf'
    }

    getEmployeePDFCertificateByContractId(
      payload,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')

        setIsLoading(false)
      },
      () => {
        setIsLoading(false)

        dispatch(notifyError('Ocurrio un error al descargar el certificado'))
      }
    )
  }

  return {isLoading, handleDownload}
}

export default useDownloadCertificatePDF
