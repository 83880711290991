import React, {useContext} from 'react'

import {PayrollContext} from '../../../../contexts/contexts'
import {getNoveltiesFallbackData} from '../../../../helpers/novelties.jsx'
import {CREATE, STATUS_PRE_PAYROLL} from '../../../../helpers/payroll'
import {isEqual} from '../../../../helpers/utils'
import {CATEGORIES_CONFIG} from '../../../../modules/payroll-process-novelties/constants/categories'
import {useNoveltiesCategoriesContext} from '../../../../modules/payroll-process-novelties/providers/NoveltiesCategories'
import {useFormActionContext} from '../../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper.jsx'
import NoveltyImage from '../../../../static/images/stories/absence-nolvety.svg'
import NoveltyEmptyState from '../../../Organisms/Emptys/NoveltyEmptyState'

const NoveltyTableContentFallback = ({isError}) => {
  const {activeProcess} = useContext(PayrollContext)
  const {activeCategory} = useNoveltiesCategoriesContext()
  const {setFormAction} = useFormActionContext()

  const isOpen = isEqual(activeProcess?.status, STATUS_PRE_PAYROLL)
  const {name} = CATEGORIES_CONFIG[activeCategory]

  const handleAddNovelty = () => {
    setFormAction({
      type: CREATE,
      noveltyID: 0
    })
  }

  const {description, title} = getNoveltiesFallbackData(isOpen, name, handleAddNovelty, isError)

  return <NoveltyEmptyState title={title} description={description} image={NoveltyImage} />
}

export default NoveltyTableContentFallback
