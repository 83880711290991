import React from 'react'

import NoveltyTableHeader from '../components/table/NoveltyTableHeader'

const useGetNoveltyTableHeaderList = list => {
  const headerElements = list.map(item => {
    if (!item.isSortColumn) return <p key={item.key}>{item.label}</p>

    return <NoveltyTableHeader key={item.key} header={item} />
  })

  return headerElements
}

export default useGetNoveltyTableHeaderList
