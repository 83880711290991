import classNames from 'classnames'
import React from 'react'

import ActionButton from '../../../../components/Atoms/Templates/ActionButton'
import useDownloadCertificatePDF from '../../hooks/useDownloadCertificatePDF'

const DownloadCertificateButton = ({id}) => {
  const {isLoading, handleDownload} = useDownloadCertificatePDF(id)

  return (
    <ActionButton
      icon="download"
      content={isLoading ? 'Descargando...' : 'Descargar'}
      className={classNames({grayscale: isLoading}, 's-w-fit s-ml-auto s-mb-2')}
      isDisabled={isLoading}
      onClick={handleDownload}
    />
  )
}

export default DownloadCertificateButton
