import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {readBackendDate} from '../../../helpers/dates'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {fullName} from '../../../helpers/string-format'
import {isAnyEqual, isEmpty, isEqual, isNull} from '../../../helpers/utils'
import {ABSENCE_NOVELTY_FORM_INITIAL_STATE} from '../../../modules/payroll-process-novelties/constants/form'
import NoveltiesFiltersProvider from '../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useFormActionContext} from '../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {getAbsenceNoveltyByID} from '../../../services/absenceNovelties'
import AbsenceNoveltyTable from '../../Molecules/Tables/AbsenceNoveltyTable'
import NoveltyTableWrapper from '../Containers/NoveltyTableWrapper'
import AbsenceNoveltyForm from '../Forms/AbsenceNoveltyForm'

const AbsenceNovelty = () => {
  const employerSession = useSelector(employerSelectors.getSession)
  const {formAction} = useFormActionContext()

  const [isOpen, setIsOpen] = useState(false)
  const [dataForm, setDataForm] = useState(ABSENCE_NOVELTY_FORM_INITIAL_STATE)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const absenceTypes = !isNull(cacheGeneral) && cacheGeneral.data?.absence_types

  const handleIsDiscountAbsence = absenceTypeID => {
    const result = absenceTypes.filter(
      absenceType => isEqual(absenceType.id, Number(absenceTypeID)) && absenceType.is_discount
    )

    return !isEmpty(result)
  }

  useEffect(() => {
    setIsOpen(false)

    if (isNull(formAction.type)) {
      setIsOpen(false)
      return
    }

    if (isEqual(formAction.type, CREATE)) {
      setDataForm(ABSENCE_NOVELTY_FORM_INITIAL_STATE)
      setIsOpen(true)
      return
    }

    if (isAnyEqual(formAction.type, [EDIT, SHOW])) {
      getAbsenceNoveltyByID(
        formAction.noveltyID,
        response => {
          setDataForm({
            id: response.id,
            contract_id: response.contract_id,
            employee_description: fullName(response),
            absence_type_id: response.absence_type_id,
            concept_id: response.concept_id,
            concept_description: response.concept_description,
            begins_at: readBackendDate(response.begins_at),
            ends_at: readBackendDate(response.ends_at),
            code: response.code,
            note: response.note,
            has_discount_rest_day: response.has_discount_rest_day,
            can_pause_contract: response.can_pause_contract,
            status: response.status,
            absence_per_periods: response.absence_per_periods,
            is_discount: handleIsDiscountAbsence(response.absence_type_id),
            edition_type: response.edition_type
          })
          setIsOpen(true)
        },
        error => {
          console.error(error)
          setIsOpen(false)
        }
      )
    }
  }, [formAction]) // eslint-disable-line

  return (
    <NoveltiesFiltersProvider>
      <NoveltyTableWrapper
        isOpenForm={isOpen}
        form={<AbsenceNoveltyForm initialState={dataForm} />}
        table={<AbsenceNoveltyTable />}
      />
    </NoveltiesFiltersProvider>
  )
}

export default AbsenceNovelty
