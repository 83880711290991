import {PropTypes} from 'prop-types'
import React, {createContext, useContext, useState} from 'react'

import {NOVELTY_PROCCESS_STRATEGY} from '../constants/novelties'

const StrategyFilterContext = createContext({})

const StrategyFilterProvider = ({children}) => {
  const [currentStrategyFilter, setCurrentStrategyFilter] = useState(NOVELTY_PROCCESS_STRATEGY)

  return (
    <StrategyFilterContext.Provider
      value={{
        currentStrategyFilter,
        setCurrentStrategyFilter
      }}
    >
      {children}
    </StrategyFilterContext.Provider>
  )
}

export const useStrategyFilterContext = () => useContext(StrategyFilterContext)

StrategyFilterProvider.propTypes = {
  children: PropTypes.node
}

StrategyFilterProvider.defaultProps = {
  children: null
}

export default StrategyFilterProvider
