import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {getSettingsByCertificateYear} from '../../../services/certificate'
import {useCertificateSettingContext} from '../providers/CertificateSetting'

const useGetCertificateSettingByYear = () => {
  const {certificateSetting, setCertificateSetting} = useCertificateSettingContext()
  const {month: date} = useParams()

  const currentYear = date.split('-')[0]

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState()

  const fetchYearSetting = () => {
    getSettingsByCertificateYear(
      currentYear,
      response => {
        setCertificateSetting(response)
        setIsLoading(false)
      },
      response => {
        setIsLoading(false)
        setIsError(true)
        setError(response)
      }
    )
  }

  useEffect(() => {
    fetchYearSetting()
  }, [])

  return {isLoading, isError, data: certificateSetting, error}
}

export default useGetCertificateSettingByYear
