import React from 'react'

import EmployeeWrapper from '../../../../components/Wrappers/EmployeeWrapper'
import PhonePanel from '../../../../components/Wrappers/PhonePanel'
import IncomesCertificatePanel from './IncomesCertificatePanel'
import IncomesCertificatesEmployeesFilter from './IncomesCertificatesEmployeesFilter'

const IncomesCertificatesListLayout = () => {
  return (
    <PhonePanel
      container={{
        className: 'ed-grid m-grid-2 lg-grid-3 s-gap-0 m-gap-2 lg-gap-4'
      }}
    >
      <EmployeeWrapper>
        <div className="ed-grid rows-gap s-gap-2 m-gap-4">
          <IncomesCertificatesEmployeesFilter />
        </div>
        <IncomesCertificatePanel />
      </EmployeeWrapper>
    </PhonePanel>
  )
}

export default IncomesCertificatesListLayout
