import React from 'react'
import Skeleton from 'react-loading-skeleton'

import SkeletonStyles from '../../../../styles/modules/icomes-certificate/skeleton.module.scss'

const IncomesCertificateSkeleton = () => (
  <div className="ed-grid rows-gap">
    <Skeleton height={120} className={SkeletonStyles.root} />
    <Skeleton height={1000} className={SkeletonStyles.root} />
  </div>
)

export default IncomesCertificateSkeleton
