import PropTypes from 'prop-types'
import React, {useRef} from 'react'

import {getConceptTypeByConceptCode} from '../../../../helpers/concepts'
import {shortFormatDate} from '../../../../helpers/dates'
import {STATUS_HISTORY} from '../../../../helpers/payroll'
import {isEqual} from '../../../../helpers/utils'
import {INITAL_STATE_FORM_ACTION} from '../../../../modules/payroll-process-novelties/constants/form'
import {useFormActionContext} from '../../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import Icon from '../../../Atoms/Icon'
import Tooltip from '../../../Atoms/Templates/Tooltip'
import TooltipContainer from '../../../Organisms/Containers/TooltipContainer'
import DeleteModal from '../../../Organisms/Modals/DeleteModal'
import TableRow from '../../../Wrappers/TableRow'
import Concept from '../../Templates/Concept'
import Employee from '../../Templates/Employee'
import NoveltyRowAction from './NoveltyRowAction'

const AbsenceNoveltyRow = ({novelty, onDelete}) => {
  const deleteModal = useRef()
  const {setFormAction} = useFormActionContext()

  const handleOnClick = () => {
    onDelete(novelty.id)
    setFormAction(INITAL_STATE_FORM_ACTION)
    deleteModal.current.closeModal()
  }

  return (
    <>
      <TableRow>
        <Employee employee={novelty} />
        <div className="flex">
          <div className="s-mr-1 s-cross-center">
            <Concept
              concept={{concept_type_description: getConceptTypeByConceptCode(novelty.concept_code), ...novelty}}
            />
          </div>
          {novelty.has_discount_rest_day && (
            <TooltipContainer className="s-cross-center">
              <Icon svg="information" color="var(--blue-accent)" className="s-mr-4px cursor-pointer" />
              <Tooltip>
                <div className="whitespace-nowrap">
                  <span>Descuento dominical</span>
                  <p className="s-mb-0 smaller s-color-lighter-text">Día {shortFormatDate(novelty.date_rest_day)}</p>
                </div>
              </Tooltip>
            </TooltipContainer>
          )}
        </div>
        <p className="s-mb-0">
          {shortFormatDate(novelty.per_period_is_rest_day ? novelty.date_rest_day : novelty.begins_at)}
        </p>
        <p className="s-mb-0">
          {shortFormatDate(novelty.per_period_is_rest_day ? novelty.date_rest_day : novelty.ends_at)}
        </p>
        <p className="s-mb-0 m-right">{novelty.per_period_is_rest_day ? novelty.per_period_days : novelty.days}</p>
        <NoveltyRowAction novelty={novelty} deleteModalRef={deleteModal} />
      </TableRow>
      <DeleteModal ref={deleteModal} deleteFunction={handleOnClick} />
    </>
  )
}

AbsenceNoveltyRow.propTypes = {
  novelty: PropTypes.shape({}).isRequired
}

AbsenceNoveltyRow.defaultProps = {
  novelty: PropTypes.shape({})
}

export default AbsenceNoveltyRow
