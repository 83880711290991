import React, {useContext, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {isZeroDate, readBackendDate, shortFormatDate, stringFormatDate, writeBackendDate} from '../../../helpers/dates'
import {ORIGIN_APP_NOMINEROS} from '../../../helpers/origin'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {isAnyEqual, isEmpty, isEqual, isNull, len} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import {
  INITAL_STATE_FORM_ACTION,
  LEAVE_NOVELTY_FORM_FIELDS,
  LEAVE_NOVELTY_FORM_RULES
} from '../../../modules/payroll-process-novelties/constants/form'
import {NOVELTY_EDITION_TYPES} from '../../../modules/payroll-process-novelties/constants/novelties'
import {getPartialMinimumEndDate} from '../../../modules/payroll-process-novelties/helpers/form'
import {useNoveltiesFiltersContext} from '../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useFormActionContext} from '../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {createLeaveNovelty, updateLeaveNovelty} from '../../../services/leaveNovelty'
import {GetBeforeByContractID} from '../../../services/leaves'
import {notifyError, notifySuccessful} from '../../../services/notification'
import RadioButton from '../../Atoms/Forms/RadioButton'
import Select from '../../Atoms/Forms/Select'
import TextArea from '../../Atoms/Forms/TextArea'
import FormInput from '../../Atoms/Templates/FormInput'
import PerPeriodItem from '../../Molecules/Items/PerPeriodItem'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import SelectEmployeeInput from '../../Molecules/Selects/SelectEmployeeInput'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'
import NoveltyFormContainer from '../Containers/NoveltyFormContainer'

const LeaveNoveltyForm = ({initialState, handleHasMedicalLeave}) => {
  const dispatch = useDispatch()
  const employerSession = useSelector(employerSelectors.getSession)
  const {formAction, setFormAction} = useFormActionContext()
  const {handleReload} = useNoveltiesFiltersContext()

  const isDisabled = isEqual(formAction.type, SHOW)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const leaveTypes = !isNull(cacheGeneral) && cacheGeneral.data?.leave_types
  const {activeProcess} = useContext(PayrollContext)
  const [hasMedicaLeave, setHasMedicalLeave] = useState(initialState.has_medical_leave)
  const [formData, setFormData] = useState(initialState)
  const [beforeLeaves, setBeforeLeaves] = useState([])

  const handleGetDataBeforeLeave = (isExtends, contractID, leaveTypeID, date) => {
    if (!isExtends || isEqual(leaveTypeID, 0) || isEqual(contractID, 0) || isZeroDate(date)) {
      setBeforeLeaves([])
      return
    }

    GetBeforeByContractID(contractID, stringFormatDate(date), leaveTypeID, response => {
      setBeforeLeaves(response)
    })
  }

  const handleOnSelectEmployeeInput = contractID => {
    setFormData(state => ({
      ...state,
      contract_id: Number(contractID)
    }))

    handleGetDataBeforeLeave(formData.is_extends, contractID, formData.leave_type_id, formData.begins_at)
  }

  const handleOnChangeLeaveType = e => {
    const value = e.target.value

    setFormData(state => ({
      ...state,
      leave_type_id: Number(value)
    }))

    const isMedicalLeave = handleHasMedicalLeave(value)
    setHasMedicalLeave(isMedicalLeave)

    if (!isMedicalLeave) {
      setFormData(state => ({
        ...state,
        medical_diagnosis: '',
        is_extends: false,
        extends_leave_id: 0
      }))
    }

    handleGetDataBeforeLeave(formData.is_extends, formData.contract_id, Number(value), formData.begins_at)
  }

  const handleOnChangeInputText = e => {
    const value = e.target.value
    const name = e.target.name

    setFormData(state => ({
      ...state,
      [name]: value
    }))
  }

  const handleOnChangeBeginsAt = date => {
    setFormData(state => ({
      ...state,
      begins_at: date
    }))

    handleGetDataBeforeLeave(formData.is_extends, formData.contract_id, formData.leave_type_id, date)
  }

  const handleOnChangeEndsAt = date => {
    setFormData(state => ({
      ...state,
      ends_at: date
    }))
  }

  const handleOnChangeIsExtends = e => {
    const value = e.target.value
    setFormData(state => ({
      ...state,
      is_extends: isEqual(value, 'true'),
      extends_leave_id: formData.is_extends || 0
    }))

    handleGetDataBeforeLeave(isEqual(value, 'true'), formData.contract_id, formData.leave_type_id, formData.begins_at)
  }

  const handleCancelForm = () => setFormAction(INITAL_STATE_FORM_ACTION)

  const handleSuccessForm = () => {
    if (isEqual(formAction.type, CREATE)) handleCreate()
    if (isEqual(formAction.type, EDIT)) handleEdit()
  }

  const handleValidate = () => {
    const payload = {
      contract_id: Number(formData.contract_id),
      leave_type_id: Number(formData.leave_type_id),
      begins_at: writeBackendDate(formData.begins_at),
      ends_at: writeBackendDate(formData.ends_at),
      is_extends: formData.is_extends,
      extends_leave_id: Number(formData.extends_leave_id),
      medical_diagnosis: formData.medical_diagnosis,
      support_code: formData.support_code,
      description: formData.description,
      process_hash: activeProcess.hash,
      origin: ORIGIN_APP_NOMINEROS
    }

    const validator = new ValidatorForm(payload, LEAVE_NOVELTY_FORM_RULES)
    validator.setAttributeNames(LEAVE_NOVELTY_FORM_FIELDS)

    if (validator.fails()) {
      throw validator.errors()
    }

    if (payload.is_extends && isEqual(payload.extends_leave_id, 0)) {
      // eslint-disable-next-line
      throw `Cuando indicas que la incapacidad tiene prorroga, debes seleccionar la incapacidad a prorrogar`
    }

    return payload
  }

  const handleCreate = () => {
    try {
      const payload = handleValidate()
      createLeaveNovelty(payload, respose => {
        setFormAction(INITAL_STATE_FORM_ACTION)
        handleReload()
        dispatch(notifySuccessful('Hemos creado la incapacidad exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const handleEdit = () => {
    try {
      const payload = handleValidate()
      updateLeaveNovelty(formData.id, payload, response => {
        setFormAction(INITAL_STATE_FORM_ACTION)
        handleReload()
        dispatch(notifySuccessful('Hemos Actualizado la novedad exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const successButtonText = isEqual(formAction.type, CREATE)
    ? 'Agregar Incapacidad'
    : isEqual(formAction.type, EDIT)
      ? 'Editar Incapacidad'
      : 'Guardar'

  const {edition_type} = formData
  const isPermissionsPartial = isEqual(edition_type, NOVELTY_EDITION_TYPES.PARTIAL)
  const isPermissionsFull = isEqual(edition_type, NOVELTY_EDITION_TYPES.FULL)
  const partialMinimumEndDate = getPartialMinimumEndDate(isPermissionsPartial, initialState.leave_per_periods)

  return (
    <NoveltyFormContainer
      cancelFunction={handleCancelForm}
      successFunction={handleSuccessForm}
      successButtonText={successButtonText}
      hasEditPermissions={isPermissionsFull || isPermissionsPartial}
    >
      <SelectEmployeeInput
        isRequired
        isDisabled={isAnyEqual(formAction.type, [EDIT, SHOW]) || isPermissionsFull || isPermissionsPartial}
        name="employee_id"
        id="employee_id"
        label="Empleado"
        placeholder="Busca por Identificación o nombre"
        onSelect={handleOnSelectEmployeeInput}
        initialValue={formData.employee_description}
      />

      <Select
        isDisabled={isDisabled || isPermissionsPartial}
        isRequired
        id="leave_type"
        name="leave_type"
        label="Tipo"
        placeholder
        onChange={handleOnChangeLeaveType}
        value={formData.leave_type_id}
      >
        {Array.isArray(leaveTypes) &&
          leaveTypes.map(leaveType => (
            <option key={leaveType.id} value={leaveType.id}>
              {leaveType.description}
            </option>
          ))}
      </Select>

      <FormInput
        isDisabled={isDisabled}
        id="support_code"
        name="support_code"
        value={formData.support_code}
        onChange={handleOnChangeInputText}
        label="Código soporte administradora"
        placeholder="000000"
      />

      <DatePickerInput
        disabled={isDisabled || isPermissionsPartial}
        isRequired
        label="Inicio"
        id="begins_at"
        name="begins_at"
        value={formData.begins_at}
        onChange={handleOnChangeBeginsAt}
      />

      <DatePickerInput
        disabled={isDisabled}
        isRequired
        label="Finalización"
        id="ends_at"
        name="ends_at"
        openToDate={isEqual(formData.ends_at, '') ? formData.begins_at : formData.ends_at}
        minDate={partialMinimumEndDate || formData.begins_at}
        value={formData.ends_at}
        onChange={handleOnChangeEndsAt}
      />

      <div className="s-order-1 m-order-initial m-rows-3 lg-rows-2">
        <TextArea
          isDisabled={isDisabled}
          className="s-h-full"
          id="description"
          name="description"
          value={formData.description}
          onChange={handleOnChangeInputText}
          label="Notas u observaciones"
          textarea={{
            className: 's-h-full'
          }}
        />
      </div>
      {hasMedicaLeave && (
        <FormInput
          isDisabled={isDisabled}
          id="medical_diagnosis"
          name="medical_diagnosis"
          label="Diagnóstico médico CIE10"
          value={formData.medical_diagnosis}
          onChange={handleOnChangeInputText}
        />
      )}

      {hasMedicaLeave && (
        <div className="flex nowrap s-flex-gap-24px">
          <RadioButtonsWrapper label="¿Es prórroga?">
            <RadioButton
              disabled={isDisabled}
              name="is_extends"
              label="Sí"
              value={true}
              onChange={handleOnChangeIsExtends}
              checked={isEqual(formData.is_extends, true)}
            />
            <RadioButton
              disabled={isDisabled}
              name="is_extends"
              label="No"
              value={false}
              onChange={handleOnChangeIsExtends}
              checked={isEqual(formData.is_extends, false)}
            />
          </RadioButtonsWrapper>

          {formData.is_extends && (
            <Select
              isDisabled={isDisabled}
              className="flex-grow"
              id="extends_leave_id"
              name="extends_leave_id"
              label="Incapacidad a prorrogar"
              placeholder={isEmpty(beforeLeaves) ? 'No hay registros anteriores' : 'Selecciona un registro'}
              value={formData.extends_leave_id}
              onChange={handleOnChangeInputText}
            >
              {Array.isArray(beforeLeaves) &&
                beforeLeaves.map(beforeLeave => (
                  <option key={beforeLeave.id} value={beforeLeave.id}>
                    {`${shortFormatDate(beforeLeave.begins_at)} - ${shortFormatDate(beforeLeave.ends_at)}`}
                  </option>
                ))}
            </Select>
          )}
        </div>
      )}

      {Array.isArray(initialState.leave_per_periods) && len(initialState.leave_per_periods) > 1 && (
        <div className="m-cols-3 ed-grid rows-gap s-gap-1">
          <h4>Distribución de la novedad por periodos</h4>
          <div className="ed-grid s-grid-1 m-grid-3 rows-gap">
            {initialState.leave_per_periods.map(novelty => (
              <PerPeriodItem key={novelty.id} perPeriodNovelty={novelty} />
            ))}
          </div>
        </div>
      )}
    </NoveltyFormContainer>
  )
}

export default LeaveNoveltyForm
