import {useState} from 'react'
import {useDispatch} from 'react-redux'

import {getSettingsReportByYear} from '../../../services/certificate'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {useDownloadLinkReportContext} from '../../employees-panel/providers/DownloadLinkReport'
import {useCurrentSettingContext} from '../providers/CurrentSetting'

const useHandleDownloadSettingReport = () => {
  const [isLoading, setIsLoading] = useState(false)
  const {setFileNameDownloaded, setObject, downloadLinkReport} = useDownloadLinkReportContext()
  const dispatch = useDispatch()
  const {
    currentSetting: {year}
  } = useCurrentSettingContext()

  const downloadSettingReport = () => {
    setIsLoading(true)

    getSettingsReportByYear(
      year,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setIsLoading(false)
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsLoading(false)
      }
    )
  }

  return {
    isLoadingDownloadReport: isLoading,
    downloadSettingReport
  }
}

export default useHandleDownloadSettingReport
