import React, {createContext, useContext, useRef, useState} from 'react'

import CloseCertificateModal from '../../components/modals/CloseCertificateModal'

const CloseCertificatesContext = createContext({})

const CloseCertificatesProvider = ({children}) => {
  const CloseCertificatesModalRef = useRef()
  const [isCloseCertificatesLoading, setIsCloseCertificatesLoading] = useState(false)

  return (
    <CloseCertificatesContext.Provider
      value={{
        CloseCertificatesModalRef,
        isCloseCertificatesLoading,
        setIsCloseCertificatesLoading
      }}
    >
      <>
        {children}
        <CloseCertificateModal modalRef={CloseCertificatesModalRef} />
      </>
    </CloseCertificatesContext.Provider>
  )
}

export const useCloseCertificatesContext = () => useContext(CloseCertificatesContext)

export default CloseCertificatesProvider
