import routes from '../../../config/routes'

export const SETTINGS_OPTIONS = [
  {
    id: 1,
    name: 'Empresa',
    description: 'Configura los datos de tu empresa',
    icon: 'building',
    tags: ['bancos', 'usuarios', 'sucursales', 'oficinas', 'cargos'],
    route: routes.settings.employer
  },
  // {
  //   id: 2,
  //   name: 'Estructura organizacional',
  //   description: 'Configura tu estructura organizacional',
  //   icon: 'rectangle-group',
  //   tags: ['areas'],
  //   route: routes.settings.organizationalStructure,
  // },
  {
    id: 3,
    name: 'Parametros de Nómina',
    description: 'Configura los parámetros para calcular la nómina',
    icon: 'clipboard-copy',
    tags: ['parametros', 'variables'],
    route: routes.settings.parameters
  },
  {
    id: 4,
    name: 'Conceptos de Nómina',
    description: 'Configura tus conceptos de nómina',
    icon: 'bolt',
    tags: ['conceptos', 'items', 'prioridad', 'nomina'],
    route: routes.settings.concepts
  },
  // {
  //   id: 5,
  //   name: 'Procesos de Cálculo',
  //   description: 'Configura los procesos de cálculo',
  //   icon: 'calculator',
  //   tags: ['procesos', 'nomina'],
  //   route: routes.settings.processes,
  // },
  // {
  //   id: 6,
  //   name: 'Bases de Nómina',
  //   description: 'Configura las bases usadas para el cálculo de nómina',
  //   icon: 'clipboard-list',
  //   tags: ['bases', 'nomina'],
  //   route: routes.settings.bases,
  // },
  // {
  //   id: 7,
  //   name: 'Contabilidad',
  //   description: 'Configura tu interfaz contable',
  //   icon: 'clipboard-check',
  //   tags: ['contabilidad', 'cuentas', 'interfaz'],
  //   route: routes.settings.accounting,
  // },
  {
    id: 8,
    name: 'Nómina electronica',
    description: 'Habilita Nómina electronica con la DIAN',
    icon: 'rocket',
    tags: ['nomina', 'electronica'],
    route: routes.settings.payrollElectronic
  }
  // {
  //   id: 9,
  //   name: 'Cert. Ingresos y Retenciones',
  //   description: 'Configura tus cert. de ingresos y retenciones DIAN',
  //   icon: 'document',
  //   tags: ['certificados', 'ingresos', 'retenciones'],
  //   route: routes.settings.incomesWithholdingsCertificates
  // }
]
