import Proptypes from 'prop-types'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'

import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {capitalize} from '../../../helpers/string-format'
import {isNull} from '../../../helpers/utils'
import NoveltyTableAddButtons from '../../../modules/payroll-process-novelties/components/NoveltyTableAddButtons'
import useDownloadNoveltyReport from '../../../modules/payroll-process-novelties/hooks/useDownloadNoveltyReport'
import {useNoveltiesFiltersContext} from '../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useStrategyFilterContext} from '../../../modules/payroll-process-novelties/providers/StrategyFilter'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {generateVacationNoveltiesReport} from '../../../services/vacationNovelty'
import Select from '../../Atoms/Forms/Select'
import ActionButton from '../../Atoms/Templates/ActionButton'
import SelectEmployeeInput from '../Selects/SelectEmployeeInput'
import VacationNoveltyTableContent from './content/VacationNoveltyTableContent'

const VacationNoveltyTable = () => {
  const employerSession = useSelector(employerSelectors.getSession)
  const {setFilters} = useNoveltiesFiltersContext()
  const {currentStrategyFilter} = useStrategyFilterContext()

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const vacationTypes = !isNull(cacheGeneral) && cacheGeneral.data?.vacation_types
  vacationTypes.unshift({id: 0, description: 'TODOS'})

  const [vacationTypeID, setVacationTypeID] = useState(0)

  const handleOnSelectEmployeeInput = contractID => {
    setFilters(prev => {
      if (contractID === 0) {
        const {contract_id, ...rest} = prev
        return rest
      }

      return {...prev, contract_id: contractID, page: 1}
    })
  }

  const handleOnChangeVacationType = e => {
    const vacationTypeID = e.target.value
    setVacationTypeID(vacationTypeID)
    setFilters(prev => {
      return {...prev, vacation_type_id: vacationTypeID, page: 1}
    })
  }

  const {handleGenerateReport, isLoadingDownloadReport} = useDownloadNoveltyReport(generateVacationNoveltiesReport)

  return (
    <>
      <div
        key={currentStrategyFilter}
        className="m-cross-end s-flex-gap-12px flex-grow m-main-end s-column m-row s-px-2 s-pb-2 m-px-4 m-pb-4"
      >
        <SelectEmployeeInput
          className="small"
          name="employee_id"
          id="employee_id"
          label="Empleado"
          placeholder="Busca por Identificación o nombre"
          onSelect={handleOnSelectEmployeeInput}
        />

        <Select
          className="small"
          id="vacation_type"
          name="vacation_type"
          label="Tipo"
          placeholder
          onChange={handleOnChangeVacationType}
          value={vacationTypeID}
        >
          {Array.isArray(vacationTypes) &&
            vacationTypes.map(vacationType => (
              <option key={vacationType.id} value={vacationType.id}>
                {capitalize(vacationType.description)}
              </option>
            ))}
        </Select>

        <ActionButton
          icon="download"
          onClick={handleGenerateReport}
          className="s-border-border"
          isDisabled={isLoadingDownloadReport}
        />
        <NoveltyTableAddButtons />
      </div>

      <VacationNoveltyTableContent />
    </>
  )
}

VacationNoveltyTable.propTypes = {
  novelties: Proptypes.array.isRequired
}

VacationNoveltyTable.defaultProps = {
  novelties: []
}

export default VacationNoveltyTable
