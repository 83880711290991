import classNames from 'classnames'
import React, {useContext, useState} from 'react'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {getConceptsNoveltyByProcessType} from '../../../helpers/concepts'
import {shortFormatDate} from '../../../helpers/dates'
import {ORIGIN_APP_NOMINEROS} from '../../../helpers/origin'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {isAnyEqual, isEqual} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import {
  INITAL_STATE_FORM_ACTION,
  OCCASIONAL_NOVELTY_FORM_FIELDS,
  OCCASIONAL_NOVELTY_FORM_RULES
} from '../../../modules/payroll-process-novelties/constants/form'
import {
  NOVELTY_DATES_STRATEGY,
  NOVELTY_EDITION_TYPES
} from '../../../modules/payroll-process-novelties/constants/novelties'
import {getSuccessButtonText} from '../../../modules/payroll-process-novelties/helpers/form'
import {useNoveltiesFiltersContext} from '../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useFormActionContext} from '../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import {useStrategyFilterContext} from '../../../modules/payroll-process-novelties/providers/StrategyFilter'
import {selectors as employerConceptSelectors} from '../../../redux/ducks/concepts'
import {selectors as configProcessTypeSelectors} from '../../../redux/ducks/processTypeConfig'
import {notifyError, notifySuccessful} from '../../../services/notification'
import {createOccasionalNovelty, updateOccasionalNovelty} from '../../../services/occasionalnovelty'
import Select from '../../Atoms/Forms/Select'
import TextArea from '../../Atoms/Forms/TextArea'
import FormInput from '../../Atoms/Templates/FormInput'
import SelectConceptInput from '../../Molecules/Selects/SelectConceptInput'
import SelectEmployeeInput from '../../Molecules/Selects/SelectEmployeeInput'
import NoveltyFormContainer from '../Containers/NoveltyFormContainer'

const OccasionalNoveltyForm = ({initialState, periods}) => {
  const {activeProcess} = useContext(PayrollContext)
  const noveltyConcepts = useSelector(employerConceptSelectors.getNoveltyConcepts)
  const ConceptByProcessType = useSelector(employerConceptSelectors.getProcessConcepts)
  const configObj = useSelector(configProcessTypeSelectors.getObj)
  const {formAction, setFormAction} = useFormActionContext()
  const dispatch = useDispatch()
  const {handleReload} = useNoveltiesFiltersContext()
  const {currentStrategyFilter} = useStrategyFilterContext()

  const configProcess = configObj[activeProcess?.process_type_code]
  const concepts = getConceptsNoveltyByProcessType(noveltyConcepts, ConceptByProcessType, activeProcess.process_type_id)
  const isDisabled = isEqual(formAction.type, SHOW)
  const isFilteredByDates = isEqual(currentStrategyFilter, NOVELTY_DATES_STRATEGY)
  const [formData, setFormData] = useState(initialState)

  const handleChangeValue = e => {
    const valueField = e.target.value
    const pattern = /^[0-9]+\.?(\d{1,2})?$/

    if (isEqual(valueField, '') || pattern.test(valueField)) {
      setFormData(state => ({
        ...state,
        value: valueField,
        // reset state quantity
        quantity: 0
      }))
    }
  }

  const handleChageQuantity = e => {
    const quantityField = e.target.value
    const pattern = /^[0-9]{1,3}(\.(\d{1,2})?)?$/

    if (isEqual(quantityField, '') || pattern.test(quantityField)) {
      setFormData(state => ({
        ...state,
        quantity: quantityField,
        // reset state value
        value: 0
      }))
    }
  }

  const handleOnChangeInputText = e => {
    const value = e.target.value
    const name = e.target.name

    setFormData(state => ({
      ...state,
      [name]: value
    }))
  }

  const handleOnSelectConceptInput = conceptID => {
    setFormData(state => ({
      ...state,
      concept_id: Number(conceptID)
    }))
  }

  const handleOnSelectEmployeeInput = contractID => {
    setFormData(state => ({
      ...state,
      contract_id: Number(contractID)
    }))
  }

  const handleOnChangePayPeriod = e => {
    const value = e.target.value

    setFormData(state => ({
      ...state,
      pay_period_id: Number(value)
    }))
  }

  const handleCancelForm = () => {
    setFormAction(INITAL_STATE_FORM_ACTION)
  }

  const handleSuccessForm = () => {
    if (isEqual(formAction.type, CREATE)) handleCreate()
    if (isEqual(formAction.type, EDIT)) handleEdit()
  }

  const handleValidate = () => {
    const payload = {
      contract_id: formData.contract_id,
      concept_id: formData.concept_id,
      pay_period_id: Number(formData.pay_period_id),
      quantity: Number(formData.quantity),
      value: Number(formData.value),
      notes: formData.notes,
      origin: ORIGIN_APP_NOMINEROS
    }

    if (!isFilteredByDates) {
      payload.pay_period_id = Number(activeProcess.pay_period_id)

      // if the process has an application pay period id, we create the novelty with this period id
      if (!isEqual(activeProcess.application_pay_period_id, 0)) {
        payload.pay_period_id = activeProcess.application_pay_period_id
      }

      // always add the process_id if the process type has marked "has_novelty_by_process"
      if (configProcess?.config?.rules?.has_novelty_by_process) {
        payload.process_id = activeProcess.id
      }
    }

    const validator = new ValidatorForm(payload, OCCASIONAL_NOVELTY_FORM_RULES)
    validator.setAttributeNames(OCCASIONAL_NOVELTY_FORM_FIELDS)

    if (validator.fails()) {
      throw validator.errors()
    }

    if (
      (isEqual(payload.value, 0) && isEqual(payload.quantity, 0)) ||
      (!isEqual(payload.value, 0) && !isEqual(payload.quantity, 0))
    ) {
      // eslint-disable-next-line
      throw `Los campos ${formFields.quantity} y ${formFields.value}, no pueden ser cero simultaneamente`
    }

    return payload
  }

  const handleCreate = () => {
    try {
      const payload = handleValidate()
      createOccasionalNovelty(payload, () => {
        setFormAction(INITAL_STATE_FORM_ACTION)
        handleReload()
        dispatch(notifySuccessful('Hemos creado la novedad exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const handleEdit = () => {
    try {
      const payload = handleValidate()
      updateOccasionalNovelty(formData.id, payload, () => {
        setFormAction(INITAL_STATE_FORM_ACTION)
        handleReload()
        dispatch(notifySuccessful('Hemos actualizado la novedad exitosamente'))
      })
    } catch (err) {
      dispatch(notifyError(err))
    }
  }

  const {edition_type} = formData
  const isPermissionsPartial = isEqual(edition_type, NOVELTY_EDITION_TYPES.PARTIAL)
  const isPermissionsFull = isEqual(edition_type, NOVELTY_EDITION_TYPES.FULL)

  const successButtonText = getSuccessButtonText(formAction.type)

  return (
    <NoveltyFormContainer
      cancelFunction={handleCancelForm}
      successFunction={handleSuccessForm}
      successButtonText={successButtonText}
      hasEditPermissions={isPermissionsFull || isPermissionsPartial}
    >
      <SelectEmployeeInput
        isRequired
        isDisabled={isAnyEqual(formAction.type, [EDIT, SHOW]) || isPermissionsFull || isPermissionsPartial}
        name="employee_id"
        id="employee_id"
        label="Empleado"
        placeholder="Busca por Identificación o nombre"
        onSelect={handleOnSelectEmployeeInput}
        initialValue={formData.employee_description}
      />

      <SelectConceptInput
        isRequired
        isDisabled={isDisabled}
        name="concept_id"
        id="concept_id"
        label="Concepto"
        placeholder="Busca por código o nombre"
        onSelect={handleOnSelectConceptInput}
        concepts={concepts}
        initialValue={formData.concept_description}
      />

      <FormInput
        isDisabled={isDisabled}
        isRequired
        name="value"
        id="value"
        label="Valor"
        placeholder="0.00"
        value={formData.value}
        onChange={handleChangeValue}
        align="right"
      />

      <FormInput
        isDisabled={isDisabled}
        isRequired
        name="quantity"
        id="quantity"
        label="Cantidad"
        placeholder="0.00"
        value={formData.quantity}
        onChange={handleChageQuantity}
        align="right"
      />

      {isFilteredByDates && (
        <Select
          isRequired
          isDisabled={isDisabled}
          id="pay_period_id"
          name="pay_period_id"
          label="Periodo"
          placeholder="Selecciona un periodo"
          onChange={handleOnChangePayPeriod}
          value={formData.pay_period_id}
        >
          {Array.isArray(periods) &&
            periods.map(period => (
              <option key={period.id} value={period.id}>
                {`${shortFormatDate(period.begins_at)} -
                  ${shortFormatDate(period.ends_at)}`}
              </option>
            ))}
        </Select>
      )}

      <div
        className={classNames('s-order-1 m-order-initial m-rows-2 lg-rows-2', {
          'm-cols-2 lg-cols-2': !isFilteredByDates
        })}
      >
        <TextArea
          isDisabled={isDisabled}
          className="s-h-full"
          name="notes"
          id="notes"
          placeholder="Escribe una nota"
          value={formData.notes}
          onChange={handleOnChangeInputText}
          align="left"
          label="Notas u observaciones"
          textarea={{
            className: 's-h-full'
          }}
        />
      </div>
    </NoveltyFormContainer>
  )
}

export default OccasionalNoveltyForm
