import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/dian-form-220-settings'

export const getEmployerYearlyCertificates = (callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(RESOURCE, callback, callbackError)
}

export const getSettingsByCertificateYear = (year, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  const endpoint = `${RESOURCE}/years/${year}`

  request.get(endpoint, callback, callbackError)
}

export const closeIncomesCertificatesByYear = (year, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  const endpoint = `${RESOURCE}/years/${year}/close`

  request.put(endpoint, {}, callback, callbackError)
}

export const getSettingsReportByYear = (year, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  const endpoint = `${RESOURCE}/years/${year}/reports/settings`

  request.getFile(endpoint, callback, callbackError)
}

export const createNewSetting = (callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()

  request.post(RESOURCE, {}, callback, callbackError)
}

export const updateCertificateSetting = (payload, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()

  request.put(RESOURCE, payload, callback, callbackError)
}
