import React from 'react'

import SortHead from '../../../../components/Atoms/SortHead'
import {isEqual} from '../../../../helpers/utils'
import {SORT_DIRECTION_ASC, SORT_DIRECTION_DESC} from '../../constants/filters'
import {useNoveltiesFiltersContext} from '../../providers/NoveltiesFilters'

const NoveltyTableHeader = ({header}) => {
  const {setFilters, filters} = useNoveltiesFiltersContext()

  const {label, key} = header
  const isCurrentFilter = isEqual(filters.sort, key)
  const isCurrentAscendant = isCurrentFilter && isEqual(filters.sort_direction, SORT_DIRECTION_ASC)

  const handleClick = () => {
    setFilters(prev => {
      const {sort_direction, sort} = prev

      const isNewField = !isEqual(sort, key)
      const isAscendant = isEqual(sort_direction, SORT_DIRECTION_ASC)
      const updatedDirection = isAscendant ? SORT_DIRECTION_DESC : SORT_DIRECTION_ASC

      const newSortDirection = isNewField ? SORT_DIRECTION_ASC : updatedDirection

      return {...prev, sort: key, page: 1, sort_direction: newSortDirection}
    })
  }

  return (
    <SortHead handleClick={handleClick} isCurrent={isCurrentFilter} isAscendant={isCurrentAscendant} label={label} />
  )
}

export default NoveltyTableHeader
