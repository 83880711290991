import {useContext, useEffect} from 'react'
import {useSelector} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {isEqual, len} from '../../../helpers/utils'
import {selectors as employerSelector} from '../../../redux/ducks/employer'
import {selectors as configProcessTypeSelectors} from '../../../redux/ducks/processTypeConfig'
import {useNoveltiesCategoriesContext} from '../providers/NoveltiesCategories'

const useLoadCategories = () => {
  const {activeProcess} = useContext(PayrollContext)
  const {setActiveCategory, setCategories} = useNoveltiesCategoriesContext()

  const employer = useSelector(employerSelector.getSession)
  const configObj = useSelector(configProcessTypeSelectors.getObj)
  const configProcess = configObj[activeProcess?.process_type_code]

  useEffect(() => {
    const options = configProcess?.config?.rules?.novelties[employer?.code_iso_3]

    if (!Array.isArray(options) || isEqual(len(options), 0)) {
      setCategories([])
      setActiveCategory('')
      return
    }

    setCategories(options)
    setActiveCategory(options[0])
  }, [configProcess, employer?.code_iso_3])
}

export default useLoadCategories
