import PropTypes from 'prop-types'
import React, {useEffect, useRef, useState} from 'react'

import DateFilterWarning from '../../../modules/payroll-process-novelties/components/dates/DateFilterWarning'

const NoveltyTableWrapper = ({isOpenForm, resizeFormReason, form, table, className}) => {
  const containerForm = useRef()
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (!isOpenForm) setHeight(0)

    if (containerForm.current) {
      // we plused 32px of the container marginBottom
      setHeight(containerForm.current.getBoundingClientRect().height + 32)
    }
  }, [isOpenForm, resizeFormReason])

  return (
    <div className={className}>
      <div className="height-transition s-px-16px s-py-16px m-py-0 m-px-24px lg-px-32px" style={{height: height}}>
        {isOpenForm && (
          <div ref={containerForm} className="s-mb-3 s-border-bottom-border">
            {form}
          </div>
        )}
      </div>
      <DateFilterWarning />
      {table}
    </div>
  )
}

NoveltyTableWrapper.propTypes = {
  form: PropTypes.element,
  table: PropTypes.element,
  isOpenForm: PropTypes.bool
}

NoveltyTableWrapper.defaultProps = {
  isOpenForm: false
}

export default NoveltyTableWrapper
