import {
  KEY_NOVELTY_ABSENCES,
  KEY_NOVELTY_LEAVES,
  KEY_NOVELTY_OCCASIONAL,
  KEY_NOVELTY_RECURRENT,
  KEY_NOVELTY_VACATIONS
} from '../../../helpers/novelties'

export const CATEGORIES_CONFIG = {
  [KEY_NOVELTY_OCCASIONAL]: {
    key: KEY_NOVELTY_OCCASIONAL,
    icon: 'clock',
    name: 'Novedades Ocasionales',
    caption: 'Las ingresas en cada período',
    is_coming_soon: false
  },
  [KEY_NOVELTY_ABSENCES]: {
    key: KEY_NOVELTY_ABSENCES,
    icon: 'hand',
    name: 'Ausentismos',
    caption: 'Ausencias remuneradas y NO remuneradas',
    is_coming_soon: false
  },
  [KEY_NOVELTY_LEAVES]: {
    key: KEY_NOVELTY_LEAVES,
    icon: 'user-minus',
    name: 'Incapacidades',
    caption: 'Incapacidades y licencias médicas',
    is_coming_soon: false
  },
  [KEY_NOVELTY_VACATIONS]: {
    key: KEY_NOVELTY_VACATIONS,
    icon: 'sun',
    name: 'Vacaciones',
    caption: 'Vacaciones disfrutadas o en dinero',
    is_coming_soon: false
  },
  [KEY_NOVELTY_RECURRENT]: {
    key: KEY_NOVELTY_RECURRENT,
    icon: 'calendar',
    name: 'Novedades Recurrentes',
    caption: 'Crealas una sola vez y te las incluimos en cada período',
    is_coming_soon: false
  }
}
