import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect, Switch, useRouteMatch} from 'react-router-dom'
import useSound from 'use-sound'

import FullPageLoader from '../components/Organisms/FullPageLoader'
import MainNavigation from '../components/Organisms/MainNavigation'
import routes, {redirectRoute} from '../config/routes'
import PrivateEmployer from '../config/Routes/PrivateEmployer'
import {getEmployerTokenData} from '../helpers/employer'
import {isAnyUndefined, isEqual, isUndefined} from '../helpers/utils'
import {WS_STATUS_CLOSE_NORMAL_CLOSURE} from '../helpers/websocket'
import {selectors as branchOfficeSelector} from '../redux/ducks/branchOffices'
import {actions as employerActions, selectors as employerSelectors} from '../redux/ducks/employer'
import {
  NOTIFICATION_DEFAULT_PAGE,
  NOTIFICATION_LIMIT_SIZE,
  selectors as notificationSelectors
} from '../redux/ducks/notificationUsers'
import {selectors as userSelectors} from '../redux/ducks/user'
import {getAbsenceTypeConcepts} from '../services/absenceTypeConcepts'
import {getAccountingInterfaceConfigurations} from '../services/accountingInterfaceConfiguration'
import {getAreas} from '../services/areas'
import {getBranchOffices} from '../services/branchOffices'
import {getCache} from '../services/cache'
import {getEmployerConcepts} from '../services/concept'
import {getDimensions} from '../services/dimensions'
import {getEmployers} from '../services/employer'
import {getEmployerMonthActive} from '../services/employermonth'
import {getJobs} from '../services/jobs'
import {
  addNotificationUserFromWebsocket,
  getHasNotificationsUser,
  getNotificationsUser
} from '../services/notificationUsers'
import {getActivePayPeriods} from '../services/payPeriodsRelation'
import {getProcessTypesActive} from '../services/processType'
import {getUserData} from '../services/user'
import {getWorkplaces} from '../services/workplaces'
import Dashboard from './Dashboard'
import Employees from './Employees'
import Payroll from './Payroll'
import Profile from './Profile'
import Settings from './Settings'

const Home = () => {
  const dispatch = useDispatch()
  let {path} = useRouteMatch()

  const [playSound] = useSound(`${process.env.REACT_APP_S3_SERVER}/static/notification-sound.mp3`)
  const branchOffices = useSelector(branchOfficeSelector.getAll)

  useEffect(() => {
    // connect to Websocket with token1 because this page is only renderized if the token1
    // and token2 exists.
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY)
    const ws = new WebSocket(`${process.env.REACT_APP_WS}/ws?authorization=${token}`)

    ws.onmessage = function (event) {
      const message = JSON.parse(event.data)
      dispatch(addNotificationUserFromWebsocket(message))
    }

    return () => {
      // close socket connection if the Home is unmounted because only we need to read
      // messages  on realtime for the active company, if the company changes the connection
      // continue  active because the connection with the websocket is per user not per user
      // and employer, but if the user exits of an employer page or logout, the connection
      // is closed  because when the user sign in company this page is mounted and the
      // connection is stablished
      ws.close(WS_STATUS_CLOSE_NORMAL_CLOSURE)
    }

    // we don't including dependencies here, this hook must be only run in the mount step
  }, []) // eslint-disable-line

  // it loads the button support Tidio when the Home page is mounted
  // useEffect(() => {
  //   var tidioScript = document.createElement('script')
  //   tidioScript.src = '//code.tidio.co/5j3u4ojc7guvoac2bgsd8wogky6bnu8i.js'
  //   document.body.appendChild(tidioScript)
  // }, [])

  const newNotificationTimestamp = useSelector(notificationSelectors.getHasNewNotificationTimestamp)
  useEffect(() => {
    if (isUndefined(newNotificationTimestamp) || isEqual(newNotificationTimestamp, 0)) return

    playSound()
  }, [newNotificationTimestamp])

  const user = useSelector(userSelectors.get)
  useEffect(() => {
    if (isUndefined(user.email)) dispatch(getUserData())
  }, [user, dispatch])

  const employers = useSelector(employerSelectors.getAll)
  useEffect(() => {
    if (isUndefined(employers)) dispatch(getEmployers())
  }, [employers, dispatch])

  // Set session of the employer in Redux only if not exists
  const employerSession = useSelector(employerSelectors.getSession)
  useEffect(() => {
    function employerLogin() {
      dispatch(employerActions.signIn(getEmployerTokenData().employer_id))
    }
    if (!isUndefined(employers) && isUndefined(employerSession)) employerLogin()
  }, [employers, employerSession, dispatch])

  // when the session isn't undefined, then apply the class.
  // The session is sets when this page is load, or when the employer is selected in the
  // employer page or sidebar menu
  useEffect(() => {
    if (isUndefined(employerSession)) return

    document.body.classList.add('loggued')
    getCache(employerSession.code_iso_3)
  }, [employerSession])

  // Load employer data only when the session data is set in Redux. This happens if:
  //  * A session changes, for example is selected other company in the sidebar selector.
  //  * From the /employers page is redirect to here, so this page is load
  //  * This page is reloaded
  useEffect(() => {
    function loadEmployerData() {
      dispatch(getNotificationsUser(NOTIFICATION_DEFAULT_PAGE, NOTIFICATION_LIMIT_SIZE))
      dispatch(getHasNotificationsUser())
      dispatch(getBranchOffices())
      dispatch(getAreas())
      dispatch(getAccountingInterfaceConfigurations())
      dispatch(getDimensions())
      dispatch(getJobs())
      dispatch(getActivePayPeriods())
      dispatch(getEmployerMonthActive())
      dispatch(getWorkplaces())
      dispatch(getEmployerConcepts())
      dispatch(getAbsenceTypeConcepts())
      dispatch(getProcessTypesActive())
    }
    if (!isUndefined(employerSession)) loadEmployerData()
  }, [employerSession, dispatch])

  // remove any data of employer session when unmount component
  useEffect(() => {
    return function cleanupEmployerSession() {
      sessionStorage.clear()

      // close any duck that contains the action SIGN_OUT_EMPLOYER
      dispatch(employerActions.signOut())
      document.body.classList.remove('loggued')
    }
  }, [dispatch])

  return isAnyUndefined(user, employers, employerSession, branchOffices) ? (
    <FullPageLoader />
  ) : (
    <main className="main">
      <MainNavigation />

      <Switch>
        {/* Dashboard */}
        <PrivateEmployer exact path={path}>
          <Dashboard />
        </PrivateEmployer>

        {/* Employees */}
        <PrivateEmployer path={routes.employees.base}>
          <Employees />
        </PrivateEmployer>

        {/* Payroll */}
        <PrivateEmployer path={`${routes.payroll.base}/:month`}>
          <Payroll />
        </PrivateEmployer>

        {/* Company settings */}
        <PrivateEmployer path={routes.settings.base}>
          <Settings />
        </PrivateEmployer>

        {/* Profile */}
        <PrivateEmployer exact path={routes.profile}>
          <Profile />
        </PrivateEmployer>

        <Redirect to={redirectRoute.private} />
      </Switch>
    </main>
  )
}

export default Home
