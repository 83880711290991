import {RequestWithEmployerAuthorization} from '../helpers/request'
import {NOVELTY_PROCCESS_STRATEGY} from '../modules/payroll-process-novelties/constants/novelties'
import {NOVELTIES_STRATEGIES_DATA} from '../modules/payroll-process-novelties/constants/strategies'

const RESOURCE = '/occasional-novelties'

export const getOccasionalNoveltiesByStrategy = (
  strategy = NOVELTY_PROCCESS_STRATEGY,
  params,
  callback,
  errorCallback
) => {
  const request = new RequestWithEmployerAuthorization()
  const {path} = NOVELTIES_STRATEGIES_DATA[strategy]

  request.get(`${RESOURCE}/strategies/${path}?${params}`, callback, errorCallback)
}

export const createOccasionalNovelty = (payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(RESOURCE, payload, response => callback(response))
}

export const updateOccasionalNovelty = (noveltyID, payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/${noveltyID}`, payload, response => callback(response))
}

export const deleteOccasionalNovelty = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${RESOURCE}/${noveltyID}`, callback)
}

export const getOccasionalNoveltyByID = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/${noveltyID}`, callback)
}

export const bulkOccasionalNovelty = payload => {
  const request = new RequestWithEmployerAuthorization()

  let data = new FormData()
  data.append('file', payload.file)
  data.append('pay_period_id', payload.pay_period_id)

  if (payload.process_id) {
    data.append('process_id', payload.process_id)
  }

  return new Promise((resolve, reject) => {
    request.post(`${RESOURCE}/bulk`, data, resolve, reject)
  })
}

export const generateOccasionalNoveltiesReport = (strategy, params) => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.getFile(
      `${RESOURCE}/reports/strategies/${strategy}?${params}`,
      (data, headers) => resolve({data, headers}),
      reject
    )
  })
}
