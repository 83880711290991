import React from 'react'

import {CATEGORIES_CONFIG} from '../modules/payroll-process-novelties/constants/categories'
import {isEqual} from './utils'

export const getNoveltiesFallbackData = (isOpen, type, generateNew, isError) => {
  const isAbsence = isEqual(type, CATEGORIES_CONFIG.ABSENCE.name)

  const handleGenerateNew = () => {
    if (!generateNew) return

    generateNew()
  }

  if (isError) {
    return {
      title: '¡Vaya! Ocurrio un error',
      description: `No pudimos cargar ${isAbsence ? 'los' : 'las'} ${type}`
    }
  }

  if (isOpen)
    return {
      title: type,
      description: (
        <>
          Aún no tienes novedades, da click en{' '}
          <span
            className="s-bg-transparent s-border-none s-pxy-0 decoration-none cursor-pointer s-color-blue-500"
            onClick={handleGenerateNew}
            // >
          >
            Nuevo
          </span>
        </>
      )
    }

  return {
    title: '¡Oh! no hay novedades',
    description: `Este proceso no tuvo ${type}`
  }
}
