import classNames from 'classnames'
import React from 'react'

import Badge from '../../../../components/Atoms/Badge'
import {CONCEPT_MAP_COLOR, CONCEPT_MAP_ORIGIN} from '../../../../constants/concept'
import {isUndefined} from '../../../../helpers/utils'

const ConceptOption = ({handleClick, isActive, option}) => {
  const {
    label: code,
    has_alerts,
    concept_description,
    concept_code,
    concept_type_description,
    origin,
    description
  } = option
  const originLogo = CONCEPT_MAP_ORIGIN[origin]?.logo

  return (
    <article
      className={classNames(
        {'is-active': isActive},
        'flex s-mb-0 s-cross-center nowrap overflow-ellipsis s-px-1 s-py-2px hover-change blue normal-radius cursor-pointer'
      )}
      onClick={() => handleClick(option)}
    >
      <div className="overflow-ellipsis s-100">
        <p className="small s-mb-0 flex s-cross-center nowrap">
          <span
            className={classNames('s-mr-8px overflow-ellipsis', {
              's-90': !isUndefined(has_alerts) && has_alerts
            })}
          >
            {concept_description || `${code} - ${description}`}
          </span>
        </p>

        <div className="s-cross-center smaller s-color-light-text">
          <p className="s-mb-0 s-mr-8px">{concept_code || code}</p>
          <Badge
            text={concept_type_description}
            color={CONCEPT_MAP_COLOR[concept_type_description].color}
            isGhost={CONCEPT_MAP_COLOR[concept_type_description].is_ghost}
            className={originLogo && 's-mr-8px'}
          />
          {originLogo && <img src={originLogo} alt="origen" style={{width: '2rem'}} />}
        </div>
      </div>
    </article>
  )
}

export default ConceptOption
