import classNames from 'classnames'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import SkeletonStyles from '../../../../styles/modules/icomes-certificate/skeleton.module.scss'
import useGetCurrentCertificateByContract from '../../hooks/useGetCurrentCertificateByContract'
import SelectedCertificateEmptyState from '../layout/SelectedCertificateEmptyState'
import CertificateIframe from './CertificateIframe'
import DownloadCertificateButton from './DownloadCertificateButton'

const SelectedIncomesCertificate = () => {
  const {isLoading, isError, data, currentCertificateId} = useGetCurrentCertificateByContract()

  if (isError) return <SelectedCertificateEmptyState isError />
  if (isLoading)
    return (
      <div className="ed-grid rows-gap s-cols-2">
        <Skeleton className={classNames(SkeletonStyles.root, 's-h-full')} />
      </div>
    )

  return (
    <div className="ed-grid lg-cols-2 s-cross-start mt-none n-container-none-padding s-pxy-2 s-h-fit">
      <DownloadCertificateButton id={currentCertificateId} />
      <hr className="s-mb-2" />
      <CertificateIframe content={data} hideOverflow className="s-mb-0" />
    </div>
  )
}

export default SelectedIncomesCertificate
