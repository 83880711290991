import {isEmpty} from '../../../helpers/utils'
import {CERTIFICATE_SETTINGS_ALLOWED_CONCEPT_TYPES} from '../constants/concept'

export const formatConceptsToOption = concepts => {
  if (!concepts || isEmpty(concepts)) return []

  const filteredConcepts = filterConceptsForCertificateSetting(concepts)

  return filteredConcepts.map(concept => {
    const {id, code, description, has_alerts, concept_description, concept_code, concept_type_description, origin} =
      concept

    return {
      id,
      label: code,
      value: id,
      has_alerts,
      concept_description,
      concept_code,
      concept_type_description,
      origin,
      description
    }
  })
}

export const filterConceptsForCertificateSetting = concepts => {
  return concepts.filter(concept =>
    CERTIFICATE_SETTINGS_ALLOWED_CONCEPT_TYPES.includes(concept.concept_type_description)
  )
}

export const formatConceptsToObjects = concepts => {
  return concepts.reduce((result, item) => {
    if (item['id'] !== undefined && item['code'] !== undefined) {
      result[item['id']] = item['code']
    }
    return result
  }, {})
}

export const getMultiSelectInitialValues = (conceptList, idList) => {
  const idSet = new Set(idList)

  return conceptList.filter(item => idSet.has(item.id))
}

export const getMultiSelectOptions = (conceptList, formData) => {
  const idSet = new Set(Object.values(formData).flat())

  return conceptList.filter(item => !idSet.has(item.id))
}
