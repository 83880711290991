import React, {useContext, useEffect, useState} from 'react'

import {PayrollContext} from '../../../contexts/contexts'
import {readBackendDate} from '../../../helpers/dates'
import {CREATE, EDIT} from '../../../helpers/payroll'
import {fullName} from '../../../helpers/string-format'
import {isEqual, isNull} from '../../../helpers/utils'
import {RECURRENT_NOVELTY_FORM_INITIAL_STATE} from '../../../modules/payroll-process-novelties/constants/form'
import NoveltiesFiltersProvider from '../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useFormActionContext} from '../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import {getRecurrentNoveltyByID} from '../../../services/recurrentnovelty'
import RecurrentNoveltyTable from '../../Molecules/Tables/RecurrentNoveltyTable'
import NoveltyTableWrapper from '../Containers/NoveltyTableWrapper'
import RecurrentNoveltyForm from '../Forms/RecurrentNoveltyForm'

const RecurrentNovelty = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [dataForm, setDataForm] = useState(RECURRENT_NOVELTY_FORM_INITIAL_STATE)
  const {activeProcess} = useContext(PayrollContext)
  const [resizeFormReason, setResizeFormReason] = useState('')
  const {formAction} = useFormActionContext()

  useEffect(() => {
    setIsOpen(false)

    if (isNull(formAction.type)) {
      setIsOpen(false)
      return
    }

    if (isEqual(formAction.type, CREATE)) {
      setDataForm(RECURRENT_NOVELTY_FORM_INITIAL_STATE)
      setIsOpen(true)
      return
    }

    getRecurrentNoveltyByID(
      formAction.noveltyID,
      response => {
        setDataForm({
          id: response.id,
          concept_id: response.concept_id,
          concept_description: response.concept_description,
          contract_id: response.contract_id,
          employee_description: fullName(response),
          value: response.value,
          begins_at: readBackendDate(response.begins_at),
          ends_at: readBackendDate(response.ends_at),
          is_override: response.is_override,
          is_custom_formula: response.is_custom_formula,
          application_period_sequence: response.application_period_sequence,
          standard_behavior: response.standard_behavior,
          formula: response.formula,
          notes: response.notes,
          has_history: response.has_history,
          edition_type: response.edition_type
        })
        setIsOpen(true)
      },
      error => {
        console.error(error)
        setIsOpen(false)
      }
    )
  }, [formAction, activeProcess.id])

  return (
    <NoveltiesFiltersProvider>
      <NoveltyTableWrapper
        isOpenForm={isOpen}
        resizeFormReason={resizeFormReason}
        form={<RecurrentNoveltyForm initialState={dataForm} setResizeFormReason={setResizeFormReason} />}
        table={<RecurrentNoveltyTable />}
      />
    </NoveltiesFiltersProvider>
  )
}

export default RecurrentNovelty
