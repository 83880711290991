import React from 'react'

import {isEmpty} from '../../../../helpers/utils'
import useGetNoveltyTableHeaderList from '../../../../modules/payroll-process-novelties/hooks/useGetNoveltyTableHeaderList'
import Table from '../../../Wrappers/Table'
import NoveltyTableContentFallback from './NoveltyTableContentFallback'

const NoveltyTableContentWrapper = ({children, headers, novelties}) => {
  const headerElements = useGetNoveltyTableHeaderList(headers)

  if (!novelties || isEmpty(novelties)) return <NoveltyTableContentFallback />

  return <Table heads={headerElements}>{children}</Table>
}

export default NoveltyTableContentWrapper
