import React, {createContext, useContext, useRef} from 'react'

import HistoryReportModal from '../../../components/modals/HistoryReportModal'
import useHandleGenerateHistoricalReport from '../../../hooks/useHandleGenerateHistoricalReport'

const HistoricReportModalContext = createContext({})

const HistoricReportModalProvider = ({children}) => {
  const historicReportModalRef = useRef()
  const {onGenerate} = useHandleGenerateHistoricalReport()

  return (
    <HistoricReportModalContext.Provider
      value={{
        historicReportModalRef
      }}
    >
      <>
        {children}
        <HistoryReportModal ref={historicReportModalRef} onGenerate={onGenerate} />
      </>
    </HistoricReportModalContext.Provider>
  )
}

export const useHistoricReportModalContext = () => useContext(HistoricReportModalContext)

export default HistoricReportModalProvider
