import {nanoid} from 'nanoid'

const useHandleNoveltiesFilters = setFilters => {
  const handleReload = () => {
    setFilters(prev => {
      return {...prev, 'reload-id': nanoid(), page: 1}
    })
  }

  const handleNextPage = isLastPage => {
    if (isLastPage) return

    setFilters(prev => {
      return {...prev, page: prev.page + 1}
    })
  }

  const handleGetParams = filters => {
    const params = new URLSearchParams(filters)
    return params.toString()
  }

  return {
    handleReload,
    handleNextPage,
    handleGetParams
  }
}

export default useHandleNoveltiesFilters
