import React from 'react'

import {isEqual} from '../../../helpers/utils'
import useGetGeneratedCertificatesAmountByYear from '../hooks/useGetGeneratedCertificatesAmountByYear'
import IncomesCertificateEmptyListView from './layout/IncomesCertificateEmptyListView'
import IncomesCertificateNoConfigurationView from './layout/IncomesCertificateNoConfigurationView'
import IncomesCertificateSkeleton from './layout/IncomesCertificateSkeleton'
import IncomesCertificateWrapper from './layout/IncomesCertificateWrapper'
import IncomesCertificatesListLayout from './list/IncomesCertificatesListLayout'

const IncomesCertificatesList = () => {
  const {data, isError, isLoading} = useGetGeneratedCertificatesAmountByYear()

  if (isLoading) return <IncomesCertificateSkeleton />
  if (isError) return <IncomesCertificateNoConfigurationView isError />
  if (isEqual(data, 0)) return <IncomesCertificateEmptyListView />

  return (
    <IncomesCertificateWrapper>
      <IncomesCertificatesListLayout />
    </IncomesCertificateWrapper>
  )
}

export default IncomesCertificatesList
