import React from 'react'
import {Link} from 'react-router-dom'

import routes from '../../../../../config/routes'
import {readLinesFromSettingData} from '../../../helpers/lines'
import {useCurrentSettingContext} from '../../../providers/CurrentSetting'
import LectureLineItem from '../../line/LectureLineItem'

const CertificateSettingLecture = () => {
  const {
    currentSetting: {setting}
  } = useCurrentSettingContext()

  const lines = readLinesFromSettingData(setting)

  return (
    <div>
      {lines.map(line => {
        return <LectureLineItem key={line.id} line={line} />
      })}
      <footer className="flex s-flex-gap-12px">
        <Link to={`${routes.settings.base}${routes.settings.incomesWithholdingsCertificates}`}>
          <span className="button ghost">Atras</span>
        </Link>
      </footer>
    </div>
  )
}

export default CertificateSettingLecture
