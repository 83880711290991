import React, {createContext, useContext, useState} from 'react'

const IsReportLoadingContext = createContext({})

const IsReportLoadingProvider = ({children}) => {
  const [isReportsLoading, setIsReportsLoading] = useState(false)

  return (
    <IsReportLoadingContext.Provider
      value={{
        isReportsLoading,
        setIsReportsLoading
      }}
    >
      {children}
    </IsReportLoadingContext.Provider>
  )
}

export const useIsReportLoadingContext = () => useContext(IsReportLoadingContext)

export default IsReportLoadingProvider
