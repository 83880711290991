import {useDispatch} from 'react-redux'

import {createNewSetting} from '../../../services/certificate'
import {notifyError} from '../../../services/notification'
import {useSettingsListContext} from '../providers/SettingsList'

const useHandleCreateNewCertificate = setIsLoading => {
  const dispatch = useDispatch()
  const {setSettingListDataTimestamp} = useSettingsListContext()

  const handleCreateNewCertificate = () => {
    setIsLoading(true)

    createNewSetting(
      () => {
        setIsLoading(false)
        const newTimestamp = new Date().getTime()
        setSettingListDataTimestamp(newTimestamp)
      },
      () => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsLoading(false)
      }
    )
  }

  return {handleCreateNewCertificate}
}

export default useHandleCreateNewCertificate
