import React, {createContext, useContext, useRef, useState} from 'react'

import CertificateReportModal from '../../../components/modals/CertificatesReportModal'

const CertificatesReportModalContext = createContext({})

const CertificatesReportModalProvider = ({children}) => {
  const certificatesReportModalRef = useRef()

  return (
    <CertificatesReportModalContext.Provider value={{certificatesReportModalRef}}>
      <>
        {children}
        <CertificateReportModal modalRef={certificatesReportModalRef} />
      </>
    </CertificatesReportModalContext.Provider>
  )
}

export const useCertificatesReportModalContext = () => useContext(CertificatesReportModalContext)

export default CertificatesReportModalProvider
