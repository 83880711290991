import React from 'react'

import TabTemplateBasic from '../../../components/Atoms/Templates/TabTemplateBasic'
import PanelTemplate from '../../../components/Wrappers/PanelTemplate'

const CertificateSettingsTabWrapper = ({children}) => {
  return (
    <>
      <div className="s-py-1 tab-container s-cross-center">
        <TabTemplateBasic index={1} className="tab-item">
          Configuración
        </TabTemplateBasic>
      </div>

      <PanelTemplate index={1} onlyHidden>
        {children}
      </PanelTemplate>
    </>
  )
}

export default CertificateSettingsTabWrapper
