import React from 'react'

import {useCertificateSettingContext} from '../../providers/CertificateSetting'
import CloseCertificatesProvider from '../../providers/modals/CloseCertificate'
import PrepareConfirmationModalProvider from '../../providers/modals/PrepareConfirmationModal'
import CloseHeaderButton from './buttons/CloseHeaderButton'
import PrepareHeaderButton from './buttons/PrepareHeaderButton'
import ReportsHeaderButtonWrapper from './buttons/ReportsHeaderButtonWrapper'
import SendHeaderButton from './buttons/SendHeaderButton'

const IncomesCertificatesHeaderActions = () => {
  const {certificateSetting} = useCertificateSettingContext()

  if (!certificateSetting) return null

  const {is_closed} = certificateSetting

  return (
    <div className="s-cross-center s-flex-gap-8px m-flex-gap-12px">
      <PrepareConfirmationModalProvider>
        <PrepareHeaderButton />
      </PrepareConfirmationModalProvider>
      <ReportsHeaderButtonWrapper />
      {!is_closed && (
        <CloseCertificatesProvider>
          <CloseHeaderButton />
        </CloseCertificatesProvider>
      )}
      {is_closed && <SendHeaderButton />}
    </div>
  )
}

export default IncomesCertificatesHeaderActions
