import {PropTypes} from 'prop-types'
import React, {createContext, useContext, useRef, useState} from 'react'

import OccasionalNoveltyBulk from '../../../components/Organisms/Modals/OccasionalNoveltyBulk'

const OccasionalNoveltyBulkContext = createContext({})

const OccasionalNoveltyBulkProvider = ({children}) => {
  const bulkModalRef = useRef()
  const [isUploading, setIsUploading] = useState(false)

  return (
    <OccasionalNoveltyBulkContext.Provider
      value={{
        bulkModalRef,
        isUploading,
        setIsUploading
      }}
    >
      {children}
      <OccasionalNoveltyBulk ref={bulkModalRef} />
    </OccasionalNoveltyBulkContext.Provider>
  )
}

export const useOccasionalNoveltyBulkContext = () => useContext(OccasionalNoveltyBulkContext)

OccasionalNoveltyBulkProvider.propTypes = {
  children: PropTypes.node
}

OccasionalNoveltyBulkProvider.defaultProps = {
  children: null
}

export default OccasionalNoveltyBulkProvider
