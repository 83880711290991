import React from 'react'

import AbsenceNovelty from '../../../components/Organisms/Sections/AbsenceNovelty'
import LeaveNovelty from '../../../components/Organisms/Sections/LeaveNovelty'
import OccasionalNovelty from '../../../components/Organisms/Sections/OccasionalNovelty'
import RecurrentNovelty from '../../../components/Organisms/Sections/RecurrentNovelty'
import VacationNovelty from '../../../components/Organisms/Sections/VacationNovelty'
import {
  KEY_NOVELTY_ABSENCES,
  KEY_NOVELTY_LEAVES,
  KEY_NOVELTY_OCCASIONAL,
  KEY_NOVELTY_RECURRENT,
  KEY_NOVELTY_VACATIONS
} from '../../../helpers/novelties'

export const NOVELTY_CATEGORY_TABLES = {
  [KEY_NOVELTY_OCCASIONAL]: <OccasionalNovelty />,
  [KEY_NOVELTY_ABSENCES]: <AbsenceNovelty />,
  [KEY_NOVELTY_LEAVES]: <LeaveNovelty />,
  [KEY_NOVELTY_VACATIONS]: <VacationNovelty />,
  [KEY_NOVELTY_RECURRENT]: <RecurrentNovelty />
}
