import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {addMonths} from '../../../helpers/dates'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {fullName} from '../../../helpers/string-format'
import {isAnyEqual, isEqual, isNull} from '../../../helpers/utils'
import {OCCASIONAL_NOVELTY_FORM_INITIAL_STATE} from '../../../modules/payroll-process-novelties/constants/form'
import {NOVELTY_DATES_STRATEGY} from '../../../modules/payroll-process-novelties/constants/novelties'
import {useDateFilterModalContext} from '../../../modules/payroll-process-novelties/providers/DateFilterModal'
import NoveltiesFiltersProvider from '../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import OccasionalNoveltyBulkProvider from '../../../modules/payroll-process-novelties/providers/OccasionalNoveltyBulk'
import {useFormActionContext} from '../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import {useStrategyFilterContext} from '../../../modules/payroll-process-novelties/providers/StrategyFilter'
import {selectors as payPeriodsSelector} from '../../../redux/ducks/payPeriods'
import {getOccasionalNoveltyByID} from '../../../services/occasionalnovelty'
import {getPeriodsByRange} from '../../../services/payPeriods'
import OccasionalNoveltyTable from '../../Molecules/Tables/OccasionalNoveltyTable'
import NoveltyTableWrapper from '../Containers/NoveltyTableWrapper'
import OccasionalNoveltyForm from '../Forms/OccasionalNoveltyForm'

const OccasionalNovelty = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [formData, setFormData] = useState(OCCASIONAL_NOVELTY_FORM_INITIAL_STATE)
  const {formAction} = useFormActionContext()
  const [periods, setPeriods] = useState([])
  const employerPayPeriods = useSelector(payPeriodsSelector.getAll)

  const {beginDate, endDate} = useDateFilterModalContext()
  const {currentStrategyFilter} = useStrategyFilterContext()

  useEffect(() => {
    if (!isEqual(currentStrategyFilter, NOVELTY_DATES_STRATEGY)) {
      setPeriods([])
      return
    }

    let rangeBeginAt = addMonths(beginDate, -3).toISOString().split('T')[0]
    let rangeEndsAt = addMonths(endDate, 3).toISOString().split('T')[0]
    let employerFrequencies = employerPayPeriods.map(p => p.pay_frequency.id)

    getPeriodsByRange(rangeBeginAt, rangeEndsAt)
      .then(response => setPeriods(response.filter(p => isAnyEqual(p.pay_frequency_id, employerFrequencies))))
      .catch(error => console.error(error))
  }, [beginDate, endDate, currentStrategyFilter])

  useEffect(() => {
    setIsOpen(false)

    if (isNull(formAction.type)) {
      setIsOpen(false)
      return
    }

    if (isEqual(formAction.type, CREATE)) {
      setFormData(OCCASIONAL_NOVELTY_FORM_INITIAL_STATE)
      setIsOpen(true)
      return
    }

    if (isAnyEqual(formAction.type, [EDIT, SHOW])) {
      getOccasionalNoveltyByID(
        formAction.noveltyID,
        response => {
          setFormData({
            id: response.id,
            concept_id: response.concept_id,
            concept_description: response.concept_description,
            contract_id: response.contract_id,
            employee_description: fullName(response),
            pay_period_id: response.pay_period_id,
            process_id: response.process_id,
            quantity: response.quantity,
            value: response.value,
            notes: response.notes,
            edition_type: response.edition_type
          })
          setIsOpen(true)
        },
        error => {
          console.error(error)
          setIsOpen(false)
        }
      )
    }
  }, [formAction])

  return (
    <NoveltiesFiltersProvider>
      <OccasionalNoveltyBulkProvider>
        <NoveltyTableWrapper
          isOpenForm={isOpen}
          form={<OccasionalNoveltyForm initialState={formData} periods={periods} />}
          table={<OccasionalNoveltyTable />}
        />
      </OccasionalNoveltyBulkProvider>
    </NoveltiesFiltersProvider>
  )
}

export default OccasionalNovelty
