import {SORT_OPTIONS} from './novelties'

export const getOccasionalNoveltiesTableHeaders = isProcess => {
  if (isProcess) {
    return [
      {key: SORT_OPTIONS.EMPLOYEE, label: 'Empleado', isSortColumn: true},
      {key: SORT_OPTIONS.CONCEPT, label: 'Concepto', isSortColumn: true},
      {key: 'amount', label: 'Cantidad'},
      {key: 'value', label: 'Valor'},
      {key: 'actions', label: 'Acciones'}
    ]
  }

  return [
    {key: SORT_OPTIONS.EMPLOYEE, label: 'Empleado', isSortColumn: true},
    {key: SORT_OPTIONS.CONCEPT, label: 'Concepto', isSortColumn: true},
    {key: SORT_OPTIONS.BEGINS, label: 'Inicio', isSortColumn: true},
    {key: SORT_OPTIONS.ENDS, label: 'Fin', isSortColumn: true},
    {key: 'amount', label: 'Cantidad'},
    {key: 'value', label: 'Valor'},
    {key: 'actions', label: 'Acciones'}
  ]
}

export const ABSENCE_NOVELTY_TABLE_HEADERS = [
  {key: SORT_OPTIONS.EMPLOYEE, label: 'Empleado', isSortColumn: true},
  {key: SORT_OPTIONS.CONCEPT, label: 'Tipo', isSortColumn: true},
  {key: SORT_OPTIONS.BEGINS, label: 'Inicio', isSortColumn: true},
  {key: SORT_OPTIONS.ENDS, label: 'Fin', isSortColumn: true},
  {key: 'days', label: 'Días'},
  {key: 'actions', label: 'Acciones'}
]

export const LEAVE_NOVELTY_TABLE_HEADERS = [
  {key: SORT_OPTIONS.EMPLOYEE, label: 'Empleado', isSortColumn: true},
  {key: SORT_OPTIONS.TYPE_LEAVE, label: 'Tipo', isSortColumn: true},
  {key: SORT_OPTIONS.BEGINS, label: 'Inicio', isSortColumn: true},
  {key: SORT_OPTIONS.ENDS, label: 'Fin', isSortColumn: true},
  {key: 'days', label: 'Días'},
  {key: 'actions', label: 'Acciones'}
]

export const VACATION_NOVELTY_TABLE_HEADERS = [
  {key: SORT_OPTIONS.EMPLOYEE, label: 'Empleado', isSortColumn: true},
  {key: SORT_OPTIONS.TYPE_VACATION, label: 'Tipo', isSortColumn: true},
  {key: SORT_OPTIONS.BEGINS, label: 'Inicio', isSortColumn: true},
  {key: SORT_OPTIONS.ENDS, label: 'Fin', isSortColumn: true},
  {key: 'enable', label: 'Hábil'},
  {key: 'not_enable', label: 'No hábil'},
  {key: 'actions', label: 'Acciones'}
]

export const getRecurrentNoveltiesTableHeaders = isHistory => {
  return [
    {key: SORT_OPTIONS.EMPLOYEE, label: 'Empleado', isSortColumn: true},
    {key: SORT_OPTIONS.CONCEPT, label: 'Concepto', isSortColumn: true},
    {key: 'amount', label: isHistory ? 'Valor Aplicado' : 'Valor Base'},
    {key: SORT_OPTIONS.BEGINS, label: 'Inicio', isSortColumn: true},
    {key: SORT_OPTIONS.ENDS, label: 'Fin', isSortColumn: true},
    {key: 'actions', label: 'Acciones'}
  ]
}
