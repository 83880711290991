import React from 'react'

import Container from '../../../../components/Wrappers/Container'
import DownloadLinkReportProvider from '../../../employees-panel/providers/DownloadLinkReport'
import SingleCertificateSettingsBody from './SingleCertificateSettingsBody'
import SingleCertificateSettingsHeader from './SingleCertificateSettingsHeader'

const SingleCertificateSettingsView = () => {
  return (
    <Container>
      <DownloadLinkReportProvider>
        <SingleCertificateSettingsHeader />
      </DownloadLinkReportProvider>
      <SingleCertificateSettingsBody />
    </Container>
  )
}

export default SingleCertificateSettingsView
