import React from 'react'
import {Link} from 'react-router-dom'

import Badge from '../../../../components/Atoms/Badge'
import EditTextButton from '../../../../components/Atoms/Buttons/EditTextButton'
import SeeTextButton from '../../../../components/Atoms/Buttons/SeeTextButton'
import TableRow from '../../../../components/Wrappers/TableRow'
import routes from '../../../../config/routes'
import {BADGE_TYPES} from '../../constants/badge'

const CertificateSettingsViewItem = ({setting}) => {
  const {id, year, is_closed} = setting

  const {label, color} = is_closed ? BADGE_TYPES.CLOSED : BADGE_TYPES.OPEN

  return (
    <TableRow key={id}>
      <div className="flex s-cross-center">
        <p className="s-mr-1">{year}</p>
        <Badge text={label} color={color} className="s-mr-1" />
      </div>
      {!is_closed && (
        <Link to={`${routes.settings.base}${routes.settings.incomesWithholdingsCertificates}/${year}?is_edit=true`}>
          <EditTextButton />
        </Link>
      )}
      {is_closed && (
        <Link to={`${routes.settings.base}${routes.settings.incomesWithholdingsCertificates}/${year}`}>
          <SeeTextButton className="transparent-hover grayscale" />
        </Link>
      )}
    </TableRow>
  )
}

export default CertificateSettingsViewItem
