import React from 'react'
import {useDispatch} from 'react-redux'

import ConfirmModal from '../../../../components/Organisms/Modals/ConfirmModal'
import {closeIncomesCertificatesByYear} from '../../../../services/certificate'
import {notifyError} from '../../../../services/notification'
import {useCertificateSettingContext} from '../../providers/CertificateSetting'
import {useCertificatesSummaryContext} from '../../providers/CertificatesSummary'
import {useCloseCertificatesContext} from '../../providers/modals/CloseCertificate'

const CloseCertificateModal = ({modalRef}) => {
  const {certificateSetting} = useCertificateSettingContext()
  const {setIsCloseCertificatesLoading} = useCloseCertificatesContext()
  const {year} = certificateSetting
  const dispatch = useDispatch()
  const {setCertificatesSummary} = useCertificatesSummaryContext()

  const handleConfirm = () => {
    setIsCloseCertificatesLoading(true)
    modalRef.current.closeModal()

    closeIncomesCertificatesByYear(
      year,
      () => {
        setIsCloseCertificatesLoading(false)

        const timestamp = new Date().getTime()

        setCertificatesSummary(timestamp)
      },
      error => {
        setIsCloseCertificatesLoading(false)
        dispatch(notifyError(error))
      }
    )
  }

  return (
    <ConfirmModal
      ref={modalRef}
      hasCodeConfirmation
      confirmFunction={handleConfirm}
      title={`Cerrar certificados año ${year}`}
      content={
        <>
          ¿Estás seguro de cerrar el año <span className="s-color-title">{year}</span>? Recuerda que esta acción no se
          puede revetir.
        </>
      }
    />
  )
}

export default CloseCertificateModal
