import React, {useEffect, useState} from 'react'

import {readBackendDate} from '../../../helpers/dates'
import {CREATE, EDIT, SHOW} from '../../../helpers/payroll'
import {fullName} from '../../../helpers/string-format'
import {isAnyEqual, isEqual, isNull} from '../../../helpers/utils'
import {VACATION_NOVELTY_FORM_INITIAL_STATE} from '../../../modules/payroll-process-novelties/constants/form'
import NoveltiesFiltersProvider from '../../../modules/payroll-process-novelties/providers/NoveltiesFilters'
import {useFormActionContext} from '../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import {getVacationNoveltyByID} from '../../../services/vacationNovelty'
import VacationNoveltyTable from '../../Molecules/Tables/VacationNoveltyTable'
import NoveltyTableWrapper from '../Containers/NoveltyTableWrapper'
import VacationNoveltyForm from '../Forms/VacationNoveltyForm'

const VacationNovelty = () => {
  const [isOpen, setIsOpen] = useState(false)
  const {formAction} = useFormActionContext()

  const [dataForm, setDataForm] = useState(VACATION_NOVELTY_FORM_INITIAL_STATE)

  useEffect(() => {
    setIsOpen(false)

    if (isNull(formAction.type)) {
      setIsOpen(false)
      return
    }

    if (isEqual(formAction.type, CREATE)) {
      setDataForm(VACATION_NOVELTY_FORM_INITIAL_STATE)
      setIsOpen(true)
      return
    }

    if (isAnyEqual(formAction.type, [EDIT, SHOW])) {
      getVacationNoveltyByID(
        formAction.noveltyID,
        response => {
          setDataForm({
            id: response.id,
            contract_id: response.contract_id,
            employee_description: fullName(response),
            vacation_type_id: response.vacation_type_id,
            vacation_type_description: response.vacation_type_description,
            begins_at: readBackendDate(response.begins_at),
            amount_days: response.working_days || response.calendar_days,
            status: response.status,
            vacation_is_in_money: response.vacation_is_in_money,
            vacation_per_periods: response.vacation_per_periods,
            edition_type: response.edition_type
          })
          setIsOpen(true)
        },
        error => {
          console.error(error)
          setIsOpen(false)
        }
      )
    }
  }, [formAction])

  return (
    <NoveltiesFiltersProvider>
      <NoveltyTableWrapper
        isOpenForm={isOpen}
        form={<VacationNoveltyForm initialState={dataForm} />}
        table={<VacationNoveltyTable />}
      />
    </NoveltiesFiltersProvider>
  )
}

export default VacationNovelty
