import PropTypes from 'prop-types'
import React, {useRef} from 'react'
import {useSelector} from 'react-redux'

import {shortFormatDate} from '../../../../helpers/dates'
import {getFormatCurrency} from '../../../../helpers/format-number'
import {INITAL_STATE_FORM_ACTION} from '../../../../modules/payroll-process-novelties/constants/form'
import {useFormActionContext} from '../../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import {selectors as employerSelectors} from '../../../../redux/ducks/employer'
import DeleteModal from '../../../Organisms/Modals/DeleteModal'
import TableRow from '../../../Wrappers/TableRow'
import Concept from '../../Templates/Concept'
import Employee from '../../Templates/Employee'
import NoveltyRowAction from './NoveltyRowAction'

const RecurrentNoveltyRow = ({novelty, onDelete}) => {
  const deleteModal = useRef()
  const {setFormAction} = useFormActionContext()
  const employerSession = useSelector(employerSelectors.getSession)

  const handleOnClick = () => {
    onDelete(novelty.id)
    setFormAction(INITAL_STATE_FORM_ACTION)
    deleteModal.current.closeModal()
  }

  return (
    <>
      <TableRow>
        <Employee employee={novelty} />
        <Concept concept={novelty} />
        <p className="s-mb-0 m-right">
          {getFormatCurrency(
            novelty.value,
            employerSession.code_iso_3,
            !employerSession.not_include_decimals_in_calculation
          )}
        </p>
        <p className="s-mb-0">{shortFormatDate(novelty.begins_at)}</p>
        <p className="s-mb-0">{shortFormatDate(novelty.ends_at)}</p>
        <NoveltyRowAction novelty={novelty} deleteModalRef={deleteModal} />
      </TableRow>
      <DeleteModal ref={deleteModal} deleteFunction={handleOnClick} />
    </>
  )
}

RecurrentNoveltyRow.propTypes = {
  novelty: PropTypes.shape({}).isRequired
}

RecurrentNoveltyRow.defaultProps = {
  novelty: PropTypes.shape({})
}

export default RecurrentNoveltyRow
