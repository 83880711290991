import {EDIT, SHOW} from '../../../helpers/payroll'
import {isEqual} from '../../../helpers/utils'
import {INITAL_STATE_FORM_ACTION} from '../constants/form'
import {NOVELTY_EDITION_TYPES} from '../constants/novelties'
import {useFormActionContext} from '../providers/PayrollReportModalWrapper'

const useGetNoveltyActions = ({novelty, deleteModalRef}) => {
  const {setFormAction} = useFormActionContext()
  const {id, edition_type} = novelty

  const handleSee = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    setFormAction({
      type: SHOW,
      noveltyID: id
    })
  }

  const handleEdit = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    setFormAction({
      type: EDIT,
      noveltyID: id
    })
  }

  const handleDelete = () => {
    deleteModalRef.current.openModal()
    setFormAction(INITAL_STATE_FORM_ACTION)
  }

  if (isEqual(edition_type, NOVELTY_EDITION_TYPES.FULL)) {
    return {
      handleSee,
      handleEdit,
      handleDelete
    }
  }

  if (isEqual(edition_type, NOVELTY_EDITION_TYPES.PARTIAL)) {
    return {
      handleSee,
      handleEdit,
      handleDelete: undefined
    }
  }

  // For security the default case is the NOVELTY_EDITION_TYPES.NOT_ALLOWED permission
  return {handleSee, handleEdit: undefined, handleDelete: undefined}
}

export default useGetNoveltyActions
