import PropTypes from 'prop-types'
import React, {useRef} from 'react'

import {shortFormatDate} from '../../../../helpers/dates'
import {ORIGIN_INTEGRATION_NBC} from '../../../../helpers/origin'
import {capitalize} from '../../../../helpers/string-format'
import {INITAL_STATE_FORM_ACTION} from '../../../../modules/payroll-process-novelties/constants/form'
import {useFormActionContext} from '../../../../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import SAP_LOGO from '../../../../static/images/utils/SAP-logo.png'
import DeleteModal from '../../../Organisms/Modals/DeleteModal'
import TableRow from '../../../Wrappers/TableRow'
import Employee from '../../Templates/Employee'
import NoveltyRowAction from './NoveltyRowAction'

const MAP_ORIGIN = {
  [ORIGIN_INTEGRATION_NBC]: {logo: SAP_LOGO}
}

const LeaveNoveltyRow = ({novelty, onDelete}) => {
  const {setFormAction} = useFormActionContext()
  const deleteModal = useRef()
  const originLogo = MAP_ORIGIN[novelty?.origin]?.logo

  const handleOnClick = () => {
    onDelete(novelty.id)
    setFormAction(INITAL_STATE_FORM_ACTION)
    deleteModal.current.closeModal()
  }

  return (
    <>
      <TableRow>
        <Employee employee={novelty} />
        <div className="s-mb-0 s-cross-center">
          <span className={originLogo && 's-mr-8px'}>{capitalize(novelty.leave_type_description)}</span>
          {originLogo && <img src={originLogo} alt="origen" style={{width: '2rem'}} />}
        </div>
        <p className="s-mb-0">{shortFormatDate(novelty.begins_at)}</p>
        <p className="s-mb-0">{shortFormatDate(novelty.ends_at)}</p>
        <p className="s-mb-0 m-right">{novelty.days}</p>
        <NoveltyRowAction novelty={novelty} deleteModalRef={deleteModal} />
      </TableRow>
      <DeleteModal ref={deleteModal} deleteFunction={handleOnClick} />
    </>
  )
}

LeaveNoveltyRow.propTypes = {
  novelty: PropTypes.shape({}).isRequired
}

LeaveNoveltyRow.defaultProps = {
  novelty: PropTypes.shape({})
}

export default LeaveNoveltyRow
