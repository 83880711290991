import React from 'react'
import {Link} from 'react-router-dom'

import Badge from '../../../../components/Atoms/Badge'
import Breadcrumbs from '../../../../components/Atoms/Breadcrumbs'
import ActionButton from '../../../../components/Atoms/Templates/ActionButton'
import routes from '../../../../config/routes'
import {mediaFromM} from '../../../../helpers/media-queries'
import {useMedia} from '../../../../hooks/useMedia'
import {BADGE_TYPES} from '../../constants/badge'
import useHandleDownloadSettingReport from '../../hooks/useHandleDownloadSettingReport'
import {useCurrentSettingContext} from '../../providers/CurrentSetting'

const SingleCertificateSettingsHeader = () => {
  const {
    currentSetting: {year, is_closed}
  } = useCurrentSettingContext()
  const fromM = useMedia(mediaFromM)
  const {isLoading, downloadSettingReport} = useHandleDownloadSettingReport()
  const badgeTypeData = is_closed ? BADGE_TYPES.CLOSED : BADGE_TYPES.OPEN

  return (
    <header className="s-mb-2 flex s-main-justify">
      <div>
        <Breadcrumbs className="s-mb-1">
          <Link to={`${routes.settings.base}${routes.settings.incomesWithholdingsCertificates}`}>Configuración</Link>
          <p className="s-mb-0 s-mr-8px">{year}</p>
        </Breadcrumbs>

        <Badge text={badgeTypeData.label} color={badgeTypeData.color} className="s-w-fit" />
      </div>

      <ActionButton
        icon="document"
        content={fromM ? 'Reportes' : ''}
        isDisabled={isLoading}
        onClick={downloadSettingReport}
      />
    </header>
  )
}

export default SingleCertificateSettingsHeader
