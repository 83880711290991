import React, {useContext, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {processRoute} from '../../../config/routes'
import {PayrollContext} from '../../../contexts/contexts'
import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {readBackendDate, shortFormatDate} from '../../../helpers/dates'
import {findProcessStage, STAGE_PAYSHEET} from '../../../helpers/payroll'
import {getPeriodRangeAndFrequency} from '../../../helpers/period'
import {isEmpty, isEqual, isNull} from '../../../helpers/utils'
import ValidatorForm from '../../../helpers/validator'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {notifyError} from '../../../services/notification'
import {getPreviousPayPeriods} from '../../../services/payPeriodsRelation'
import {createProcess} from '../../../services/process'
import WarningImg from '../../../static/images/stories/warning.svg'
import AddButton from '../../Atoms/Buttons/AddButton'
import CancelTextButton from '../../Atoms/Buttons/CancelTextButton'
import SaveTextButton from '../../Atoms/Buttons/SaveTextButton'
import Select from '../../Atoms/Forms/Select'
import FormInput from '../../Atoms/Templates/FormInput'
import DischargedEmployee from '../../Molecules/DischargedEmployee'
import DatePickerInput from '../../Molecules/Pickers/DatePickerInput'
import SelectEmployeeForTermination from '../../Molecules/Selects/SelectEmployeeForTermination'

//----------------------------------------------------------------------------------------
// Form Data
//----------------------------------------------------------------------------------------

const initialStateFormData = {
  process_type_code: '',
  description: '',
  application_pay_period_id: 0,
  pay_frequency_id: 0,
  contracts: []
}

const formFields = {
  process_type_code: 'Tipo de proceso',
  description: 'Nombre de tu proceso',
  application_pay_period_id: 'Periodo de aplicación',
  pay_frequency_id: 'Frecuencia de pago'
}

const rules = {
  process_type_code: 'required|string',
  description: 'required|string|min:5',
  application_pay_period_id: 'required|integer|not_in:0',
  pay_frequency_id: 'required|integer|not_in:0'
}

//----------------------------------------------------------------------------------------
// Employee Item
//----------------------------------------------------------------------------------------

const initialStateEmployeeItem = {
  employee: null,
  id: 0,
  termination_reason_id: 0,
  termination_date: null,
  termination_notes: ''
}

const formFieldsEmployeeItem = {
  employee: 'Empleado',
  id: 'Empleado',
  termination_reason_id: 'Motivo de terminación',
  termination_date: 'Fecha de terminación',
  termination_notes: 'Notas'
}

const rulesEmployeeItem = {
  id: 'required|integer|not_in:0',
  termination_reason_id: 'required|integer|not_in:0',
  termination_date: 'required|date',
  termination_notes: 'string'
}

//----------------------------------------------------------------------------------------
// Component
//----------------------------------------------------------------------------------------

const ProcessExecSettlementForm = ({data, handleCloseModal}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {payrollDate} = useContext(PayrollContext)
  const employerSession = useSelector(employerSelectors.getSession)

  const activePeriods = data?.periods.filter(p => p.is_active)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const reasons = !isNull(cacheGeneral) && cacheGeneral.data?.termination_reasons

  const [formData, setFormData] = useState(initialStateFormData)
  const [currentEmployeeItem, setCurrentEmployeeItem] = useState(initialStateEmployeeItem)
  const [applicationPeriodList, setApplicationPeriodList] = useState([])
  const [isDisable, setIsDisable] = useState(false)
  const [isShowEmployeeForm, setIsShowEmployeeForm] = useState(false)
  const [minDate, setMinDate] = useState('')
  const [maxDate, setMaxDate] = useState('')

  const handleOnChangeFrequency = e => {
    const value = e.target.value

    setFormData(state => ({
      ...state,
      process_type_code: data?.type?.code,
      pay_frequency_id: Number(value)
    }))

    // reset state employee item
    setCurrentEmployeeItem(initialStateEmployeeItem)
    setMinDate('')
    setMaxDate('')

    const activePeriod = data?.periods.find(p => isEqual(p?.pay_frequency?.id, Number(value)) && p.is_active)

    getPreviousPayPeriods(activePeriod?.pay_frequency?.id, activePeriod?.pay_period?.ends_at, response => {
      setApplicationPeriodList(response)
    })
  }

  const getContractIDs = () => {
    if (isEmpty(formData.contracts)) return null

    return formData.contracts.map(contract => contract.id)
  }

  const handleOnChangeDescription = e => {
    const value = e.target.value

    setFormData(state => ({
      ...state,
      description: value
    }))
  }

  const handleOnChangeApplicationPayPeriod = e => {
    const value = e.target.value

    const options = e.target.options
    const i = options.selectedIndex
    setMinDate(options[i].getAttribute('data-begins-at'))
    setMaxDate(options[i].getAttribute('data-ends-at'))

    setFormData(state => ({
      ...state,
      application_pay_period_id: Number(value)
    }))
  }

  const handleOnSelectEmployee = data => {
    if (isNull(data)) {
      setCurrentEmployeeItem(state => ({
        ...state,
        employee: null,
        id: 0
      }))

      return
    }

    setCurrentEmployeeItem(state => ({
      ...state,
      employee: data,
      id: data.contract_id
    }))
  }

  const handleOnChangeTerminationReasonID = e => {
    const value = e.target.value

    setCurrentEmployeeItem(state => ({
      ...state,
      termination_reason_id: Number(value)
    }))
  }

  const handleAddNewEmployeeItem = () => {
    // if the user has not selected an application pay period not render the employee form.
    if (isEqual(formData.application_pay_period_id, 0)) return

    setIsShowEmployeeForm(true)
  }

  const handleSaveEmployeeItem = () => {
    const validator = new ValidatorForm(currentEmployeeItem, rulesEmployeeItem)
    validator.setAttributeNames(formFieldsEmployeeItem)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    setFormData(state => ({
      ...state,
      contracts: [...state.contracts, currentEmployeeItem]
    }))

    setIsShowEmployeeForm(false)
    setCurrentEmployeeItem(initialStateEmployeeItem)
  }

  const handleCancelEmployeeItemForm = () => {
    setIsShowEmployeeForm(false)
    setCurrentEmployeeItem(initialStateEmployeeItem)
  }

  const handleDiscardContract = contractID => {
    setFormData(state => ({
      ...state,
      contracts: [...state.contracts.filter(contract => contract.id !== contractID)]
    }))
  }

  const handleCreateProcess = () => {
    setIsDisable(true)

    const validator = new ValidatorForm(formData, rules)
    validator.setAttributeNames(formFields)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      setIsDisable(false)
      return
    }

    if (isEmpty(formData.contracts)) {
      dispatch(notifyError('¡Upps! debes agregar minimo un empleado'))
      setIsDisable(false)
      return
    }

    createProcess(
      formData,
      response => {
        setIsDisable(false)
        handleCloseModal()

        history.push({
          pathname: processRoute(payrollDate?.year, payrollDate?.month, response?.hash),
          hash: findProcessStage(
            Array.isArray(data?.type?.config?.rules?.stages) ? data?.type?.config?.rules?.stages[0] : STAGE_PAYSHEET
          )?.hash
        })
      },
      error => {
        setIsDisable(false)
        dispatch(notifyError(error))
      }
    )
  }

  if (isEmpty(activePeriods)) {
    return <AlertProcess content={'¡Upps! no hay periodos activos para crear el proceso.'} />
  }

  return (
    <form className="ed-grid form-grid form s-mb-0">
      <h3 className="s-center">Nuevo Proceso Liquidación de Contrato</h3>
      <div className="ed-grid m-grid-2 form-grid">
        <Select
          isRequired
          label={formFields.pay_frequency_id}
          name="pay_frequency_id"
          placeholder
          value={formData.pay_frequency_id}
          onChange={handleOnChangeFrequency}
        >
          {Array.isArray(activePeriods) &&
            activePeriods.map(period => (
              <option key={period.pay_frequency.id} value={period.pay_frequency.id}>
                {getPeriodRangeAndFrequency(
                  period.pay_period.begins_at,
                  period.pay_period.ends_at,
                  period.pay_frequency.description
                )}
              </option>
            ))}
        </Select>

        <Select
          isRequired
          label={formFields.application_pay_period_id}
          name="application_pay_period_id"
          placeholder
          value={formData.application_pay_period_id}
          onChange={handleOnChangeApplicationPayPeriod}
        >
          {Array.isArray(applicationPeriodList) &&
            applicationPeriodList.map(period => (
              <option
                key={period.pay_period.id}
                value={period.pay_period.id}
                data-begins-at={period.pay_period.begins_at}
                data-ends-at={period.pay_period.ends_at}
              >
                {`${shortFormatDate(period.pay_period.begins_at)} -
                  ${shortFormatDate(period.pay_period.ends_at)}`}
              </option>
            ))}
        </Select>
        <FormInput
          className="s-cols-2"
          label={formFields.description}
          placeholder="Liquidaciones del mes"
          isRequired
          value={formData.description}
          onChange={handleOnChangeDescription}
        />
      </div>

      <div>
        <div className="s-main-justify s-cross-center s-mb-2">
          <span className="t4">Empleados</span>
          {!isShowEmployeeForm && (
            <AddButton
              onClick={handleAddNewEmployeeItem}
              isDisable={isEqual(formData.application_pay_period_id, 0)}
              text="Agregar"
            />
          )}
        </div>

        <div className="ed-grid rows-gap s-gap-2">
          {isShowEmployeeForm && (
            <div className="ed-grid rows-gap s-gap-1 m-gap-2 s-pxy-1 m-pxy-2 s-bg-body normal-radius">
              <SelectEmployeeForTermination
                isRequired
                label="Empleado"
                name="employee"
                placeholder="Busca por identificación o nombre"
                onSelect={handleOnSelectEmployee}
                payFrequencyID={formData?.pay_frequency_id}
                ignoreContractIDIn={getContractIDs()}
              />

              <div className="ed-grid m-grid-2 rows-gap s-gap-1 m-gap-2">
                <Select
                  isRequired
                  label={formFieldsEmployeeItem.termination_reason_id}
                  name="termination_reason_id"
                  placeholder
                  value={currentEmployeeItem.termination_reason_id}
                  onChange={handleOnChangeTerminationReasonID}
                >
                  {Array.isArray(reasons) &&
                    reasons.map(reason => (
                      <option key={reason.id} value={reason.id}>
                        {reason.description}
                      </option>
                    ))}
                </Select>

                <DatePickerInput
                  label={formFieldsEmployeeItem.termination_date}
                  isRequired
                  value={currentEmployeeItem.termination_date}
                  minDate={readBackendDate(minDate)}
                  maxDate={readBackendDate(maxDate)}
                  onChange={date =>
                    setCurrentEmployeeItem(state => ({
                      ...state,
                      termination_date: date
                    }))
                  }
                />
              </div>
              <div className="flex">
                <SaveTextButton className="s-mr-4px" onClick={handleSaveEmployeeItem} />
                <CancelTextButton onClick={handleCancelEmployeeItemForm} />
              </div>
            </div>
          )}

          {/* Lista de empleados para liquidar */}
          {Array.isArray(formData.contracts) &&
            formData.contracts.map(contract => (
              <DischargedEmployee
                key={contract.employee.contract_id}
                data={contract}
                terminationReasons={reasons}
                onDiscard={handleDiscardContract}
              />
            ))}
        </div>
      </div>

      {/* Botones para cancelar o liquidar*/}
      <div className="buttons-container">
        <button type="button" onClick={handleCloseModal} className="button cancel">
          Cancela
        </button>
        <button className="button" type="button" onClick={handleCreateProcess} disabled={isDisable}>
          {isDisable ? 'Creando...' : 'Crea el proceso'}
        </button>
      </div>
    </form>
  )
}

const AlertProcess = ({content}) => {
  return (
    <div className="ed-grid s-grid-3 s-gap-2">
      <img src={WarningImg} alt="Warning" width="200px" height="200px" />
      <div className="s-cols-2 s-left s-cross-center">
        <p className="s-mb-0">{content}</p>
      </div>
    </div>
  )
}

export default ProcessExecSettlementForm
