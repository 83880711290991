import {RequestWithEmployerAuthorization} from '../helpers/request'
import {NOVELTY_PROCCESS_STRATEGY} from '../modules/payroll-process-novelties/constants/novelties'
import {NOVELTIES_STRATEGIES_DATA} from '../modules/payroll-process-novelties/constants/strategies'

const RESOURCE = '/absences-relation'

export const getAbsenceNoveltiesByStrategy = (
  strategy = NOVELTY_PROCCESS_STRATEGY,
  params,
  callback,
  errorCallback
) => {
  const request = new RequestWithEmployerAuthorization()
  const {path} = NOVELTIES_STRATEGIES_DATA[strategy]

  request.get(`${RESOURCE}/strategies/${path}?${params}`, callback, errorCallback)
}

export const createAbsenceNovelty = (payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.post(RESOURCE, payload, response => callback(response))
}

export const updateAbsenceNovelty = (noveltyID, payload, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.put(`${RESOURCE}/${noveltyID}`, payload, response => callback(response))
}

export const deleteAbsenceNovelty = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.delete(`${RESOURCE}/${noveltyID}`, callback)
}

export const getAbsenceNoveltyByID = (noveltyID, callback) => {
  const request = new RequestWithEmployerAuthorization()
  request.get(`${RESOURCE}/${noveltyID}`, callback)
}

export const generateAbsenceNoveltiesReport = (strategy, params) => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.getFile(
      `${RESOURCE}/reports/strategies/${strategy}?${params}`,
      (data, headers) => resolve({data, headers}),
      reject
    )
  })
}
