import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/dian-form-220'

export const generateDIANForm220PDF = (payload, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.generateFile(`${RESOURCE}/generate-pdf`, payload, callback, callbackErr)
}

export const getGeneratedCertificatesAmount = (year, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  const endpoint = `${RESOURCE}/years/${year}/summary`

  request.get(endpoint, callback, callbackError)
}

export const getEmployeesWithGeneratedCertificate = (queryParams, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  const endpoint = `${RESOURCE}/employees?${queryParams}`

  request.get(endpoint, callback, callbackError)
}

export const prepareIncomesCertificates = (year, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  const endpoint = `${RESOURCE}/populates/${year}`

  request.post(endpoint, {}, callback, callbackError)
}

export const getEmployeeCertificateByContractId = (payload, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  const endpoint = `${RESOURCE}/generate-reports`

  request.put(endpoint, payload, callback, callbackError)
}

export const getEmployeePDFCertificateByContractId = (payload, callback, callbackError) => {
  const request = new RequestWithEmployerAuthorization()
  const endpoint = `${RESOURCE}/generate-reports`

  request.generateFile(endpoint, payload, callback, callbackError)
}
