import {isEqual} from 'helpers/utils'
import {useState} from 'react'
import {useDispatch} from 'react-redux'

import {readBackendDate, writeBackendDate} from '../../../helpers/dates'
import ValidatorForm from '../../../helpers/validator'
import {notifyError} from '../../../services/notification'
import {DATE_FILTER_FORM_FIELD_NAMES, DATE_FILTER_FORM_RULES} from '../constants/form'
import {NOVELTY_DATES_STRATEGY, NOVELTY_PROCCESS_STRATEGY} from '../constants/novelties'
import {useDateFilterModalContext} from '../providers/DateFilterModal'
import {useStrategyFilterContext} from '../providers/StrategyFilter'

const useDateFilterForm = ({modalRef}) => {
  const dispatch = useDispatch()
  const {beginDate, setBeginDate, endDate, setEndDate} = useDateFilterModalContext()
  const {currentStrategyFilter, setCurrentStrategyFilter} = useStrategyFilterContext()

  const initialState = {
    beginDate: readBackendDate(beginDate),
    endDate: readBackendDate(endDate)
  }

  const [formData, setFormData] = useState(initialState)

  const resetForm = () => {
    setFormData(initialState)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const validator = new ValidatorForm(formData, DATE_FILTER_FORM_RULES)
    validator.setAttributeNames(DATE_FILTER_FORM_FIELD_NAMES)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      return
    }

    // validate if the begin date is lower than the end date
    if (writeBackendDate(formData.beginDate) > writeBackendDate(formData.endDate)) {
      dispatch(notifyError('La fecha de inicio no puede ser mayor a la fecha de fin.'))
      return
    }

    setBeginDate(writeBackendDate(formData.beginDate))
    setEndDate(writeBackendDate(formData.endDate))

    if (isEqual(currentStrategyFilter, NOVELTY_PROCCESS_STRATEGY)) {
      setCurrentStrategyFilter(NOVELTY_DATES_STRATEGY)
    }

    modalRef.current.closeModal()
  }

  return {
    formData,
    setFormData,
    handleSubmit,
    resetForm
  }
}

export default useDateFilterForm
