import React, {createContext, useContext, useState} from 'react'

const certificateSettingContext = createContext({})

const CertificateSettingProvider = ({children}) => {
  const [certificateSetting, setCertificateSetting] = useState()

  return (
    <certificateSettingContext.Provider
      value={{
        certificateSetting,
        setCertificateSetting
      }}
    >
      {children}
    </certificateSettingContext.Provider>
  )
}

export const useCertificateSettingContext = () => useContext(certificateSettingContext)

export default CertificateSettingProvider
