export const CERTIFICATE_REPORTS_CONTRACTS = {
  SELECTION: 'SELECTION',
  ALL: 'ALL'
}

export const CERTIFICATE_REPORTS_KIND = {
  ZIP: 'zip',
  ONE_FILE: 'one_file'
}

export const CERTIFICATE_REPORTS_INITIAL_STATE = {
  selectionType: CERTIFICATE_REPORTS_CONTRACTS.ALL,
  contractIds: [],
  formatFile: 'pdf',
  kind: CERTIFICATE_REPORTS_KIND.ZIP
}

export const CERTIFICATE_REPORTS_RULES = {
  contractIds: 'required_if:selectionType,SELECTION|array'
}

export const CERTIFICATE_REPORTS_FIELD_NAMES = {
  selectionType: '"Lista de empleados"',
  contractIds: '"empleados"',
  formatFile: '"Formato de archivo"',
  kind: '"Tipo de descarga (Generar como)"'
}
