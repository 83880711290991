import React, {forwardRef, useState} from 'react'
import {useDispatch} from 'react-redux'

import RadioButton from '../../../../components/Atoms/Forms/RadioButton'
import DatePickerInput from '../../../../components/Molecules/Pickers/DatePickerInput'
import ModalTemplate from '../../../../components/Wrappers/ModalTemplate'
import RadioButtonsWrapper from '../../../../components/Wrappers/RadioButtonsWrapper'
import {isEmpty, isEqual} from '../../../../helpers/utils'
import ValidatorForm from '../../../../helpers/validator'
import {notifyError} from '../../../../services/notification'
import MultiSelectEmployeeInput from '../../../employees-panel/components/MultiSelectEmployeeInput'
import {
  HISTORY_REPORT_FIELD_NAMES,
  HISTORY_REPORT_RULES,
  TYPE_ALL,
  TYPE_SELECTION
} from '../../constants/form/history-report'
import {getHistoryReportModalInitialState} from '../../helpers/form/history-report'
import {useCertificateSettingContext} from '../../providers/CertificateSetting'
import {useIsReportLoadingContext} from '../../providers/modals/reports/IsReportDownload'

const HistoryReportModal = forwardRef(({onGenerate = () => {}}, ref) => {
  const {
    certificateSetting: {year}
  } = useCertificateSettingContext()

  const dispatch = useDispatch()
  const {setIsReportsLoading} = useIsReportLoadingContext()
  const initialState = getHistoryReportModalInitialState(year)
  const [formData, setFormData] = useState(initialState)

  const handleGenerate = () => {
    setIsReportsLoading(true)

    const validator = new ValidatorForm(formData, HISTORY_REPORT_RULES)
    validator.setAttributeNames(HISTORY_REPORT_FIELD_NAMES)

    if (validator.fails()) {
      dispatch(notifyError(validator.errors()))
      setIsReportsLoading(false)
      return
    }

    if (isEqual(formData.selectionType, TYPE_SELECTION) && isEmpty(formData.contractIds)) {
      dispatch(notifyError('¡Upps! debes seleccionar al menos un empleado'))
      setIsReportsLoading(false)
      return
    }

    onGenerate(formData.from, formData.to, formData.contractIds)
    ref.current.closeModal()
  }

  const resetForm = isOpen => {
    if (!isOpen) return

    setFormData(initialState)
  }

  const handleOnChangeFrom = date => {
    setFormData(state => ({
      ...state,
      from: date
    }))
  }

  const handleOnChangeTo = date => {
    setFormData(state => ({
      ...state,
      to: date
    }))
  }

  const updateRadioButton = e => {
    const name = e.target.name
    const value = e.target.value

    setFormData(state => ({
      ...state,
      [name]: value,
      // clean the contract list if the selectionType changed
      contractIds: isEqual(name, 'selectionType') ? [] : state.contractIds
    }))
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium" onChangeOpen={resetForm}>
      <h3>Historial de Nómina</h3>
      <div className="ed-grid s-grid-2 rows-gap s-mb-1">
        <DatePickerInput
          isRequired
          label={HISTORY_REPORT_FIELD_NAMES.from}
          name="from"
          id="from"
          value={formData.from}
          onChange={handleOnChangeFrom}
        />

        <DatePickerInput
          isRequired
          label={HISTORY_REPORT_FIELD_NAMES.to}
          name="to"
          id="to"
          minDate={formData.from}
          value={formData.to}
          onChange={handleOnChangeTo}
        />

        <div className="s-cols-2 s-mb-1">
          <RadioButtonsWrapper label="Empleados" className="s-mb-1">
            <RadioButton
              name="selectionType"
              label="Todos"
              value={TYPE_ALL}
              onChange={updateRadioButton}
              checked={isEqual(formData.selectionType, TYPE_ALL)}
            />

            <RadioButton
              name="selectionType"
              label="Una selección"
              value={TYPE_SELECTION}
              onChange={updateRadioButton}
              checked={isEqual(formData.selectionType, TYPE_SELECTION)}
            />
          </RadioButtonsWrapper>

          {isEqual(formData.selectionType, TYPE_SELECTION) && (
            <MultiSelectEmployeeInput setFormData={setFormData} placeholder="Escribe para buscar empleados" />
          )}
        </div>
      </div>

      <button className="button" onClick={handleGenerate}>
        Descargar
      </button>
    </ModalTemplate>
  )
})

HistoryReportModal.displayName = 'HistoryReportModal'

export default HistoryReportModal
