import React from 'react'

import NoveltyTable from '../components/Organisms/Tables/NoveltyTable'
import {STAGE_NOVELTIES} from '../helpers/payroll'
import {isEmpty, isEqual} from '../helpers/utils'
import DateFilterModalProvider from '../modules/payroll-process-novelties/providers/DateFilterModal'
import NoveltiesCategoriesProvider from '../modules/payroll-process-novelties/providers/NoveltiesCategories'
import FormActionProvider from '../modules/payroll-process-novelties/providers/PayrollReportModalWrapper'
import StrategyFilterProvider from '../modules/payroll-process-novelties/providers/StrategyFilter'

const Novelties = ({stages}) => {
  if (isEmpty(stages) || !isEqual(stages[0], STAGE_NOVELTIES)) return null

  return (
    <FormActionProvider>
      <NoveltiesCategoriesProvider>
        <StrategyFilterProvider>
          <DateFilterModalProvider>
            <main className="ed-grid row-gap">
              <NoveltyTable />
            </main>
          </DateFilterModalProvider>
        </StrategyFilterProvider>
      </NoveltiesCategoriesProvider>
    </FormActionProvider>
  )
}

export default Novelties
