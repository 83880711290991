import {KEY_NOVELTY_OCCASIONAL} from 'helpers/novelties'
import {PropTypes} from 'prop-types'
import React, {createContext, useContext, useState} from 'react'

const NoveltiesCategoriesContext = createContext({})

const NoveltiesCategoriesProvider = ({children}) => {
  const [activeCategory, setActiveCategory] = useState('')
  const [categories, setCategories] = useState([])
  return (
    <NoveltiesCategoriesContext.Provider
      value={{
        activeCategory,
        setActiveCategory,
        categories,
        setCategories
      }}
    >
      {children}
    </NoveltiesCategoriesContext.Provider>
  )
}

export const useNoveltiesCategoriesContext = () => useContext(NoveltiesCategoriesContext)

NoveltiesCategoriesProvider.propTypes = {
  children: PropTypes.node
}

NoveltiesCategoriesProvider.defaultProps = {
  children: null
}

export default NoveltiesCategoriesProvider
