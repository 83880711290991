import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'

import Icon from '../../../../components/Atoms/Icon'
import IconBackground from '../../../../components/Atoms/IconBackground'
import Tag from '../../../../components/Atoms/Tag'
import {payrollDateRoute} from '../../../../config/routes'
import {PayrollContext} from '../../../../contexts/contexts'
import {mediaToM} from '../../../../helpers/media-queries'
import {useMedia} from '../../../../hooks/useMedia'
import {BADGE_TYPES} from '../../constants/badge'
import {useCertificateSettingContext} from '../../providers/CertificateSetting'

const IncomesCertificatesHeaderInfo = () => {
  const {payrollDate} = useContext(PayrollContext)
  const history = useHistory()
  const toM = useMedia(mediaToM)
  const {certificateSetting} = useCertificateSettingContext()

  const {label, color, background} = certificateSetting?.is_closed ? BADGE_TYPES.CLOSED : BADGE_TYPES.OPEN

  return (
    <div className="s-cross-center s-flex-gap-8px m-flex-gap-16px overflow-ellipsis nowrap">
      <Icon
        className="cursor-pointer"
        svg="arrow-uturn-left"
        size={toM ? '16' : '24'}
        onClick={() => history.push(payrollDateRoute(payrollDate))}
      />
      <div className="overflow-ellipsis">
        <h1 className="t2 s-mb-1 overflow-ellipsis">Certificados Ingresos y Retenciones</h1>

        <div className="flex s-flex-gap-8px m-flex-gap-12px s-cross-center">
          <IconBackground
            title="Certificados Ingresos y Retenciones"
            svg="document"
            bgColor="s-bg-alt-yellow"
            color="black"
          />
          <span className="small code-font s-color-light-text">{payrollDate?.year}</span>
          {certificateSetting && <Tag text={label} color={color} bgColor={background} />}
        </div>
      </div>
    </div>
  )
}

export default IncomesCertificatesHeaderInfo
