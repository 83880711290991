import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURCE = '/pay-periods'

export const getPeriodsByRange = (beginsAt, endsAt) => {
  const request = new RequestWithEmployerAuthorization()

  return new Promise((resolve, reject) => {
    request.get(`${RESOURCE}/range-dates?begins_at=${beginsAt}&ends_at=${endsAt}`, resolve, reject)
  })
}
