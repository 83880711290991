import React, {useContext} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago'

import {electronicPayrollRoute} from '../../../config/routes'
import {FloatContainerContext} from '../../../contexts/contexts'
import {getMonth} from '../../../helpers/dates'
import {capitalize} from '../../../helpers/string-format'
import {isNull} from '../../../helpers/utils'
import {markNotificationUserAsRead} from '../../../services/notificationUsers'
import Icon from '../../Atoms/Icon'

const NotificationItemElectronicPayroll = ({itemID, unread, content, createdAt}) => {
  const {setActive} = useContext(FloatContainerContext)
  const history = useHistory()
  const dispatch = useDispatch()

  const goToElectronicPayrollMonth = () => {
    setActive(false)

    history.push({
      pathname: electronicPayrollRoute(content?.year, content?.month)
    })
  }

  const handleOnClick = e => {
    if (unread) {
      dispatch(markNotificationUserAsRead(itemID, goToElectronicPayrollMonth))
      return
    }

    goToElectronicPayrollMonth()
  }

  return (
    <div className="s-cross-start nowrap" onClick={handleOnClick}>
      <div className="s-mr-8px">
        <Icon size="20px" color="var(--alt-blue-accent)" svg="rocket" />
      </div>
      <div>
        <p className="small s-mb-0">
          La nómina electronica de{' '}
          <span className="s-color-title">
            {getMonth(content.month - 1)} {content.year}
          </span>{' '}
          enviada por{' '}
          <span className="s-color-title">{content?.is_user_exec ? 'ti' : capitalize(content.user_name)}</span> ha
          finalizado.
        </p>
        <p className="s-mb-0 smaller s-color-light-text">{!isNull(createdAt) && <ReactTimeAgo date={createdAt} />}</p>
      </div>
    </div>
  )
}

NotificationItemElectronicPayroll.propTypes = {}

export default NotificationItemElectronicPayroll
