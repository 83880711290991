import classNames from 'classnames'
import React from 'react'

import Icon from './Icon'

const SortHead = ({isCurrent, isAscendant, label, handleClick}) => {
  return (
    <span className="s-cross-center cursor-pointer" onClick={handleClick}>
      <Icon
        className={classNames({'s-color-blue': isCurrent, turn: isAscendant}, 'icon s-mr-4px transform-transition')}
        svg="order"
      />
      {label}
    </span>
  )
}

export default SortHead
